import ReceiveLayout from 'modules/letsMeet/layouts/receiveLayout';
import {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {useGetEventQuery} from 'services/event.service';
import ReceivePublicMeetNow from './receivePublicMeetNow';
import ReceivePublicMeetLater from './receivePublicMeetLater';
import ReceivePublicMeetPoll from './receivePublicMeetPoll';
import axios from "axios";
import { IEvent } from 'types/event';
import OverlayLoader from 'components/loader/OverlayLoader';
import ReceivePublicMeet from './receivePublicMeet';

const ReceivePublicEvent: React.FC = () => {
    const {id} = useParams<{ id: string }>();
    const [loading, setLoading] = useState(false);
    const [event, setEvent] = useState<IEvent|null>(null);

    // const event = {
    //   type: 'poll',
    // };
    // const {data: event, refetch: refetchEvent} = useGetEventQuery(id || '');

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/event/share/${ id }`).then((response) => {
            console.log(response.data);
            setEvent(response.data);
        }).catch((error) => {
            console.error(error);
        });
    }, [id]);
    
    const refetch = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/event/share/${ id }`).then((response) => {      
            setEvent(response.data);
        }).catch((error) => {
            console.error(error);
        });
    };


    if (!id || !event) {
        return <OverlayLoader/>;
    }

    return (
        <ReceiveLayout>
            <ReceivePublicMeet type={event.type as 'now' | 'later' | 'poll'} event={event} refetch={refetch} />
        </ReceiveLayout>
    );
};

export default ReceivePublicEvent;

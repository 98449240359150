import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from 'components/ui/collapsible';
import { ChevronDown } from 'lucide-react';
import { act, useEffect, useMemo, useRef, useState } from 'react';

import { ReactComponent as MessageIcon } from 'assets/images/activity/message.svg';
import { ReactComponent as SendIcon } from 'assets/images/common/send.svg';
// import { Button } from 'components/ui/button';
import Comment from './comment';
import { cn, getCommentsFromEvent, getRandomHexID } from 'utils/helpers';
import { useAddEventCommentMutation } from 'services/event.service';
import { useAppSelector } from 'hooks/useAppSelector';
import { RootState } from 'app/store';
import { TextField, Avatar, Button, Menu, MenuItem } from "@mui/material";
import caleidoBaseApi from 'services/apiClient';
import { IEvent } from 'types';
import InLineLoader from 'components/loader/InlineLoader';
import { set } from 'lodash';
import useKeyboardHeightReset from 'hooks/useKeyboardHeightReset';
import useLocalStorage from 'hooks/useLocalStorage';
import useSessionStorage from 'hooks/useSessionStorage';
import OverlayLoader from 'components/loader/OverlayLoader';
import { ReactComponent as VerticalDots } from 'assets/images/common/vertical-dots.svg';
import { formatDistanceToNow } from 'date-fns';
import { IActivity } from 'types/activity';
import { IUser } from 'modules/auth/types/types';

export type IComment = {
  id?: string;
  by?: any;
  comment?: string;
  timestamp?: string;
};
// TODO: Add types for event
type CommentListProps = {
  refetchEvent?: () => void;
  event?: any | null;
  disabled?: boolean
  activities?: IActivity[];
  userPhotos?: {[key: string]: { photoURL: string, name: string }}
};
const CommentList: React.FC<CommentListProps> = ({
  event,
  disabled = false,
  refetchEvent, 
  activities,
  userPhotos,
}) => {
  const [open, setOpen] = useState(true);
  const [input, setInput] = useState('');
  const [commentsList, setCommentsList] = useState<IComment[]>([]);

  const [isDeleting, setIsDeleting] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [reloading, setReloading] = useState(false);
  const [editingComment, setEditingComment] = useState<any | null>(null);

  const [guestName, setGuestName] = useSessionStorage("cal-user-name", "");
  const [storedPhoneNumber, setStoredPhoneNumber] = useSessionStorage("cal-user-phone-number", "");
  const [storedCountryCode, setStoredCountryCode] = useSessionStorage("cal-user-country-code", "+1");
  const [guestOwnedCommentIds, setGuestOwnedCommentIds] = useLocalStorage("cal-user-guest-comment-ids", []);

  const guestPhoneNumber = useMemo(() =>storedPhoneNumber!=''?storedCountryCode+storedPhoneNumber:undefined, [storedPhoneNumber]);

  // fix for mobile keyboard height issue
  const { isKeyboardOpen } = useKeyboardHeightReset();
  const [dropdownOpen, setDropdownOpen] = useState<string | null>(null);

  const [addCommentMutation] = useAddEventCommentMutation();

  const user = useAppSelector((state: RootState) => state.auth.user);
  const expired = useMemo(() => new Date(event?.event?.end).getTime() < new Date().getTime(), [event]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  type ActivityUpdate = {
    id: string;
    type: string;
    content: string;
    timestamp: Date;
    by: { name: string };
    isActivity: boolean;
    activityType?: string;
    icon?: string;
    title?: string;
    user?: {
      name: string;
      phoneNumber: string;
      photoURL: string;
    } | null;
  };

  type CommentUpdate = {
    id: string | undefined;
    type: string;
    content: string | undefined;
    timestamp: Date;
    by: any;
    isActivity: boolean;
    icon: any;
  };

  const combinedUpdates = useMemo(() => {
    const commentUpdates = commentsList.map(comment => ({
      id: comment.id,
      type: 'comment',
      content: comment.comment,
      timestamp: new Date(comment.timestamp || ''),
      by: comment.by,
      isActivity: false
    }));
  
    const activityUpdates = activities?.filter(activity => (activity.type === 'rsvp' || activity.type === "poll_vote"))
      .map(activity => ({
        id: activity._id,
        type: 'activity',
        content: activity.body,
        timestamp: new Date(activity.timestamp),
        by: { name: activity.organizerName || '' },
        isActivity: true,
        activityType: activity.type,
        icon: activity.icon,
        title: activity.title,
        user: activity.user ? {
          name: activity.user.name || '',
          phoneNumber: activity.user.phoneNumber || '',
          photoURL: activity.user.photoURL || ''
        } : null
      })) ?? [];
  
    return [...commentUpdates, ...activityUpdates].sort((a, b) => 
      b.timestamp.getTime() - a.timestamp.getTime()
    );
  }, [commentsList, activities]);


  const mapIconToStatus = (icon: string) => {
    switch (icon) {
      case '👍':
        return 'Going';
      case '🤔':
        return 'Maybe';
      case '😢':
        return "Can't go";
      default:
        return 'Unknown';
    }
  };

  const getFormattedStatusString = (name: string, icon: string) => {
    const status = mapIconToStatus(icon);
    if (icon === '👍' || icon === '🤔') {
      return `${name} is ${icon} ${status}`;
    } else {
      return `${name} (${icon}) ${status}`;
    }
  };

  const renderUpdate = (update: typeof combinedUpdates[0], index: number) => {
    if (update.isActivity) {
      // for now we can assume the person's name is the first word of the content
      const activityUpdate = update as ActivityUpdate;

      // Extract name and icon
      const name = activityUpdate.content?.split(' ')[0] || "Guest";
      const icon = activityUpdate.icon || "";
      console.log(activityUpdate);
      // Function to map icon to status
      const mapIconToStatus = (icon: string) => {
        switch (icon) {
          case '👍':
            return 'Going';
          case '🤔':
            return 'Maybe';
          case '😢':
            return "Can't go";
          default:
            return 'Unknown';
        }
      };
  
      // Generate formatted activity string
      const status = mapIconToStatus(icon);
      const activityString = 
      icon === '👍' || icon === '🤔'
        ? `is ${icon} ${status}`
        : `${icon} ${status}`;


     
      const activityRenderText = () => {
        
        if (activityUpdate.activityType === 'poll_vote') {
         return (<p className="text-white text-[14px] font-semibold font-[Manrope] leading-normal flex items-center gap-1">
          {name}{" "}
          <span className="text-[rgba(255,255,255,0.80)] font-normal">
            {activityUpdate.content.split(" ").slice(1).join(" ")}
          </span>
        </p>);
        } else {
          return (
            <p className="text-white text-[14px] font-semibold font-[Manrope] leading-normal flex items-center gap-1">
            {name}{" "}
            <span className="text-[rgba(255,255,255,0.80)] font-normal">
              {activityString}
            </span>
          </p>
          )
        }
      }
     
      return (
        <div
          key={`activity-${index}`}
          className="flex justify-between items-center py-4"
        >
          <div className="flex gap-4 items-center">
            <Avatar
              alt={name}
              src={activityUpdate.user?.photoURL || ""}
              sx={{
                bgcolor: "#6C63FF",
                width: 40,
                height: 40,
                fontFamily: 'Manrope',
              }}
            >
              {name[0].toUpperCase()}
            </Avatar>
           {activityRenderText()}
          </div>
          <span className="text-[rgba(255,255,255,0.80)] text-right text-[14px] font-[Manrope] font-normal leading-normal">
            {formatTimeDisplay(activityUpdate.timestamp.toISOString())}
          </span>
        </div>
      );
    }
    const commentUpdate = update as CommentUpdate;
    return (
      // Existing comment rendering
      <div
      key={index}
      className="flex justify-between items-center py-4 w-full font-manrope"  
    >
      {/* Avatar and Comment Details */}
      <div className="flex gap-4 items-center"> 
        <Avatar
          alt={commentUpdate.by?.name || "Guest"}
          src={userPhotos?.[commentUpdate.by?.phoneNumber]?.photoURL || ""}
          sx={{ 
            bgcolor: "#6C63FF", 
            width: 40, 
            height: 40,
            fontFamily: 'Manrope'
          }}
        >
          {commentUpdate.by?.name?.[0]?.toUpperCase() || "G"}
        </Avatar>
        <div className="flex flex-col gap-[1px] "> 
          <p className="text-white text-sm font-normal leading-none">
            {commentUpdate.by?.name || "Guest"}
          </p>
          <p className="text-white/80 text-sm font-normal">
            {commentUpdate.content}
          </p>
        </div>
      </div>

      {/* Time and Actions */}
      <div className="flex items-center gap-2">  
        <span className="text-white/80 text-right text-sm font-normal">
        {formatTimeDisplay(commentUpdate.timestamp.toISOString() || "")}
        </span>
        
        <div className="relative">
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setDropdownOpen(dropdownOpen === (commentUpdate.id ?? '') ? null : commentUpdate.id ?? '');
              // Update anchor position when opening
              setAnchorEl(e.currentTarget);
            }}
            sx={{ 
              minWidth: 'unset',
              padding: 0,
              margin: 0,
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }}
          >
            <VerticalDots />
          </Button>

          <Menu
            key={`menu-${commentUpdate.id}`} // Add key to force remount
            anchorEl={anchorEl}
            open={dropdownOpen === commentUpdate.id}
            onClose={() => {
              setDropdownOpen(null);
              setAnchorEl(null);
            }}
            onClick={() => {
              // Close menu on any click
              setDropdownOpen(null);
              setAnchorEl(null);
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              '& .MuiPaper-root': {
                backgroundColor: '#32395E',
                marginTop: '4px',
                minWidth: '120px',
                boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.24)',
                position: 'fixed', // Fix position relative to viewport
              },
              '& .MuiMenuItem-root': {
                color: 'white',
                fontSize: '14px',
                fontFamily: 'Manrope',
                padding: '8px 16px',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.1)'
                }
              }
            }}
            keepMounted={false} // Don't keep menu in DOM when closed
          >
            <MenuItem onClick={() => {
              editComment(event.event._id, commentUpdate);
            }}>
              Edit
            </MenuItem>
            <MenuItem onClick={() => {
              deleteComment(event.event._id, commentUpdate.id || "");
            }}>
              Delete
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
    );
  };
  // NOTE: This functionality does not support for guest comments
  // TODO: Properly implement BE for adding comments by guests
  const addComment = async (newComment: string) => {
    if (newComment === '' || !event.event._id) return;
  
    await addCommentMutation({ eventId: event.event._id, comment: newComment });
  
    // append new comment to the list to the top
    const newCommentObj = {
      id: event.event._id,
      by: {
        id: user ? user.id : '',
        name: user ? user.name : '',
        phoneNumber: user?.phoneNumber, // Add phone number for photo lookup
      },
      comment: newComment,
      timestamp: new Date().toISOString(),
    };
  
    setCommentsList([newCommentObj, ...commentsList]);
    setIsAdding(false);
    setInput('');
  };

  
  // take the comment from the list and put it in the input field
  const editComment = async (eventId:string, comment:any) => {
    setEditingComment(comment);
    setInput(comment.comment);
    // TODO: uncomment to remove the comment from the list while editing.
    // setCommentsList(commentsList.filter((c) => c.id !== comment.id));
  }
  
  // TODO: remove after proper API is implemented for editing and deleting comments.
  // Taking the latest updated event before deleting or editing.
  const getEvent= async (eventId: string) => {
   return caleidoBaseApi.get(`/event/share/${eventId}`);
  }

  // TODO: implement proper API for deleting comment.
  // NOTE: This may not be reliable for concurrent updates. But only the PUT api is available.
  const updateComment = async () => {
    if (!editingComment) return;
    const timestamp = new Date().toISOString();
  
    // Update UI immediately
    setCommentsList(prev => prev.map(c => 
      c.id === editingComment.id 
        ? { ...c, comment: input, timestamp }
        : c
    ));
  
    // Clear form state
    setEditingComment(null);
    setInput('');
  
    try {
      const eventData = await getEvent(event.event._id);
      const eventBeforeUpdate = eventData.data;
      eventBeforeUpdate.contacts = eventBeforeUpdate?.contacts?.map((contact: any) => {
        const updatedContact = contact;
        if(updatedContact.comments) {
          updatedContact.comments = contact.comments.map((comment: any) => {
            if(comment._id === editingComment.id) {
              comment.comment = input;
              comment.timestamp = timestamp;
            }
            return comment;
          });
        }
        return updatedContact;
      });
      await updateEvent(eventBeforeUpdate);
    } catch (error) {
      // Revert on error
      console.error('Failed to update comment:', error);
      setCommentsList(prev => prev.map(c => 
        c.id === editingComment.id ? editingComment : c
      ));
    }
  };

  // TODO: implement proper API for deleting comment.
  // NOTE: This may not be reliable for concurrent updates. But only the PUT api is available. Using the /public/event PUT api.
  const deleteComment = async (eventId: string, commentId: string) => {
    // Optimistically update UI immediately
    setCommentsList(prevComments => 
      prevComments.filter(c => c.id !== commentId)
    );
    
    setIsDeleting(true);
    try {
      const eventData = await getEvent(eventId);
      const eventBeforeUpdate = eventData.data;
      eventBeforeUpdate.contacts = eventBeforeUpdate?.contacts?.map((contact: any) => {
        const updatedContact = contact;
        if(updatedContact.comments) {
          updatedContact.comments = contact.comments.filter((comment: any) => 
            comment._id !== commentId
          );
        }
        return updatedContact;
      });
      
      await updateEvent(eventBeforeUpdate);
    } catch (error) {
      // On error, revert the optimistic update
      console.error('Failed to delete comment:', error);
      const eventData = await getEvent(eventId);
      const newComments = getCommentsFromEvent(eventData.data);
      setCommentsList(newComments);
    } finally {
      setIsDeleting(false);
    }
  };

  // TODO: implement proper API for adding comment.
  // NOTE: This may not be reliable for concurrent updates. But only the PUT api is available for guest comments. Using the /public/event PUT api.
  const addNewComment = async (eventId: string, comment: any) => {
    if (comment === '') return;
    
    const phoneNumberToCheck = user ? user.phoneNumber : guestPhoneNumber;
    const storedGuestName = JSON.parse(sessionStorage.getItem('cal-user-name')??'""');
    const guestUserName = storedGuestName? storedGuestName : "Guest";
    const newCommentId = getRandomHexID();
    const timestamp = new Date().toISOString();

    // Add comment to UI immediately
    const newCommentObj = {
      id: newCommentId,
      by: {
        name: user ? user.name : guestUserName,
        phoneNumber: phoneNumberToCheck
      },
      comment: input,
      timestamp
    };
    setCommentsList(prev => [newCommentObj, ...prev]);
    setInput(''); // Clear input immediately

    try {
      const eventData = await getEvent(eventId);
      const eventBeforeUpdate = eventData.data;
      
      if(user) {
        addComment(input);
      } else {
        let contactFound = false;
        if(phoneNumberToCheck) {
          eventBeforeUpdate.contacts = eventBeforeUpdate?.contacts?.map((contact: any) => {
            if(contactFound) return contact;
            const updatedContact = contact;
            if(updatedContact.phoneNumber == phoneNumberToCheck){
              contactFound = true;
              if(!updatedContact.comments) updatedContact.comments = [];
              updatedContact.comments.push({comment:input, timestamp, _id:newCommentId});
            }
            return updatedContact;
          });
          
          if(!contactFound) {
            eventBeforeUpdate.contacts.push({
              name: guestUserName,
              phoneNumber: phoneNumberToCheck,
              comments: [{comment:input, timestamp, _id:newCommentId}],
              _id: getRandomHexID(),
              _commentGuestId: getRandomHexID()
            });
          }
        } else {
          const contactFound = eventBeforeUpdate.contacts?.find((contact:any)=>
            contact.phoneNumber==='+' && contact.name===guestUserName
          );
          if(contactFound) {
            if(!contactFound.comments) contactFound.comments = [];
            contactFound.comments.push({comment:input, timestamp, _id:newCommentId});
          } else {          
            eventBeforeUpdate.contacts.push({
              name: guestUserName,
              phoneNumber: '',
              comments: [{comment:input, timestamp, _id:newCommentId}],
              _id: getRandomHexID(),
              _commentGuestId: getRandomHexID(),
            });
          }
        }
        await updateEvent(eventBeforeUpdate);
      }
    } catch (error) {
      // On error, remove the optimistic comment
      setCommentsList(prev => prev.filter(c => c.id !== newCommentId));
      console.error('Failed to add comment:', error);
    }
};

const reloadCommentList = async () => {
  const tmpEventId = event?.event?._id;
  if (tmpEventId && !isAdding && !isEditing && !isDeleting) {
    try {
      const res = await getEvent(tmpEventId);
      if (res.data?.contacts) {
        const newComments = getCommentsFromEvent(res.data);
        
        // Only update if we have different comments
        const currentIds = new Set(commentsList.map(c => c.id));
        const hasNewComments = newComments.some(c => !currentIds.has(c.id));
        
        if (hasNewComments) {
          setCommentsList(newComments);
        }
      }
    } catch (err) {
      console.error('Failed to check for new comments:', err);
    }
  }
};
  
  // TODO: implement proper API for updating comments.
  // NOTE: This may not be reliable for concurrent updates. But only the PUT api is available. Using the /public/event PUT api.
  const updateEvent = async (event:IEvent) => {
    const formattedEvent:any = {...event,id:event._id};
    return caleidoBaseApi.put(`/public/event`, event);
  }

  // TODO: use the helper method to get comments from the event
  useEffect(() => {

    if (event && event.event ) {
      const newCommentsList = event.event.contacts
        .flatMap(
          (contact: {
            comments?: any[];
            phoneNumber: any;
            name: any;
            status: any;
            organizer: any;
          }) =>
            contact.comments
              ? contact.comments.map((comment) => ({
                eventId: event.event._id,
                id: comment._id,
                by: {
                  phoneNumber: contact.phoneNumber,
                  name: contact.name,
                  status: contact.status,
                  organizer: contact.organizer,
                },
                comment: comment.comment,
                timestamp: comment.timestamp,
              }))
              : [],
        )
        .sort(
          (
            a: { timestamp: string | number | Date },
            b: { timestamp: string | number | Date },
          ) =>
            new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
        );
      setCommentsList(newCommentsList);
    }
  }, []);


  // fetch the list of comments every 15 seconds
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    intervalRef.current = setInterval(() => {
        reloadCommentList();
      }, 15000);  
    return () => {
      if(intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    console.log('commentsList', commentsList);
  }, [commentsList]);

  useEffect(() => {
    console.log(commentsList);
  }, [commentsList]);

  if (!event) {
    return <></>;
  }

  // check if user is host
  const isHost = event? event?.event?.userId === user?._id : false;

  const getActivityUpdateText = (commentCount: number) => {
    if (commentCount === 0) return 'No updates';
    if (commentCount === 1) return '1 update';
    return `${commentCount} updates`;
  };

  return (
    <Collapsible
      className="w-full my-5 bg-[#1A1F37] rounded-lg p-4"
      open={open}
      onOpenChange={(e) => setOpen(e)}
    >
      {/* {isAdding || isEditing ? <OverlayLoader /> : null} */}
      <CollapsibleTrigger className="w-full flex justify-between items-center mb-4 text-sm text-white">
        <div className="flex flex-col items-start">
          <span className="text-[14px] text-white font-semibold font-manrope">
            Activity
          </span>
          <p className="text-[13px] text-white/80 font-normal font-manrope">
            {getActivityUpdateText(combinedUpdates.length)}
          </p>
        </div>
        <ChevronDown
          className={cn("transition-transform", open && "rotate-180")}
          size={20}
        />
      </CollapsibleTrigger>
      <CollapsibleContent className="flex flex-col justify-center">
        {/* Comment Input Section */}
        <form
          className="flex h-[60px] mb-2 mt-2 items-center gap-2 px-4 py-0 rounded-xl bg-[#32395E]"
          style={{
            boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.06)"
          }}
          onSubmit={(e) => {
            e.preventDefault();
            if (editingComment) {
              updateComment();
            } else {
              addNewComment(event.event._id, input);
            }
          }}
        >
          <Avatar
            alt="User Avatar"
            src= {user?.photoURL || ""}
            sx={{ 
              width: 32, 
              height: 32,
            }}
          />
         <TextField
          multiline
          maxRows={5}
          placeholder="Enter a comment..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          sx={{
            marginLeft: "8px",
            flexGrow: 1,
            "& .MuiOutlinedInput-root": {
              background: "transparent",
              color: "white",
              "& fieldset": { border: "none" },
              padding: "0",
            },
            "& .MuiInputBase-input": {
              padding: "0",
              color: "#FFF",
              fontFamily: "Manrope",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "normal",
            },
            "& .MuiInputBase-input::placeholder": {
              color: "rgba(255, 255, 255, 0.60)",
              fontFamily: "Manrope",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              opacity: 1
            }
          }}
          disabled={disabled || expired}
        />
          <button
            type="submit"
            className="p-0 disabled:opacity-50"
            disabled={disabled || isAdding || !input}
          >
            <SendIcon style={{ width: 24, height: 24 }} />
          </button>
        </form>
        {/* Comments List Section */}

        <div className="space-y-4">
        {combinedUpdates.length === 0 ? (
          <></> // TODO Remove?
          // <div className="text-center text-white/50">No comments yet</div> 
        ) : (
          
          // commentsList.map((comment, index) => (
            // <div
            //   key={index}
            //   className="flex justify-between items-center py-4 w-full font-manrope"  
            // >
            //   {/* Avatar and Comment Details */}
            //   <div className="flex gap-4 items-center"> 
            //     <Avatar
            //       alt={comment.by?.name || "Guest"}
            //       src={comment.by?.avatar || ""}
            //       sx={{ 
            //         bgcolor: "#6C63FF", 
            //         width: 40, 
            //         height: 40,
            //         fontFamily: 'Manrope'
            //       }}
            //     >
            //       {comment.by?.name?.[0]?.toUpperCase() || "G"}
            //     </Avatar>
            //     <div className="flex flex-col gap-[1px] "> 
            //       <p className="text-white text-sm font-normal leading-none">
            //         {comment.by?.name || "Guest"}
            //       </p>
            //       <p className="text-white/80 text-sm font-normal">
            //         {comment.comment}
            //       </p>
            //     </div>
            //   </div>

            //   {/* Time and Actions */}
            //   <div className="flex items-center gap-2">  
            //     <span className="text-white/80 text-right text-sm font-normal">
            //     {formatTimeDisplay(comment.timestamp || "")}
            //     </span>
                
            //     <div className="relative">
            //       <Button
            //         onClick={(e) => {
            //           e.stopPropagation();
            //           setDropdownOpen(dropdownOpen === (comment.id ?? '') ? null : comment.id ?? '');
            //           // Update anchor position when opening
            //           setAnchorEl(e.currentTarget);
            //         }}
            //         sx={{ 
            //           minWidth: 'unset',
            //           padding: 0,
            //           margin: 0,
            //           '&:hover': {
            //             backgroundColor: 'transparent'
            //           }
            //         }}
            //       >
            //         <VerticalDots />
            //       </Button>

            //       <Menu
            //         key={`menu-${comment.id}`} // Add key to force remount
            //         anchorEl={anchorEl}
            //         open={dropdownOpen === comment.id}
            //         onClose={() => {
            //           setDropdownOpen(null);
            //           setAnchorEl(null);
            //         }}
            //         onClick={() => {
            //           // Close menu on any click
            //           setDropdownOpen(null);
            //           setAnchorEl(null);
            //         }}
            //         anchorOrigin={{
            //           vertical: 'bottom',
            //           horizontal: 'right',
            //         }}
            //         transformOrigin={{
            //           vertical: 'top',
            //           horizontal: 'right',
            //         }}
            //         sx={{
            //           '& .MuiPaper-root': {
            //             backgroundColor: '#32395E',
            //             marginTop: '4px',
            //             minWidth: '120px',
            //             boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.24)',
            //             position: 'fixed', // Fix position relative to viewport
            //           },
            //           '& .MuiMenuItem-root': {
            //             color: 'white',
            //             fontSize: '14px',
            //             fontFamily: 'Manrope',
            //             padding: '8px 16px',
            //             '&:hover': {
            //               backgroundColor: 'rgba(255, 255, 255, 0.1)'
            //             }
            //           }
            //         }}
            //         keepMounted={false} // Don't keep menu in DOM when closed
            //       >
            //         <MenuItem onClick={() => {
            //           editComment(event.event._id, comment);
            //         }}>
            //           Edit
            //         </MenuItem>
            //         <MenuItem onClick={() => {
            //           deleteComment(event.event._id, comment.id || "");
            //         }}>
            //           Delete
            //         </MenuItem>
            //       </Menu>
            //     </div>
            //   </div>
            // </div>
          // ))
          combinedUpdates.map((update, index) => renderUpdate(update, index))
        )}
      </div>
      </CollapsibleContent>
    </Collapsible>
  );
  
};

const formatTimeDisplay = (timestamp: string) => {
  const now = new Date();
  const date = new Date(timestamp);
  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  // Less than a minute
  if (diffInSeconds < 60) {
    return `>1m`;
  }

  // Less than an hour
  const minutes = Math.floor(diffInSeconds / 60);
  if (minutes < 60) {
    return `${minutes}m`;
  }

  // Less than a day
  const hours = Math.floor(minutes / 60);
  if (hours < 24) {
    return `${hours}h`;
  }

  // Days
  const days = Math.floor(hours / 24);
  return `${days}d`;
};

export default CommentList;

import { ReactComponent as Home } from 'assets/images/nav/home.svg';
import { ReactComponent as HomeActive } from 'assets/images/nav/home-active.svg';
import { ReactComponent as Activity } from 'assets/images/nav/activity.svg';
import { ReactComponent as ActivityActive } from 'assets/images/nav/activity-active.svg';
import { ReactComponent as LetsMeet } from 'assets/images/nav/lets-meet.svg';
import { Link } from 'react-router-dom';

export enum BottomNavType {
  HOME = 'Home',
  ACTIVITY = 'Activity',
}

type BottomNavProps = {
  type: BottomNavType;
};

const BottomNav: React.FC<BottomNavProps> = ({ type }) => {
  return (
    <div>
      <div className="w-full h-[74px] bg-[#EBDFFF] fixed bottom-0 left-1/2 -translate-x-1/2">
        <div className="flex justify-around items-center h-full mx-4">
          <Link className="flex flex-col items-center" to="/home">
            {type === BottomNavType.HOME ? <HomeActive /> : <Home />}
          </Link>

          <Link className="-translate-y-8 rounded-full" to="/lets-meet">
            <LetsMeet />
          </Link>

          <Link className="flex flex-col items-center" to="/activities">
            {type === BottomNavType.ACTIVITY ? (
              <ActivityActive />
            ) : (
              <Activity />
            )}
          </Link>
        </div>
      </div>
      <div className="w-full max-w-md h-20"></div>
    </div>
  );
};

export default BottomNav;

import type React from 'react';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useCallback,
  useState,
  Fragment,
} from 'react';

import { debounce, isEmpty } from 'lodash';
import { useForm, type SubmitHandler } from 'react-hook-form';
import TextField from 'components/textfield/TextField';
import ProfileAvatar from 'components/avatar/ProfileAvatar';
import { Text } from 'components/text/primary/Text';
import type { IUser } from 'modules/auth/types/types';
import {
  useDeleteFileMutation,
  useUploadFileMutation,
} from 'services/upload.service';
import { useLazyGetUsernameSuggestionsQuery } from 'modules/auth/services/user.mutation.service';
import { Check, CircleX, Cross, Loader } from 'lucide-react';
import { USERNAME_MIN_CHR } from 'modules/profile/constants/common';
import { Separator } from 'components/ui/separator';
import useSessionStorage from 'hooks/useSessionStorage';
import useLocalStorage from 'hooks/useLocalStorage';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { whoAmIAction } from 'modules/auth/slices/actions';
import OverlayLoader from 'components/loader/OverlayLoader';

type AccountProps = {
  setPrevOrSkip: () => void;
  update?: any;
  data?: IUser | undefined;
  isEdit?: boolean;
};

type FormValues = {
  name: string;
  username: string;
  photoURL?: string;
};

const List = ({
  title,
  onClick,
}: {
  title: string;
  onClick: React.MouseEventHandler<HTMLHeadingElement>;
}) => (
  <div
    className="flex items-center justify-between rounded-full  text-white text-sm px-3 py-2 my-1 "
    onClick={onClick}
  >
    <Text size="small" className="text-xs font-semibold text-center truncate">
      {title}
    </Text>
  </div>
);

const Account = forwardRef<(() => void) | null, AccountProps>(
  ({ setPrevOrSkip, update, data, isEdit }, ref) => {
    const dispatch = useAppDispatch();

    const [onboardingUsername, setOnboardingUsername] = useLocalStorage('cal-user-onboarding-username', '');
    const [isLoadingData, setIsLoadingData] = useState(false);

    const {
      register,
      handleSubmit,
      setValue,
      reset,
      watch,
      setError,
      formState: { errors },
    } = useForm<FormValues>();

    const username = watch('username');
    const name = watch('name');
    
    const [uploadFile, { isLoading: isUploading, error }] =
      useUploadFileMutation();
    const [deleteFile, { isLoading: isDeleting, error: deleteError }] =
      useDeleteFileMutation();
    const [usernameSuggestions, setUsernameSuggestions] = useState<string[]>(
      [],
    );
    const [
      triggerGetUsernameSuggestions,
      { data: userNameData, isLoading, isFetching },
    ] = useLazyGetUsernameSuggestionsQuery();
    
    const [
      triggerGetUsernameValidation,
      { data: userNameValidationData, isLoading:userNameValidationIsLoading, isFetching:userNameValidationIsFetching },
    ] = useLazyGetUsernameSuggestionsQuery();

    useImperativeHandle(ref, () =>
      handleSubmit(onSubmitForm as SubmitHandler<FormValues>),
    );

    const onSubmitForm = async (formData: FormValues, isFile?: boolean) => {
      !isFile && setUsernameSuggestions([]);
      if (!isFile && username?.length < USERNAME_MIN_CHR) {
        setError('username', {
          type: 'manual',
          message: 'Username must be at least 6 characters long',
        });
        return;
      }
      try {
        const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
        const updateData = {
          username: formData?.username,
          name: formData?.name,
          photoURL: formData.photoURL,
          timeZone,
        };
        const response = await update({ data: updateData }).unwrap();
        if (response) {
          !isFile && setPrevOrSkip();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setUsernameSuggestions([]);
      }
    };

    const handleUpload = async (file: File) => {
      if (file) {
        setIsLoadingData(true);
        try {
          const response = await uploadFile({ file, optimize: true }).unwrap();
          await onSubmitForm(
            {
              username: data?.username ?? '',
              name: data?.name ?? '',
              photoURL: response?.signedUrl ?? '',
            },
            true,
          );
          setValue('photoURL', response?.signedUrl);
          dispatch(whoAmIAction());
          setIsLoadingData(false);
          // window.location.reload();
        } catch (error) {
          console.log(error);
          setIsLoadingData(false);
        }
      }
    };

    const handleDelete = async () => {
      try {
        if (data?.photoURL) {
          setIsLoadingData(true);
          await deleteFile({ key: data.photoURL }).unwrap();
          await onSubmitForm(
            {
              username: data?.username ?? '',
              name: data?.name ?? '',
              // we use command delete for remove image from user
              photoURL: 'DELETE',
            },
            true,
          );

          setValue('photoURL', '');
          dispatch(whoAmIAction());
          setIsLoadingData(false);
          // window.location.reload();
        }
      } catch (error) {
        console.log(error);
        setIsLoadingData(false);
      }
    };

    // fetch username suggestions based on full name
    const fetchUsernameSuggestions = useCallback(
      debounce(async (baseUsername: string) => {
        if (baseUsername) {
          triggerGetUsernameSuggestions({ baseUsername});
        }
      }, 500),
      [triggerGetUsernameSuggestions],
    );

    // fetch username validation for selected username
    const fetchUsernameValidation = useCallback(
      debounce(async (baseUsername: string) => {
        if (baseUsername) {
          triggerGetUsernameValidation({ baseUsername});
        }
      }, 500),
      [triggerGetUsernameValidation],
    );

    // handle username change
    const handleUsernameChange = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const newUsername = event.target.value;
      setValue('username', newUsername);
      if(!isEmpty(newUsername)) fetchUsernameValidation(newUsername);      
    };

    // handle full name change
    const handleFullNameChange = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const newName = event.target.value;      
      if(!onboardingUsername) setValue('name', newName);
      if(!isEmpty(newName)) fetchUsernameSuggestions(newName);
    }

    // handle suggestion click
    const handleSuggestionClick = (suggestion: string) => {
      setValue('username', suggestion);      
      fetchUsernameValidation(suggestion);      
    };

    useEffect(() => {
      if (data) {
        reset( onboardingUsername == '' ? {
          name: data.name || '',
          username: isEdit? data.username : '',
        }:{
          username: isEdit? data.username : '',
        });
      }
    }, [reset, data]);

    // set username suggestions from api response
    useEffect(() => {            
      if(userNameData?.suggestions){
        setUsernameSuggestions(userNameData?.suggestions);
      }
    }, [userNameData?.suggestions]);
    
    // clear suggestions if username is valid or set suggestions if username is invalid
    useEffect(() => {
      if (userNameValidationData?.matches === false) return setUsernameSuggestions([]);
      if (userNameValidationData?.matches === true) return setUsernameSuggestions(userNameValidationData?.suggestions??[]);
    }, [userNameValidationData]);       


    // set name from local storage when onboarding
    useEffect(() => {
      onboardingUsername && setValue('name', onboardingUsername);
    },[])

    return (
      <div className="flex flex-col items-center w-full gap-2">
        {(!data || isLoadingData) && <OverlayLoader />}
        <Text size="large" className="w-full">
          {isEdit ? 'Edit your details' : 'Enter your details'}
        </Text>
        <ProfileAvatar
          uploadable
          url={(isLoading||!data)?'':data?.photoURL}
          onUpload={(file) => handleUpload(file)}
          onDelete={() => handleDelete()}
          className="my-4"
          loading={isUploading || isDeleting}
          error={Boolean(error) || Boolean(deleteError)}
        />
        <form
          onSubmit={handleSubmit(onSubmitForm as SubmitHandler<FormValues>)}
          className="w-full"
        >
          <TextField
            label={isEdit ? 'Update your full name' : 'What is your full name?'}
            {...register('name', { required: 'Full name is required', onChange: handleFullNameChange})}
            required
            error={errors.name?.message}
          />
          <div className="mt-4">
            <TextField
              label="Your username"
              required
              maxLength={20}
              {...register('username', {
                required: 'Username is required',
                maxLength: 20,
                minLength: {
                  value: USERNAME_MIN_CHR,
                  message: 'Username must be at least 6 characters long',
                },
                onChange: handleUsernameChange,
              })}
              error={
                errors.username?.message ||
                (userNameData?.matches &&
                  !isEmpty(usernameSuggestions) &&
                  'User name already exists! Try one of these user names.')
              }
              endAdornment={
                username &&
                username.length >= USERNAME_MIN_CHR &&
                username !== '' ? (
                  isFetching || isLoadingData || userNameValidationIsFetching || userNameValidationIsLoading ? (
                    <div>
                      <Loader className="w-4 h-4 text-slate-300 animate-spin" />
                    </div>
                  ) : (
                    <div>
                      {userNameValidationData?.matches === true ? (
                        <CircleX className="w-4 h-4 text-red-600" />
                      ) : userNameValidationData?.matches === false? (
                        userNameData && (
                          <Check className="w-4 h-4 text-green-600" />
                        )
                      ) : (
                        <></>
                      )
                    }
                    </div>
                  )
                ) : (
                  <></>
                )
              }
            />
          </div>
          {(!isLoadingData || !isFetching) && usernameSuggestions.length != 0 && (
            <div className="flex flex-col mt-2 gap-x-2 bg-[#1F2339] py-1  px-2 rounded-lg">
              {usernameSuggestions?.map((suggestion, index) => (
                <Fragment key={index}>
                  <List
                    key={index}
                    title={suggestion}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSuggestionClick(suggestion);
                    }}
                  />
                  {index !== usernameSuggestions.length - 1 && (
                    <Separator className="opacity-40 h-[.08px]" />
                  )}
                </Fragment>
              ))}
            </div>
          )}
          <button type="submit" className="hidden">
            Submit
          </button>
        </form>
      </div>
    );
  },
);

Account.displayName = 'Account';

export default Account;

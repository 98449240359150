import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import ReceiveLayout from 'modules/letsMeet/layouts/receiveLayout';
import ReceiveMeetNow from './receiveMeetNow';
import ReceiveMeetLater from './receiveMeetLater';
import ReceiveMeetPoll from './receiveMeetPoll';
import { IEvent } from 'types/event';
import { useAppSelector } from 'hooks/useAppSelector';
import OverlayLoader from 'components/loader/OverlayLoader';
import { getSearchParams } from 'modules/home/utils/helpers';

const ReceiveEvent: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true); // Initially true to show loading state
  const [event, setEvent] = useState<IEvent | null>(null);

  // get query params
  const location = useLocation();
  const searchParams = getSearchParams(location.search ?? '');
  const origin = searchParams?.origin;

  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  // const fetchEvent = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL}/event/share/${id}`,
  //     );
  //     setEvent(response.data);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchEvent();
  // }, [id]);
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/event/share/${ id }`).then((response) => {
        console.log(response.data);
        setEvent(response.data);
    }).catch((error) => {
        console.error(error);
    });
}, [id]);

const refetch = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/event/share/${ id }`).then((response) => {      
        setEvent(response.data);
    }).catch((error) => {
        console.error(error);
    });
};
  useEffect(() => {
    if (!event) return;
    if (!user) return;

    if (event?.userId === user?.id) {
      navigate(`/lets-meet/view/${id}?${origin ? `origin=${origin}` : ''}`);
    }
  }, [event, user, id]);

  // if (loading) {
  //   return <OverlayLoader/>; // Show loading state
  // }
  if (!id || !event) {
    return <OverlayLoader/>;
}
  if(event?.userId === user?.id) {
    return <OverlayLoader/>; // Show loading state while navigating
  }

  if (!id || !event) {
    return <div className={'text-white p-5'}>Error: Event not found</div>; // Error handling
  }
  return (
    <ReceiveLayout>
      {event.type === 'now' && <ReceiveMeetNow event={event} refetch={refetch} />}
      {event.type === 'later' && <ReceiveMeetLater event={event} refetch={refetch} />}
      {event.type === 'poll' && <ReceiveMeetPoll event={event} refetch={refetch} />}
    </ReceiveLayout>
  );
};

export default ReceiveEvent;

import { EventCardItem } from 'components/eventCardItem';
import { EventObject } from 'components/eventCardItem/EventCardItem.types';
import InsideLoader from 'components/loader/InsideLoader';
import { useApiCall } from 'hooks/axiosHooks';
import { Key, useCallback, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  determineVariant,
  findEventOfDate,
  getSortedCalendarEvents,
  getSortedNonPastEvents,
  isDateTimeRangeInPast,
} from 'utils/calendarUtils';
import { AllEventPaginationProps } from './AllEventPagination.types';
import { MonthPill } from 'components/eventPagination/monthPill';
import { scrollToViewportIfNotIn } from 'utils/helpers';
import InLineLoader from 'components/loader/InlineLoader';


const AllEventPagination = ({
  selectedDates,
  selectedEventType,
  onload,
  focusToEventId
}: AllEventPaginationProps) => {

  // pending event states
  const [pendingEventList, setPendingEventList] = useState<EventObject[]>([]);
  const [pendingEventListLoading, setPendingEventListLoading] = useState<boolean>(false);
  const [pendingEventListHasMore, setPendingEventListHasMore] =useState<boolean>(true);
  const pendingEventHasMore = useRef(true);
  const pendingEventPageNumber = useRef(0);
  const pendingEventLastFetchedPage = useRef<number | null>(null);  
  const pendingEventsApiLoadingRef = useRef<boolean>(false);

  // past event states
  const [pastEventList, setPastEventList] = useState<EventObject[]>([]);  
  const [pastEventListLoading, setPastEventListLoading] = useState<boolean>(false);
  const [pastEventListHasMore, setPastEventListHasMore] =useState<boolean>(true);
  const pastEventHasMore = useRef(true);
  const pastEventPageNumber = useRef(0);
  const pastEventLastFetchedPage = useRef<number | null>(null);
  const pastEventsApiLoadingRef = useRef<boolean>(false);


  // future event states
  const [upcomingEventList, setUpcomingEventList] = useState<EventObject[]>([]);
  const [upcomingEventListLoading, setUpcomingEventListLoading] = useState<boolean>(false);
  const [upcomingEventListHasMore, setUpcomingEventListHasMore] =useState<boolean>(true);
  const upcomingEventHasMore = useRef(true);
  const upcomingEventPageNumber = useRef(0);
  const upcomingEventListLastFetchedPage = useRef<number | null>(null);
  const upcomingEventsApiLoadingRef = useRef<boolean>(false);
  
  const [initialDataLoaded, setInitialDataLoaded] = useState<boolean>(false);
  const initialDataLoadedRef = useRef<boolean>(false);

  const [hasNoVisibleEvents, setHasNoVisibleEvents] = useState<boolean>(false);

  useEffect(() => {
    initialDataLoadedRef.current = initialDataLoaded;
  }, [initialDataLoaded]);

  // formatted events
  const [formattedPendingEvents, setFormattedPendingEvents] = useState<EventObject[]>([]);
  const [formattedPastEvents, setFormattedPastEvents] = useState<EventObject[]>([]);
  const [formattedUpcomingEvents, setFormattedUpcomingEvents] = useState<EventObject[]>([]);  

  // category pills 
  const categoryPillsUpcomingRef = useRef<string[]>([]);
  const categoryPillsPastRef = useRef<string[]>([]);
  const categoryPillsPendingRef = useRef<string[]>([]);

  useEffect(() => {
    // clear the category pills on every render
    categoryPillsUpcomingRef.current = [];
    categoryPillsPastRef.current = [];
    categoryPillsPendingRef.current = [];
  });
  
  // api calls
  const {data: pastEvents,refetch: refetchPastEvents,isLoading: isLoadingPastEvents,isFetching: isFetchingPastEvents,} = useApiCall();
  const {data: pendingEvents,refetch: refetchPendingEvents,isLoading: isLoadingPendingEvents,isFetching: isFetchingPendingEvents,} = useApiCall();
  const {data: upcomingEvents, refetch: refetchUpcomingEvents,isLoading: isLoadingUpcomingEvents,isFetching: isFetchingUpcomingEvents,} =  useApiCall();

  // event load functions
  const loadPastEventsNextPage = () => {
    pastEventHasMore.current && refetchPastEvents(`event/all-events?page=${pastEventPageNumber.current }&history=true`);
    pastEventLastFetchedPage.current = pastEventPageNumber.current;
    pastEventPageNumber.current += 1;
  }
  const loadUpcomingEventsNextPage = () => {
    upcomingEventHasMore.current && refetchUpcomingEvents(`event/all-events?page=${upcomingEventPageNumber.current}&history=false`);
    upcomingEventListLastFetchedPage.current = upcomingEventPageNumber.current;
    upcomingEventPageNumber.current += 1;
  }
  const loadPendingEventsNextPage = () => {
    pendingEventHasMore.current && refetchPendingEvents(`event/pending?page=${pendingEventPageNumber.current}`);
    pendingEventLastFetchedPage.current = pendingEventPageNumber.current;
    pendingEventPageNumber.current += 1;
  }

  // load events on page load
  useEffect(() => {
    loadPastEventsNextPage();  
    setPastEventListLoading(true);    
    loadUpcomingEventsNextPage();    
    setUpcomingEventListLoading(true);
    loadPendingEventsNextPage();      
    setPendingEventListLoading(true);     
  }, []);
  
  // set pending events to the states
  useEffect(() => {
    if (pendingEvents?.length) {
      setPendingEventList((prevEvents) =>getSortedNonPastEvents([...prevEvents, ...pendingEvents],'asc'));      
    } else if (pendingEvents && !pendingEvents?.length) {
      setPendingEventListHasMore(false);
      pendingEventHasMore.current = false;
    }
    if(pendingEventListLoading){
      setPendingEventListLoading(false);
    }
    if(pendingEvents && pendingEventsApiLoadingRef.current){     
      pendingEventsApiLoadingRef.current = false;
    }
  }, [pendingEvents]);
  
  // set past events to the states
  useEffect(() => {
    if (pastEvents?.length) {
      setPastEventList((prevEvents) =>
        getSortedCalendarEvents([...prevEvents, ...pastEvents],'asc'),
      );      
    } else if (pastEvents && !pastEvents?.length) {
      setPastEventListHasMore(prev=>false);
      pastEventHasMore.current = false;
    }
    if(pastEventListLoading){
      setPastEventListLoading(false);
    }
    if(pastEvents && pastEventsApiLoadingRef.current){
      pastEventsApiLoadingRef.current = false;
    }
  }, [pastEvents]);

  // set future events to the states
  useEffect(() => {
    if (upcomingEvents?.length){
      setUpcomingEventList((prevEvents) =>
        getSortedCalendarEvents([...prevEvents, ...upcomingEvents],'asc'),
      );      
    } else if ( upcomingEvents && !upcomingEvents?.length) {
      setUpcomingEventListHasMore(false);
      upcomingEventHasMore.current = false;
    }
    if(upcomingEventListLoading){
      setUpcomingEventListLoading(false);
    }
    if(upcomingEvents && upcomingEventsApiLoadingRef.current){
      upcomingEventsApiLoadingRef.current = false;
    }
  }, [upcomingEvents]);


    // format events to display
  useEffect(() => {
      setFormattedPastEvents((prev)=>pastEventList);
      setFormattedUpcomingEvents((prev)=>upcomingEventList);
      setFormattedPendingEvents((prev)=>pendingEventList);  
  },[pastEventList,upcomingEventList,pendingEventList]);
 

  // scroll to the selected date related events
  useEffect(() => {
    if(selectedDates && selectedDates.length>0){      
      const found = findEventOfDate([...formattedPastEvents,...formattedUpcomingEvents,...formattedPendingEvents],selectedDates[0]);   
      if(found){
        const element = document.getElementById(found.event?._id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }
  }, [selectedDates]);

  
  // initial scroll position handler
  const initialScrolledRef = useRef<boolean>(false);
  const initialScrollTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const handleInitialScroll = () => {
    if(initialScrolledRef.current) return;
    let element = null;

    // TODO: scroll to the event id
    // if(focusToEventId){
    // }

    if(formattedPendingEvents.length>0){
      // when pending events are available
      element = document.getElementById('pending-event-pill');
    }else if(formattedUpcomingEvents.length>0){
      // when upcoming events are available
      element = document.getElementById('upcoming-event-pill');
    }else{
      // when no upcoming and pending events are available
      element = document.getElementById('no-event-message-container');
    }

    if (element) {
      if(element.id == 'no-event-message-container') {
          initialScrolledRef.current = scrollToViewportIfNotIn(element);
          setHasNoVisibleEvents(true);
      }else {
        initialScrolledRef.current = scrollToViewportIfNotIn(element);
        setHasNoVisibleEvents(false);
      }
    }
    if(!initialScrolledRef.current){
      initialScrollTimeoutRef.current && clearTimeout(initialScrollTimeoutRef.current);
      initialScrollTimeoutRef.current = setTimeout(() => {
        handleInitialScroll();
      }, 50);
    }
  }
 
  // initial data load handler
  const handleInitialDataLoad=()=>{
    onload && onload();
    handleInitialScroll();
  }  
  useEffect(()=>{
    if(pastEvents && upcomingEvents && pendingEvents && !initialDataLoaded){
      setTimeout(() => {
        handleInitialDataLoad();        
        setInitialDataLoaded(prev=>true);
      }, 100);
    }
  },[pastEvents,pendingEvents,upcomingEvents]); 


  

  // TODO: Remove if not needed in the future.
  // use intersection observer to handle infinite scroll
  const topTriggerRef = useRef<HTMLDivElement | null>(null);
  const bottomTriggerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observerOptions = {
      root: null, // Use the viewport as the root
      rootMargin: '-100px', // Trigger 20px before the element enters the viewport
      // threshold: 0.1, // Trigger when 10% of the element is visible
      threshold: 0, // Trigger when any part of the element is visible
    };

    const observerCallback = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          if (entry.target === topTriggerRef.current) {
            // fetchDataAtTop();
          } else if (entry.target === bottomTriggerRef.current) {
            // fetchDataAtBottom();
          }
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions,
    );

    if (topTriggerRef.current) {
      observer.observe(topTriggerRef.current);
    }
    if (bottomTriggerRef.current) {
      observer.observe(bottomTriggerRef.current);
    }

    // Cleanup the observer on component unmount
    return () => {
      if (topTriggerRef.current) {
        observer.unobserve(topTriggerRef.current);
      }
      if (bottomTriggerRef.current) {
        observer.unobserve(bottomTriggerRef.current);
      }
    };
  }, []);

  // data fetch on scroll top
  const fetchDataAtTop = () => {
    if(!initialDataLoadedRef.current) return;
    if (pastEventListLoading||!pastEventHasMore.current) {
      return;
    }
    loadPastEventsNextPage(); 
  };
  
  // data fetch on scroll bottom
  const fetchDataAtBottom = () => {
    if(!initialDataLoadedRef.current) return;
    if (upcomingEventListLoading||!upcomingEventHasMore.current) {
      return;
    }
    loadUpcomingEventsNextPage();
  };

  
  // add scroll listeners
  const windowScrollRef = useRef<number>(0);
  const scrollStartedRef = useRef<boolean>(false);
  useEffect(() => {
    const handleScroll = () => {
      windowScrollRef.current = window.scrollY;
      handleScrollForPills();
      handleScrollForDataLoading();
    };    
    const startManualScroll = () => {
      scrollStartedRef.current = true;
    };
    const handleKeydownScroll = (e: KeyboardEvent) => {
      const scrollKeys = ['PageDown', 'PageUp', 'ArrowDown', 'ArrowUp', 'Home', 'End', 'Space'];
      if (scrollKeys.includes(e.code)) {
        startManualScroll();
      }
    };
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('wheel', startManualScroll);
    window.addEventListener('touchstart', startManualScroll);
    window.addEventListener('keydown', handleKeydownScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('wheel', startManualScroll);
      window.removeEventListener('touchstart', startManualScroll);
      window.removeEventListener('keydown', handleKeydownScroll);
    };
  }, []); 
  
  
  
  // handle document height change due to element adding
  const documentHeightRef = useRef<number>(0);  
  const shouldScrollTop = useRef<boolean>(false);
  
  useEffect(()=>{
    if(shouldScrollTop.current){
      // TODO: disabling due to scroll issue
      // window.scrollTo(0, window.scrollY+ document.documentElement.scrollHeight - documentHeightRef.current);
      shouldScrollTop.current = false;
    } 
    documentHeightRef.current = document.documentElement.scrollHeight;
  },[document.documentElement.scrollHeight])

  useEffect(()=>{
    if(pastEventList.length>0){
      shouldScrollTop.current = true;
    }
  },[pastEventList]) 
    
   
  // height change listener
  const resizingElementRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const targetDiv = resizingElementRef.current;
    if (targetDiv) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const { height } = entry.contentRect;
          // Handle the height change here        
        }
      });
      resizeObserver.observe(targetDiv);
      return () => {
        resizeObserver.unobserve(targetDiv);
      };
    }
  }, []); 
  

  // handle pill visibility  
  const prevScrollPositionRef = useRef<number>(0);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [currentDisplayPillText, setCurrentDisplayPillText] = useState<string | null>(null);
  const [showPill, setShowPill] = useState(false);
  
  const pillContainerRef = useRef<HTMLElement | null>(null);
  const prevScrollPosition = useRef<number>(0);
  const downScrollPillPassedRef = useRef<boolean>(false);
  
  /*
  // NOTE: top visible card month pill
  const [topVisibleCardId, setTopVisibleCardId] = useState<string | null>(null);
  useEffect(() => {
    const found = formattedPastEvents.find((event) => event?.event?._id === topVisibleCardId)?? formattedUpcomingEvents.find((event) => event?.event?._id === topVisibleCardId)?? formattedPendingEvents.find((event) => event?.event?._id === topVisibleCardId);
    if (found) {
      // get month from the top visible card
      const month = new Date(found.event.start).toLocaleString('default', {month: 'long',});
      console.log( month,found.event.start,found.event?.activity);
      setCurrentDisplayPillText(month);
    }
  }, [topVisibleCardId]);
  */
  

  const handleScrollForPills = () => {
    
    // get current scroll position of the window
    const currentScrollY = window.scrollY;
    const containerPosition = 120;

    // identify scroll direction
    let scrollDirection = 'down';
    if(currentScrollY<prevScrollPosition.current){
      scrollDirection = 'up';
    }
    prevScrollPosition.current = currentScrollY;

    // hide on near the scroll top
    if(currentScrollY<100){
      setShowPill(false);
      return;
    }

    // show div on scroll at least 100px
    if(Math.abs(prevScrollPositionRef.current-currentScrollY)>50 ){
      setShowPill(true);
    }

    // hide pill after 1 seconds of inactivity
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setShowPill(false);
      prevScrollPositionRef.current = currentScrollY;
    }, 1000);

    
    /*
    // NOTE: top visible card month pill
    const eventCards = document.querySelectorAll<HTMLElement>('.event-card-container');
    let topMostCard: HTMLElement | null = null;

    eventCards.forEach((card) => {
      const rect = card.getBoundingClientRect();
      // Check if the element is in the viewport (top is above the bottom of the viewport)
      if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
        if (!topMostCard || rect.top < topMostCard.getBoundingClientRect().top) {
          topMostCard = card;
        }
      }
    });

    if (topMostCard && (topMostCard as any)?.id) {
      setTopVisibleCardId((topMostCard as any)?.id);
    } else {
      setTopVisibleCardId(null);
    }
    */

   /*
   // NOTE: get the month pill based on the scroll direction
    const summaryPills = document.querySelectorAll<HTMLElement>('.summary-pill');
    if(!pillContainerRef.current) pillContainerRef.current = document.getElementById('summary-pill-container-label');
    const pillContainerRect = pillContainerRef.current?.getBoundingClientRect();

    if(scrollDirection === 'up'){
      // find the nearest pill to the top of the container
      for(let i=summaryPills.length-1;i>=0;i--){
        const rect = summaryPills[i].getBoundingClientRect();
        if(rect.top > containerPosition +5){
          const selectedPill = summaryPills[i-1];
          if(selectedPill){
            currentDisplayPillText !== selectedPill.innerText && setCurrentDisplayPillText(selectedPill.innerText);
          }
        }
      }      
    }else if(scrollDirection === 'down'){
      summaryPills.forEach((pill) => {
        const rect = pill.getBoundingClientRect();
        if(rect.top >= (containerPosition-5) && rect.top<= (containerPosition+5)){
          currentDisplayPillText !== pill.innerText && setCurrentDisplayPillText(pill.innerText);
        }
      });
    }
    */

    // set the pill text based on the scroll direction 
    
    const summaryPills = document.querySelectorAll<HTMLElement>('.summary-pill');
    if(!pillContainerRef.current) pillContainerRef.current = document.getElementById('summary-pill-container-label');
    const pillContainerRect = pillContainerRef.current?.getBoundingClientRect();

    if(scrollDirection === 'up'){
      // find the nearest pill to the top of the container
      for(let i=summaryPills.length-1;i>=0;i--){
        downScrollPillPassedRef.current = false;
        const rect = summaryPills[i].getBoundingClientRect();
        if(rect.top > containerPosition +5){
          const selectedPill = summaryPills[i-1];
          if(selectedPill){
            currentDisplayPillText !== selectedPill.innerText && setCurrentDisplayPillText(selectedPill.innerText);
          }
        }
      }      
    }else if(scrollDirection === 'down'){
      for(let i=0;i<summaryPills.length;i++){
        const rect = summaryPills[i].getBoundingClientRect();
        if(rect.top >= (containerPosition-50) && rect.top<= (containerPosition+2)){
          const selectedPill = summaryPills[i];
          if(selectedPill){
            currentDisplayPillText !== selectedPill.innerText && setCurrentDisplayPillText(selectedPill.innerText);
          }
          break;
        }
      }
    } 
  };
 
  const handleScrollForDataLoading= ()=>{ 
    // bottom scroll data loading
    const topTriggerPosition = topTriggerRef.current?.getBoundingClientRect().top;
    const bottomTriggerPosition = bottomTriggerRef.current?.getBoundingClientRect().bottom;

    if(topTriggerPosition && topTriggerPosition>-100 && !pastEventsApiLoadingRef.current && initialDataLoadedRef.current){
      pastEventsApiLoadingRef.current = true;
      fetchDataAtTop();
    }
    
    if(bottomTriggerPosition && ((bottomTriggerRef.current?.getBoundingClientRect().top??0) - window.innerHeight) < 100 && !upcomingEventsApiLoadingRef.current&& initialDataLoadedRef.current){
      upcomingEventsApiLoadingRef.current = true;
      fetchDataAtBottom();
    }
  }

  return (
    <>
      {/* month label pill */}
      {/* {<div id='summary-pill-container' className={`fixed top-[120px] z-50 w-full left-0 flex justify-center transition-all duration-200 ease-in-out transform ${showPill ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0 pointer-events-none'}`}>
        <span id='summary-pill-container-label' className='bg-gray-700 text-white rounded-xl px-4 py-1 text-xs'>{currentDisplayPillText}</span>
      </div>} */}

      {scrollStartedRef.current && <div id='summary-pill-container' className={`fixed top-[120px] z-50 w-full left-0 flex justify-center transition-all duration-200 ease-in-out transform ${showPill ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0 pointer-events-none'}`}>
        <span id='summary-pill-container-label' className={
          `rounded-[100px] text-center text-sm leading-4 font-bold my-1
          ${
            currentDisplayPillText=='Past'? 
            `py-1.5 px-2.5 bg-black ` 
            : currentDisplayPillText =='Pending'?
            'py-1.5 px-2.5 bg-theme': 
            currentDisplayPillText=='Upcoming'?
            'py-1.5 px-2.5 bg-theme':''
          }`
          }>{currentDisplayPillText}</span>
      </div>}

      {(!initialDataLoaded) && <InsideLoader />}
     
      <div
        id="scrollableDiv"
        className={`flex gap-2 flex-col relative ${!initialScrolledRef.current ? 'opacity-0' : ''} ${hasNoVisibleEvents?'opacity-100':''}`}
        ref={resizingElementRef}
      >
        {/* TODO: This component functionality is not used in the current implementation */}
            
          <InfiniteScroll
            dataLength={formattedPastEvents.length + formattedPendingEvents.length + formattedUpcomingEvents.length}
            next={()=>{}}
            hasMore={true}
            loader={<></>}
            style={{overflowX: 'hidden'}}
            scrollableTarget=""
            >
            {/* viewport top ref */}
            <div ref={topTriggerRef} className="bg-none h-4 w-full" key={0}>
              {(isLoadingPastEvents|| isFetchingPastEvents) && initialDataLoaded && <InLineLoader/>}
            </div>

            {initialDataLoaded && (
              <>

                {(()=>{
                  handleInitialScroll();
                })()}

                {formattedPastEvents?.length > 0 && (
                  <div className="min-h-[100px] mb-3" key={1}>
                    <div className="flex flex-row justify-center" key={1}>
                      <div id='past-event-pill' className="py-1.5 px-2.5 bg-black rounded-[100px] text-center text-sm leading-4 font-bold my-1 summary-pill">
                        Past
                      </div>
                    </div>
                    {formattedPastEvents.map((event: EventObject, index: Key | null | undefined) => (
                        // <>
                        // {/* Use for month pill */}
                        // {/* <MonthPill pillRef={categoryPillsPastRef} event={event?.event} />             */}
                        <div key={index} className="mb-2 event-card-container" id={event?.event?._id}>
                          <EventCardItem
                            id={event?.event?._id}
                            eventData={event?.event}
                            variant={determineVariant(event?.variantData)}
                            additionalVariants={event?.variantData}
                            />
                        </div>
                        // </>
                    ))}
                  </div>
                )}
                
                {formattedPendingEvents.length > 0 && (
                  <div className="min-h-[100px] my-3" key={2}>
                    <div className="flex flex-row justify-center" key={2}>
                      <div id='pending-event-pill' className="py-1.5 px-2.5 bg-theme rounded-[100px] text-center text-sm leading-4 font-bold my-1 summary-pill">
                        Pending
                      </div>
                    </div>
                    {formattedPendingEvents?.map(
                      (event: EventObject, index: Key | null | undefined) => (
                          // <>
                          // {/* Use for month pill */}
                          // {/* <MonthPill pillRef={categoryPillsPendingRef} event={event?.event} /> */}
                          <div key={index} className="mb-2 event-card-container" id={event?.event?._id}>
                            <EventCardItem
                              id={event?.event?._id}
                              eventData={event?.event}
                              variant={'response'}
                              additionalVariants={event?.variantData}
                              />
                          </div>
                          // </>
                      )
                    )}
                  </div>
                )}

                {formattedUpcomingEvents?.length > 0 && (
                  <div className="min-h-[100px] mt-3" key={3}>
                    <div className="flex flex-row justify-center" key={3}>
                      <div id='upcoming-event-pill' className="py-1.5 px-2.5 bg-theme rounded-[100px] text-center text-sm leading-4 font-bold my-1 summary-pill">
                        Upcoming
                      </div>
                    </div>
                    {formattedUpcomingEvents.map((event: EventObject, index: Key | null | undefined) => (
                        // <>
                        // {/* Use for month pill */}
                        // {/* <MonthPill pillRef={categoryPillsUpcomingRef} event={event?.event} /> */}
                        <div key={index} className="mb-2 event-card-container" id={event?.event?._id}>
                          <EventCardItem
                            id={event?.event?._id}
                            eventData={event?.event}
                            variant={determineVariant(event?.variantData)}
                            additionalVariants={event?.variantData}
                            />
                        </div>
                        // </>
                    ))}
                   
                  </div>
                )}

                {!formattedPendingEvents.length && !formattedUpcomingEvents.length && (
                  <div className={`flex justify-center items-center ${formattedPastEvents.length?'h-[calc(100dvh-200px)]':'absolute bottom-[50%]'} w-full select-none`} id='no-event-message-container'>
                    <p className="text-lg text-gray-400 text-center" id='no-event-message'>You don&apos;t seem to have any events to show. Lets plan an event!</p>
                  </div>                
                )}

                {(()=>{
                  handleInitialScroll();
                })()}

              </>
            )}

            {/* viewport bottom ref */}
            <div ref={bottomTriggerRef} className="bg-none h-4 w-full">
              {(isLoadingUpcomingEvents|| isFetchingUpcomingEvents) && initialDataLoaded && <InLineLoader/>}
            </div>

          </InfiniteScroll>
        </div>      
      </>
    );   
};

export default AllEventPagination;

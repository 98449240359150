import React, { useEffect } from 'react';
import {
  EventType,
  ReceiveMeetResponseState,
  ReceiveMeetResponseType,
} from 'modules/letsMeet/types/types';
import { useAppSelector } from 'hooks/useAppSelector';
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { IContact, IEvent } from 'types/event';
import { formatDistanceToNow } from 'date-fns';

// Components
import ModernActivityHeader from 'modules/activity/components/modernHeader';
import EventOrganizerHeader from 'modules/letsMeet/components/receive/eventOrganizerHeader';
import EventCard from 'modules/letsMeet/components/receive/eventCard';
import HorizontalInviteList from 'modules/letsMeet/components/inviteList/HorizontalInviteList';
import CommentList from 'modules/letsMeet/components/comments/commentList';
import RSVP from 'modules/letsMeet/components/receive/RSVP';
import Drawer from 'modules/settings/components/contactActions/Drawer/Drawer';
import { Text } from 'components/text/primary/Text';
import ActionButton from 'components/buttons/action/ActionButton';

// Services
import {
  useAddEventCommentMutation,
  useDeleteEventMutation,
  useRespondEventMutation,
} from 'services/event.service';

import {
  useGetInviteActivityQuery,
  useGetAllEventActivitiesQuery,
  useCreateActivityMutation,
} from 'services/activity.service';
import { IActivity } from 'types/activity';
import axios from 'axios';
import { getUserPhotosForEvent } from 'modules/letsMeet/utils/user';
import { getFormattedSharingText } from 'utils/helpers';
import { toast } from 'components/ui/toast/use-toast';


interface ReceiveMeetLaterProps {
  event: IEvent | null;
  refetch?: () => void;
}

const ReceiveMeetLater = (receiveProps: ReceiveMeetLaterProps) => {
  const user = useAppSelector((state) => state.auth.user);
  const navigate = useNavigate();

  const [createActivity] = useCreateActivityMutation();

  const { data: inviteActivity } = useGetInviteActivityQuery(
    receiveProps.event?._id ?? '', 
    {
      skip: !receiveProps.event?._id
    }
  );
  const [userPhotos, setUserPhotos] = React.useState<{[key: string]: { photoURL: string, name: string }}>({});
  const [organizerPhoto, setOrganizerPhoto] = React.useState<string>('');
  const [pastEvent, setPastEvent] = React.useState<boolean>(false);
  useEffect(() => {
  if (receiveProps.event?._id) {
    getUserPhotosForEvent(receiveProps.event._id).then(photos => {
      setUserPhotos(photos);
      const organizerNumber = receiveProps.event?.contacts?.find((contact : IContact) => contact.organizer === 1)?.phoneNumber;
      if (!organizerNumber) return;
      setOrganizerPhoto(photos[organizerNumber]?.photoURL ?? '');
    });
  }
  }, [receiveProps.event?._id]);


  
  useEffect(() => {
    const endDate = new Date(receiveProps?.event?.end ?? 0);
    const currentDate = new Date();

    if (endDate < currentDate && receiveProps?.event?.type !== 'poll') {
      setPastEvent(true);
    } else {
      setPastEvent(false);
    }
  }, [receiveProps?.event]);

  const [eventActivities, setEventActivities] = React.useState<IActivity[]>([]);
  const initialAttendingInvitees = receiveProps.event?.contacts
  ?.filter((contact) => contact.status === 1)
  .map((contact) => contact.name) ?? [];
  const [attendingInvitees, setAttendingInvitees] = React.useState<string[]>(initialAttendingInvitees);
  React.useEffect(() => {
    const fetchEventActivities = async () => {
      try {
        if (receiveProps.event?._id) {
          const accessToken = sessionStorage.getItem('accessToken');
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/activity/event/${receiveProps.event._id}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
          });
          console.log(response.data);
          console.log(response)
        
          setEventActivities(response.data);
        } else {
          setEventActivities([]);
        }
      } catch (error) {
        console.error('Error fetching event activities:', error);
        setEventActivities([]);
      }
    };
  
    fetchEventActivities();
  }, [receiveProps.event?._id]);

  const getResponseStateFromStatus = (status: number | undefined): ReceiveMeetResponseState => {
    
    if (status === 1) return ReceiveMeetResponseState.ACCEPTED;
    if (status === 2) return ReceiveMeetResponseState.DECLINED;
    if (status === 3) return ReceiveMeetResponseState.MAYBE;
    return ReceiveMeetResponseState.NOT_RESPONDED;
  };

  const currentUser = receiveProps?.event?.contacts?.find(
    (contact) => (contact as IContact).phoneNumber === user?.phoneNumber
  );
  
  const [state, setState] = React.useState<ReceiveMeetResponseState>(
    getResponseStateFromStatus(currentUser?.status)
  );
  const [visible, setVisible] = React.useState(false);
  
  React.useEffect(() => {
    if (!receiveProps.event?.contacts || !receiveProps.event?.end) {
      return;
    }
  
    const eventEndTime = dayjs(receiveProps.event.end);
    const currentTime = dayjs();
    const currentUser = receiveProps.event.contacts.find(
      (contact) => (contact as IContact).phoneNumber === user?.phoneNumber
    );
  
    // Determine the initial state
    let newState: ReceiveMeetResponseState;
  
    if (eventEndTime.isBefore(currentTime)) {
      // Event has ended
      newState = currentUser?.status 
        ? getResponseStateFromStatus(currentUser.status)
        : ReceiveMeetResponseState.EXPIRED;
    } else {
      // Event hasn't ended yet
      newState = currentUser?.status 
        ? getResponseStateFromStatus(currentUser.status)
        : ReceiveMeetResponseState.NOT_RESPONDED;
    }
  
    setState(newState);
  }, [receiveProps.event?.contacts, receiveProps.event?.end, user?.phoneNumber]);
  
  
  console.log("State: ", state);
  console.log("State: " + state);
  // Mutations
  const [respondEvent] = useRespondEventMutation();
  const [deleteEvent] = useDeleteEventMutation();

  // Date formatting utilities
  const getUserTimezone = () => {
    // eslint-disable-next-line new-cap
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  const formatDateToLocalTimezoneOnly = (date: Date, timezone: string) => {
    return toZonedTime(date, timezone);
  };

  const formatDateToLocalTimezone = (date: Date, timezone: string, formatStr: string) => {
    const zonedDate = toZonedTime(date, timezone);
    return format(zonedDate, formatStr);
  };

  const formatDate = (date: Date, timezone: string) => {
    const dayOfMonth = format(date, 'd');
    return `${format(date, 'EEE, MMM')} ${dayOfMonth}${getOrdinalIndicator(Number(dayOfMonth))}`;
  };

  const getOrdinalIndicator = (day: number) => {
    const j = day % 10;
    const k = day % 100;
    if (j === 1 && k !== 11) return 'st';
    if (j === 2 && k !== 12) return 'nd';
    if (j === 3 && k !== 13) return 'rd';
    return 'th';
  };

  const getResponseEmoji = (response: ReceiveMeetResponseType) => {
    switch(response) {
      case ReceiveMeetResponseType.ACCEPTED:
        return '👍';
      case ReceiveMeetResponseType.DECLINED:
        return '😢';
      case ReceiveMeetResponseType.MAYBE:
        return '🤔';
      default:
        return '';
    }
  };

  const getResponseText = (response: ReceiveMeetResponseType, userName: string) => {
    switch(response) {
      case ReceiveMeetResponseType.ACCEPTED:
        return `${userName} is going to the event`;
      case ReceiveMeetResponseType.DECLINED:
        return `${userName} declined the event`;
      case ReceiveMeetResponseType.MAYBE:
        return `${userName} might go to the event`;
      default:
        return '';
    }
  };
  

  // Event handlers
  const onRespond = async (response: ReceiveMeetResponseType) => {
    const responseMap = {
      [ReceiveMeetResponseType.ACCEPTED]: { state: ReceiveMeetResponseState.ACCEPTED, code: 1 },
      [ReceiveMeetResponseType.DECLINED]: { state: ReceiveMeetResponseState.DECLINED, code: 2 },
      [ReceiveMeetResponseType.MAYBE]: { state: ReceiveMeetResponseState.MAYBE, code: 3 },
    };
   
    const mappedResponse = responseMap[response];
    if (!mappedResponse) return;
   
    // Store previous states in case we need to revert
    const previousAttendees = [...attendingInvitees];
    const previousContacts = [...eventContacts];
    const previousState = state;
    
    // Optimistically update UI states
    setState(mappedResponse.state);
    
    if (mappedResponse.code === 1) { // Accepted
      const matchingContact = eventContacts.find(
        contact => contact.phoneNumber === user?.phoneNumber
      );
      if (matchingContact?.name && !attendingInvitees.includes(matchingContact.name)) {
        setAttendingInvitees(prev => [...prev, matchingContact.name]);
        setEventContacts(prev => prev.map(contact => 
          contact.phoneNumber === user?.phoneNumber 
            ? { ...contact, status: 1 }
            : contact
        ));
      }
    } else { // Declined or Maybe
      setAttendingInvitees(prev => {
        const matchingContact = eventContacts.find(
          contact => contact.phoneNumber === user?.phoneNumber
        );
        return prev.filter(name => name !== matchingContact?.name);
      });
      setEventContacts(prev => prev.map(contact => 
        contact.phoneNumber === user?.phoneNumber 
          ? { ...contact, status: mappedResponse.code }
          : contact
      ));
    }
   
    try {
      // Make API call
      await respondEvent({
        eventId: receiveProps?.event?._id ?? '',
        response: mappedResponse.code,
        phoneNumber: user?.phoneNumber ?? '',
      });
   
      // Create activity data
      const activityData = {
        _id: `temp-${Date.now()}`,
        title: 'RSVP',
        body: getResponseText(response, user?.name ?? 'Someone'),
        type: 'rsvp',
        icon: getResponseEmoji(response),
        eventId: receiveProps?.event?._id ?? '',
        timestamp: new Date().toISOString(),
        organizerName: user?.name ?? 'Someone',
        userId: user?._id || '',
        user: user, 
        status: 1
      } as unknown as IActivity;
   
      // Optimistically update activities
      setEventActivities(prev => [activityData, ...prev]);

      // Create activity in backend
      try {
        await createActivity({
          title: 'RSVP',
          body: getResponseText(response, user?.name ?? 'Someone'),
          type: 'rsvp',
          icon: getResponseEmoji(response),
          eventId: receiveProps?.event?._id ?? '',
          user: user 
        });
      } catch (error) {
        // If activity creation fails, remove optimistic update
        setEventActivities(prev => prev.filter(a => a._id !== activityData._id));
        console.error('Failed to create activity:', error);
      }
   
    } catch (error) {
      // If RSVP update fails, revert all UI changes
      setAttendingInvitees(previousAttendees);
      setEventContacts(previousContacts);
      setState(previousState);
      console.error('Failed to update RSVP:', error);
    }
   };

   const handleShare = async () => {
    const link = `${process.env.REACT_APP_CLIENT_URL}/share/${receiveProps?.event?._id}`;
    if (!navigator.share) {
      await navigator?.clipboard?.writeText(link);
      toast({
        title: 'Link copied to clipboard',
      });
    } else {
      await navigator.share({
        title: 'Share this event',
        text: getFormattedSharingText(user!, receiveProps.event!, link),
        url: link,
      });
    }
  };

  const handleDeleteEvent = async () => {
    if (receiveProps.event?._id) {
      await deleteEvent(receiveProps.event._id);
      navigate('/home');
    }
    setVisible(false);
  };

  // Computed values
  const startTime = formatDateToLocalTimezone(
    receiveProps.event?.start ?? new Date(), 
    getUserTimezone(), 
    'h:mma'
  );
  const isHost = receiveProps.event?.userId === user?._id;
  
    const formatHeaderDate = (date: Date) => {
      try {
        return formatDistanceToNow(date, { addSuffix: true }); // addSuffix: true adds "ago" at the end
      } catch {
        return 'Invalid date';
      }
    };

  useEffect(() => {
    if (receiveProps.event?.contacts) {
      console.log(receiveProps.event.contacts);
      setAttendingInvitees(receiveProps.event.contacts
        .filter((contact) => contact.status === 1)
        .map((contact) => contact.name));
    }
  }
  , [receiveProps.event?.contacts]);

  const initialContacts = receiveProps.event?.contacts ?? [];
  const [eventContacts, setEventContacts] = React.useState<IContact[]>(initialContacts);

  useEffect(() => {
    if (receiveProps.event?.contacts) {
      setEventContacts(prev => {
        if (!prev) return receiveProps.event?.contacts ?? [];
        
        const userContact = prev.find(c => (c as IContact).phoneNumber === user?.phoneNumber);
        if (userContact && receiveProps.event?.contacts) {
          return receiveProps.event.contacts.map(contact => 
            (contact as IContact).phoneNumber === user?.phoneNumber ? userContact : contact
          );
        }
        return receiveProps.event?.contacts ?? [];
      });
    }
  }, [receiveProps.event?.contacts, user?.phoneNumber]);


  return (
    <>
      <div className="relative min-h-screen pb-[200px]">
        <div className="w-full sticky top-0 z-10">
          <ModernActivityHeader 
            isHost={isHost}
            event={receiveProps.event}
            originPage="lets-meet"
            onEditEvent={() => navigate(`/lets-meet/edit/${receiveProps.event?._id}`)}
            onDeleteEvent={() => setVisible(true)}
          />
        </div>

        <div className="px-8">
          <EventOrganizerHeader 
            organizerPhoto={organizerPhoto}
            organizerName={receiveProps.event?.organizer ?? ''}
            sentTime={formatHeaderDate(
              inviteActivity?.timestamp 
                ? new Date(inviteActivity.timestamp)
                : (receiveProps.event?.start ?? new Date())
            )}
            responseState={state}
            isHost={isHost}
            isPast={pastEvent}
          />

          <div className="my-6">
            <EventCard
              title={receiveProps.event?.activity ?? ''}
              emoji={receiveProps.event?.emoji ?? ''}
              guests={attendingInvitees.length ?? 0}
              location={receiveProps.event?.location ?? ''}
              startTime={startTime}
              dateOfEvent={receiveProps.event?.start?.toString() ?? ''}
            />
          </div>
          
          <HorizontalInviteList 
            title="Attendee List" 
            eventId={receiveProps.event?._id} 
            contacts={eventContacts} 
            userPhotos={userPhotos}
            isOrganizer={isHost} 
            shareEvent={handleShare}
          />

          <CommentList
            event={receiveProps}
            refetchEvent={receiveProps.refetch}
            activities={eventActivities}
            userPhotos={userPhotos}
            disabled={pastEvent}
          />
        </div>

        <div className="fixed bottom-0 left-0 w-full z-40">
          {
            !pastEvent && (
              <RSVP
              onRespond={onRespond}
              state={state}
              setState={setState}
              disabled={pastEvent}
              shareEventHandler={handleShare}
            />
            )
          }
        </div>
        <Drawer
          visible={visible}
          setVisible={setVisible}
          className="flex flex-col w-full p-4"
        >
          <Text size="small" className="px-4 mt-2 mb-2 font-semibold text-center text-md">
            Are you sure you want to delete?
          </Text>
          <Text size="medium" className="mb-6 font-light text-center text-sm">
            You cannot undo this action after you confirm.
          </Text>
          <div className="flex items-center w-full gap-4 px-2 mt-4 mb-6">
            <ActionButton
              variant="secondary"
              label="Cancel"
              className="text-sm"
              onClick={() => setVisible(false)}
            />
            <ActionButton
              label="Yes, Delete"
              className="text-sm"
              onClick={handleDeleteEvent}
            />
          </div>
        </Drawer>
      </div>
    </>
  );
};

export default ReceiveMeetLater;
import { ReactNode } from 'react';
import { cn } from 'utils/helpers';

type LayoutProps = {
  children: ReactNode;
  className?: string | undefined;
};

const AuthLayout = ({ children, className }: LayoutProps) => {
  return (
    <div className="flex items-center justify-center w-screen h-screen text-white bg-main">
      <div
        className={cn(
          'flex flex-col items-center w-full h-full max-w-md p-6',
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default AuthLayout;

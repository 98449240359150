import axios from "axios";

export const getUserPhotosForEvent = async (eventId: string) => {
    try {
      // const accessToken = sessionStorage.getItem('accessToken');
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/users/event/${eventId}`,
        // {
        //   headers: {
        //     'Authorization': `Bearer ${accessToken}`
        //   }
        // }
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching user photos:', error);
      return {};
    }
   };
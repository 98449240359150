import React, { useEffect, useState } from 'react';
import { ChevronDown } from 'lucide-react';
import { ReceiveMeetResponseState } from 'modules/letsMeet/types/types';

enum ReceiveMeetResponseType {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  MAYBE = 'MAYBE',
}

const getInitialSelectedResponse = (state: ReceiveMeetResponseState) => {
  switch (state) {
    case ReceiveMeetResponseState.ACCEPTED:
      return ReceiveMeetResponseType.ACCEPTED;
    case ReceiveMeetResponseState.DECLINED:
      return ReceiveMeetResponseType.DECLINED;
    case ReceiveMeetResponseState.MAYBE:
      return ReceiveMeetResponseType.MAYBE;
    default:
      return null;
  }
};
interface RSVPProps {
  onRespond: (response: ReceiveMeetResponseType) => Promise<void>;
  state: ReceiveMeetResponseState;
  setState: (state: ReceiveMeetResponseState) => void;
  disabled?: boolean;
  shareEventHandler?: () => Promise<void>
 }
 
 const RSVP: React.FC<RSVPProps> = ({ onRespond, state, setState, disabled = false, shareEventHandler }) => {
  const [isOpen, setIsOpen] = useState<boolean>(state === ReceiveMeetResponseState.NOT_RESPONDED);
  const [selectedResponse, setSelectedResponse] = useState<ReceiveMeetResponseType | null>(
    getInitialSelectedResponse(state)
  );
 
  useEffect(() => {
    setSelectedResponse(getInitialSelectedResponse(state));
    setIsOpen(state === ReceiveMeetResponseState.NOT_RESPONDED);
  }, [state]);
  
  const handleResponse = async (response: ReceiveMeetResponseType) => {
    if (disabled || selectedResponse === response) {
      return;
    }
    setSelectedResponse(response);
    setState(response as unknown as ReceiveMeetResponseState);
    setIsOpen(false);
    await onRespond(response);
  };
 
  const getButtonBgColor = (responseType: ReceiveMeetResponseType) => {
    if (disabled) return 'bg-gray-500';
    return selectedResponse === responseType ? 'bg-[#6000E9]' : 'bg-[#32395E]';
  };
 
  return (
    <div className="fixed bottom-0 left-0 right-0 flex justify-center pointer-events-none">
      {!isOpen ? (
        <div className="absolute bottom-20 left-1/2 -translate-x-1/2 pointer-events-auto flex flex-col items-center">
          <div className="relative">
            <div className={`w-[80px] h-[80px] ${disabled ? 'bg-gray-500' : 'bg-[#6000E9]'} rounded-full flex items-center justify-center`}>
              {selectedResponse === ReceiveMeetResponseType.ACCEPTED && <span className="text-3xl">👍</span>}
              {selectedResponse === ReceiveMeetResponseType.DECLINED && <span className="text-3xl">😢</span>}
              {selectedResponse === ReceiveMeetResponseType.MAYBE && <span className="text-2xl">🤔</span>}
            </div>
            <button
              onClick={() => !disabled && setIsOpen(true)}
              className={`absolute -bottom-1 -right-1 w-[38px] h-[38px] bg-[#242632] rounded-full flex items-center justify-center shadow-lg ${
                disabled ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={disabled}
            >
              <ChevronDown className="w-5 h-5 text-white" />
            </button>
          </div>
          {state !== ReceiveMeetResponseState.NOT_RESPONDED && selectedResponse && shareEventHandler && (
            <button 
              onClick={(e) => {shareEventHandler()}}
              className="mt-[19px] text-white text-center font-manrope text-base font-normal border-b border-white/50 cursor-pointer pointer-events-auto"
            >
              Click to share
            </button>
          )}
        </div>
      ) : (
        <div className="relative w-[389px] h-[180px] pointer-events-none">
          {/* Maybe button */}
          <button
            onClick={() => handleResponse(ReceiveMeetResponseType.MAYBE)}
            disabled={disabled}
            className={`absolute bottom-[160px] left-1/2 -translate-x-1/2 w-[110px] h-[44px] ${getButtonBgColor(
              ReceiveMeetResponseType.MAYBE
            )} rounded-full flex justify-center items-center gap-2 pointer-events-auto transition-colors ${
              disabled ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            <span className="text-xl">🤔</span>
            <span
              className="text-white text-sm font-medium"
              style={{ fontFamily: 'SF Pro Display, system-ui' }}
            >
              Maybe
            </span>
          </button>
 
          {/* Bottom emoji buttons */}
          <div className="absolute bottom-20 flex justify-between w-full px-6 pointer-events-none">
            {/* Decline button */}
            <button
              onClick={() => handleResponse(ReceiveMeetResponseType.DECLINED)}
              disabled={disabled}
              className={`w-[80px] h-[80px] ${getButtonBgColor(
                ReceiveMeetResponseType.DECLINED
              )} rounded-full flex items-center justify-center transition-all hover:scale-105 active:scale-95 pointer-events-auto ${
                disabled ? 'opacity-50 cursor-not-allowed hover:scale-100 active:scale-100' : ''
              }`}
            >
              <span className="text-3xl">😢</span>
            </button>
 
            {/* Accept button */}
            <button
              onClick={() => handleResponse(ReceiveMeetResponseType.ACCEPTED)}
              disabled={disabled}
              className={`w-[80px] h-[80px] ${getButtonBgColor(
                ReceiveMeetResponseType.ACCEPTED
              )} rounded-full flex items-center justify-center transition-all hover:scale-105 active:scale-95 pointer-events-auto ${
                disabled ? 'opacity-50 cursor-not-allowed hover:scale-100 active:scale-100' : ''
              }`}
            >
              <span className="text-3xl">👍</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
 };
 
 export default RSVP;
import { createApi } from '@reduxjs/toolkit/query/react';
import { isPast } from 'date-fns';
import baseQueryWithReauth from 'services/mutationInterceptor';
import { ITimeBlock } from 'modules/auth/types/types';
import { Contact } from 'modules/profile/types/types';

// TODO: Expose the types of the request and response and move them into the types folder
export interface CreateEventRequest {
  activity: string;
  emoji: string;
  location: string;
  contacts: Contact[];
  details: string;
  type: string;
  start: Date | null;
  end: Date | null;
  prevPollId?: string; // used for deleting a poll after creating an event from it
}

interface RespondEventRequest {
  eventId: string;
  response: number;
  phoneNumber: string;
}

interface AddEventCommentRequest {
  eventId: string;
  comment: string;
}

interface ShareEventRequest {
  fromName: string;
  eventName: string;
  endTime: string | null;
  eventLink: string;
  to: string[];
  messageType: string;
}

interface EventResponse {
  // Define the structure of the event response here
}

export const eventApi = createApi({
  reducerPath: 'eventApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Get', 'Event'],
  endpoints: (builder) => ({
    createEvent: builder.mutation<any, any>({
      query: (data) => ({
        url: '/event',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Event','Get'],
    }),
    respondEvent: builder.mutation<any, RespondEventRequest>({
      query: (data) => ({
        url: '/event/respond',
        method: 'POST',
        body: data,
      }),
    }),
    shareEvent: builder.mutation<any, ShareEventRequest>({
      query: (data) => ({
        url: '/sms/send',
        method: 'POST',
        body: data,
      }),
    }),
    addEventComment: builder.mutation<any, AddEventCommentRequest>({
      query: (data) => ({
        url: '/event/comment',
        method: 'POST',
        body: data,
      }),
    }),
    getEvent: builder.query<any, string>({
      query: (id) => ({
        url: `/event/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'Event', id }],
      transformResponse: (response) => {
        console.log('Event response:', response);
        return response;
      },
    }),
    getEvents: builder.query<any, void>({
      query: () => ({
        url: `/event`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        console.log('Event response:', response);
        return response;
      },
    }),
    getEventsByStatus: builder.query<any, number>({
      query: (status) => ({
        url: `/event/status/${status}`,
        method: 'GET',
      }),
    }),
    updateEvent: builder.mutation<any, EventResponse>({
      query: (data) => ({
        url: '/event',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags(result, error, arg:any, meta) {
        return [arg.id?{ type: 'Event', id: arg.id }:{ type: 'Event' }];
      },
    }),
    updateEventStatus: builder.mutation<number, EventResponse>({
      query: (status) => ({
        url: '/event/status',
        method: 'PUT',
        body: status,
      }),
    }),
    deleteEvent: builder.mutation<any, EventResponse>({
      query: (id: string) => ({
        url: `/event/${id}`,
        method: 'DELETE',
      }),
    }),
    getAllPendingEvents: builder.query<any, number>({
      query: (page) => ({
        url: `event/pending?page=${page}&history=true`,
        method: 'GET',
      }),
    }),
    getPendingEvents: builder.query<any, { page: number; isPast?: boolean }>({
      query: ({ page, isPast }) => ({
        url: `event/pending?page=${page}${isPast === true ? '&history=true' : isPast === false ? '&history=false' : ''}`,
        method: 'GET',
      }),
    }),
    getMyEvents: builder.query<any, { status: number; page: number }>({
      query: ({ status, page }) => ({
        url: `event/my-events/${status}?page=${page}&history=false`,
        method: 'GET',
      }),
      providesTags:['Event','Get'],
    }),
    getAllEvents: builder.query<any, { page: number; history: string }>({
      query: ({ page, history }) => ({
        url: `event/all-events?page=${page}&history=${history}`,
        method: 'GET',
      }),
    }),
    getEventByDateRange: builder.query<
      any,
      { startDate: string; endDate: string }
    >({
      query: ({ startDate, endDate }) => ({
        url: `event/range?startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
      }),
    }),
    createTimeOff: builder.mutation<any, ITimeBlock>({
      query: (body) => ({
        url: '/time-off',
        method: 'POST',
        body,
      }),
    }),
    getTimeOffByDateRange: builder.query<
      any,
      { startDate: string; endDate: string }
    >({
      query: ({ startDate, endDate }) => ({
        url: `time-off?startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
      }),
    }),
    deleteTimeOff: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `/time-off/${id}`,
        method: 'DELETE',
      }),
    }),
    checkTimeAvaliability: builder.query<
      any,
      { startDateTime: string; endDateTime: string }
    >({
      query: ({ startDateTime, endDateTime }) => ({
        url: `event/check-availability?startDateTime=${startDateTime}&endDateTime=${endDateTime}`,
        method: 'GET',
      }),
    }),
    checkPollingAvaliabilities: builder.mutation<any, string[]>({
      query: (body) => ({
        url: '/availability-check',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useCreateEventMutation,
  useRespondEventMutation, 
  useShareEventMutation,
  useAddEventCommentMutation,
  useGetEventQuery,
  useGetEventsQuery,
  useGetEventsByStatusQuery,
  useUpdateEventMutation,
  useUpdateEventStatusMutation,
  useDeleteEventMutation,
  useGetAllPendingEventsQuery,
  useGetPendingEventsQuery,
  useGetMyEventsQuery,
  useGetAllEventsQuery,
  useGetEventByDateRangeQuery,
  useGetTimeOffByDateRangeQuery,
  useCreateTimeOffMutation,
  useDeleteTimeOffMutation,
  useCheckTimeAvaliabilityQuery,
  useCheckPollingAvaliabilitiesMutation
} = eventApi;

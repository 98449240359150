import React from 'react';
import { ChevronLeft, MoreVertical } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { ReactComponent as CaleidoLogo } from 'assets/images/common/logo.svg';
import { ReactComponent as ButterflyIcon } from 'assets/images/common/logo-butterfly.svg';
import { IEvent } from 'types/event';
import SideBarButton from 'components/sidebar/sidebarButton';

interface ModernActivityHeaderProps {
  isHost?: boolean;
  event?: IEvent | null;
  originPage?: string;
  navigateBackUrl?: string;
  onEditEvent?: () => void;
  onDeleteEvent?: () => void;
  isGuestView?: boolean;
}

const ModernActivityHeader = ({
  isHost = false,
  event,
  originPage = 'home',
  navigateBackUrl,
  onEditEvent,
  onDeleteEvent,
  isGuestView,
}: ModernActivityHeaderProps) => {
  const [showMenu, setShowMenu] = React.useState(false);
  const navigate = useNavigate();

  const getFormattedNavigateBackFromViewUrl = () => {
    let formattedDate = undefined;
    if (navigateBackUrl) {
      return navigateBackUrl;
    }
    if (event) {
      const eventDate = event?.start ?? new Date();
      formattedDate = format(eventDate, 'yyyy-MM-dd');
    }
    if (formattedDate) {
      return `/${originPage}?date=${formattedDate}${event?._id ? `&ref=${event._id}` : ''}`;
    } else {
      return `/${originPage}${event?._id ? `?ref=${event._id}` : ''}`;
    }
  };

  const handleBack = () => {
    // navigate(getFormattedNavigateBackFromViewUrl());
    navigate('/home')
  };

  const handleMenuToggle = () => {
    if (isHost) {
      setShowMenu(!showMenu);
    }
  };

  return (
    <header className="w-full pt-6 relative">
      <div className="flex items-center justify-between py-5 px-8 max-w-md mx-auto">
        <div className="flex items-center space-x-3">
          {(!isGuestView && (
              <button 
              onClick={handleBack}
              className="p-2 -ml-2 text-white hover:bg-white/10 rounded-full transition-colors"
            >
              <ChevronLeft className="w-5 h-5" />
            </button>
          ))
          } 
        
          <div className="flex items-center">
            <CaleidoLogo className="w-23 h-3.5" />
            <ButterflyIcon className="w-4 h-4 ml-1" />
          </div>
        </div>

        <div className="relative">

          {
            !isGuestView && (
               <button
                  onClick={handleMenuToggle}
                  className="p-2 text-white hover:bg-white/10 rounded-full transition-colors"
                >
                    {isHost ? (
                      <MoreVertical className="w-5 h-5" />
                    ) : (
                      <SideBarButton />
                    )}
            </button>
            )
          }
          

          {/* Host Menu Dropdown */}
          {isHost && showMenu && (
            <div className="absolute right-0 top-full mt-2 w-48 bg-[#1A1F37] rounded-lg shadow-lg overflow-hidden z-50">
              {onEditEvent && (
                <button
                  onClick={() => {
                    onEditEvent();
                    setShowMenu(false);
                  }}
                  className="w-full px-4 py-3 text-left text-white hover:bg-white/10 transition-colors text-sm"
                >
                  Edit Details
                </button>
              )}
              {onDeleteEvent && (
                <button
                  onClick={() => {
                    onDeleteEvent();
                    setShowMenu(false);
                  }}
                  className="w-full px-4 py-3 text-left text-red-500 hover:bg-white/10 transition-colors text-sm"
                >
                  Delete Event
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default ModernActivityHeader;
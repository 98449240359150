import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ChevronRight, Loader, X } from 'lucide-react';
import LetsMeetLayout from 'modules/letsMeet/layouts/LetsMeetLayout';
import MainButton from 'modules/auth/components/MainButton';
import { Text } from 'components/text/primary/Text';
import OverlayLoader from 'components/loader/OverlayLoader';
import TextField from 'components/textfield/TextField';
import { format, parseISO } from 'date-fns';
import Drawer from 'modules/settings/components/contactActions/Drawer/Drawer';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  useUpdateEventMutation,
  useGetEventQuery,
  useGetEventsQuery,
} from 'services/event.service';
import { ReactComponent as NowIcon } from 'assets/images/event/now.svg';
import { ReactComponent as LaterIcon } from 'assets/images/event/later.svg';
import { ReactComponent as PollIcon } from 'assets/images/event/poll.svg';
import { ReactComponent as NowActiveIcon } from 'assets/images/event/nowPurple.svg';
import { ReactComponent as LaterActiveIcon } from 'assets/images/event/laterPurple.svg';
import { ReactComponent as PollActiveIcon } from 'assets/images/event/pollPurple.svg';
import { ReactComponent as PlusIcon } from 'assets/images/event/plus.svg';
import IconsList from 'components/iconsList/IconsList';
import EventIcon from 'components/eventIcon/EventIcon';
import CitySuggestion from 'components/citySuggestion/CitySuggestion';
import { useAppSelector } from 'hooks/useAppSelector';
import { RootState } from 'app/store';
import { Contact, IAvailabilityPeriod } from 'modules/profile/types/types';
import ToggleSwitch from 'components/toggle/ToggleSwitch';
import { Calendar } from 'components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { useNavigate, useParams } from 'react-router-dom';
import { Checkbox } from 'components/ui/checkbox';
import dayjs from 'dayjs';
import { DateRange } from 'react-day-picker';
import { ReactComponent as UserAvatar } from 'assets/images/common/avatar.svg';
import { ITimeSlot } from 'modules/profile/components/availability/TimeSlot/TimeSlot';
import AvailabilityTimeSelector from 'modules/profile/components/availability/TimeDropDown/availabilityTimeSelector';
import CustomSelect from 'modules/letsMeet/components/select/select';
import { generateTimeOptions, toNextDay } from 'modules/letsMeet/utils/lib';
import ContactsList from 'modules/letsMeet/components/contactsList/contactsList';
import TextAreaField from 'components/textArea/TextAreaField';
import { NotificationType } from 'modules/letsMeet/utils/enum';
import { useSendNotificationMutation } from 'services/notification.service';
import InLineLoader from 'components/loader/InlineLoader';
import { DatePickerWithRange } from 'components/ui/datePickerWithRange';
import { ReactComponent as DeleteIcon } from 'assets/images/profile/delete-icon.svg';
import RecurringAvailabilitySlot from 'modules/profile/components/availability/AvailabilitySlot/RecurringAvailabilitySlot';
import { cn } from 'utils/helpers';
import { useGetAvailabilitiesQuery } from 'services/calendar.service';
import { AvailabilityPeriodModel } from 'modules/profile/model/Period';

type FormValues = {
  activity: string;
  details: string;
};
interface IDateInfo {
  date: Date;
  startTime: Date;
  endTime: Date;
}
interface AvailabilitySlot {
  start: string;
  end: string;
}
interface AvailabilityResponse {
  manual?: boolean;
  date: string;
  times: AvailabilitySlot[];
}

interface AvailabilitySlot {
  start: string;
  end: string;
}
interface AvailabilityResponse {
  manual?: boolean;
  date: string;
  times: AvailabilitySlot[];
}

type CheckedTimesTypes = {
  time: string, 
  voters: []
}

// Create a custom theme
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: 'rgb(99,91,232)',
    },
  },
  typography: {
    body1: {},
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: 'rgba(0, 1, 41, 1)',
          color: '#fff',
        },
      },
    },
  },
});

const inputStyles = {
  '& *': {
    borderColor: 'transparent',
  },

  '& .MuiInputBase-root': {
    backgroundColor: '#32395E',
    borderRadius: '30px',
    border: 'none',
    padding: '10px',
    paddingLeft: '18px',
    fontSize: '14px',
    outline: 'none',
  },
  '& .MuiInputBase-input': {
    padding: '0',
    textAlign: 'left',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },

  '& .MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },

  '& .MuiIconButton-root': {
    opacity: 0.7,
  },
};

const EditEvent = forwardRef<() => void, {endPollEvent?: boolean}>(({endPollEvent}, ref) => {
  const { id, dateSelected } = useParams<{ id: string, dateSelected: string }>();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [location, setLocation] = useState<string>('');
  const [icon, setIcon] = useState<string>('');
  const [locationMenuVisible, setLocationMenuVisible] = useState(false);
  const user = useAppSelector((state: RootState) => state.auth.user);
  const [userContacts, setUserContacts] = useState<Contact[]>([]);
  const [isContactVisible, setIsContactVisible] = useState(false);
  const [isMeetNowChecked, setIsMeetNowChecked] = useState(false);
  const [type, setType] = useState<string>('');
  const [laterDate, setLaterDate] = useState<Date>();
  const [nowEndTime, setNowEndTime] = useState(new Date());
  const [nowEndSelectTime, setNowEndSelectTime] = useState<any | null>(null);
  const [laterStartTime, setLaterStartTime] = useState(new Date());
  const [laterEndTime, setLaterEndTime] = useState(new Date());
  const [gCalendarEventId, setGCalendarEventId] = useState<string | null>(null);
  const [selectedNowEndValue, setSelectedNowEndValue] = useState<string | null>(
    '120',
  );
  const [publicEvent, setPublicEvent] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [formValues, setFormValues] = useState<FormValues>({
    activity: '',
    details: '',
  });
  const [dateRange, setDateRange] = useState<Date[] | undefined>();
  const [updateEvent, { data, error, isLoading }] = useUpdateEventMutation();
  const navigate = useNavigate();
  const { data: event, refetch: refetchEvent } = useGetEventQuery(id || '');
  const { data: allEventData, refetch: refetchAllEvents } = useGetEventsQuery();
  const [checkedTimes, setCheckedTimes] = useState<string[]>([]);
  const [selectedDuration, setSelectedDuration] = useState<number | null>(null);
  const [recurringTimes, setRecurringTimes] = useState<IAvailabilityPeriod[]>(
    [],
  );
  const [visibleOption, setVisibleOption] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [endEnable, setEndEnable] = useState<boolean>(false);
  const [sendNotification] = useSendNotificationMutation()
  const [manualAvailabilitySlots, setManualAvailabilitySlots] = useState<
    AvailabilityResponse[]
  >([]);
  const [organizerContact, setOrganizerContact] = useState<Contact | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null); 
  const [availabiltySlots, setAvailabiltySlots] = useState<
  AvailabilityResponse[]
>([]);

const formattedDateList = dateRange?.map(date => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
});

const {
  data: availabilities,
  error: availabilitiesError,
  isLoading: availabilitiesLoading,
  refetch: refetchAvailabilities,
} = useGetAvailabilitiesQuery(
  {
    dates: formattedDateList || [],
    interval: selectedDuration || 0,
  },
  {
    skip: !dateRange || !dateRange?.[0] || !selectedDuration, // Skip the query if date or duration is undefined
  },
);

  useEffect(() => {
    refetchEvent();

    if (event) {
      setFormValues({
        activity: event.activity || '',
        details: event.details || '',
      });

      setIcon(event.emoji || '');
      setLocation(event.location || '');
      setType(event.type || '');
      setPublicEvent(event.publicEvent || false);

      setLaterDate(event.type === 'later' ? event.start : null);
      setLaterStartTime(event.type === 'later' ? event.start : null);
      setLaterEndTime(event.type === 'later' ? event.end : null);

      setNowEndTime(event.type === 'now' ? event.end : null);

      setGCalendarEventId(event.gCalendarEventId || null);

      // Filter and set contacts where organizer equals 0
      const filteredContacts =
        event.contacts?.filter((contact: any) => contact.organizer === 0) || [];
      setSelectedContacts(filteredContacts);
      const organizerContact = event.contacts?.find((contact: any) => contact.organizer === 1);

      if (event.type === 'poll') {
        const formattedCheckedTimes = event.pollData?.checkedTimes?.map((checkedTime: { time: string; }) => checkedTime.time)
        const manualTimes = transformTimeSlots(formattedCheckedTimes)
        setManualAvailabilitySlots(manualTimes)
        setCheckedTimes(formattedCheckedTimes || []);

        if (endPollEvent) {
          const dateInfo = getMeetLaterDateInfo(dateSelected ?? '');
          const voters = event?.pollData?.checkedTimes?.find((dates: any) => dates?.time === dateSelected)?.voters || []
          const votedContacts = filteredContacts?.filter((contact: any) => voters?.some((voter: any) => voter.userId === contact.userId)) || [];
          const votedContactsWithResponse = votedContacts.map((contact: Contact) => {
            return{
              ...contact,
              status: 0
            }
          } )

          setSelectedContacts(votedContactsWithResponse);
          if (dateInfo) {
            setType('later')
            setLaterDate(dateInfo?.startTime); // The issue with just using date here is the time is truncated, which will then render the incorrect time when going from utc to local. Makes sense to just pass the start time here.
            setLaterStartTime(dateInfo.startTime);
            setLaterEndTime(dateInfo.endTime);
          }
        }
      }
      setOrganizerContact(organizerContact);
    }
  }, [event]);

  // Effect to log the available times when they are loaded
  useEffect(() => {
    if (availabilities) {
      console.log('Availabilities:', availabilities);
      setAvailabiltySlots(availabilities); // Replace previous state with new availabilities
    }
  }, [availabilities, availabilitiesLoading, selectedDuration]);

  const transformTimeSlots = (timeSlots: string[]): AvailabilityResponse[] => {
    const result: AvailabilityResponse[] = [];
 
    timeSlots?.forEach((slot) => {
      // Match date, start time (with optional timezone), and end time (with optional timezone)
      const matchResult = slot.match(
        /(\d{4}-\d{2}-\d{2})-(\d{2}:\d{2}(?:[+-]\d{4}|Z)?)-(\d{2}:\d{2}(?:[+-]\d{4}|Z)?)/
      );
  
      if (matchResult) {
        const [date, startTime, endTime] = matchResult.slice(1);
  
        const existingEntry = result.find((entry) => entry.date === date);
  
        const timeSlotObj: AvailabilitySlot = {
          start: startTime,
          end: endTime,
        };
  
        if (existingEntry) {
          existingEntry.times.push(timeSlotObj);
        } else {
          result.push({
            date: date,
            times: [timeSlotObj],
            manual: true,
          });
        }
      }
    });
  
    return result;
  };
  
  const getMeetLaterDateInfo = (timeSlot: string): IDateInfo | null => {
    if (!timeSlot) return null

    const matchResult = timeSlot?.match(
      /(\d{4}-\d{2}-\d{2})-(\d{2}:\d{2}(?:[+-]\d{4}|Z)?)-(\d{2}:\d{2}(?:[+-]\d{4}|Z)?)/
    );

    if (matchResult) {
      const [date, startTime, endTime] = matchResult.slice(1);

      return {
        date: new Date(date),
        startTime: new Date(`${date}T${startTime}`),
        endTime: new Date(`${date}T${endTime}`),
      } as IDateInfo;

    } else {
      return {
        date: new Date(),
        startTime: new Date(),
        endTime: new Date(),
      } as IDateInfo;
    }
  };

  const validateForm = () => {
    const formErrors: { [key: string]: string } = {};

    if (!formValues.activity) {
      formErrors.activity = 'Activity is required';
    }

    if (type === '') {
      formErrors.when = 'When is required';
    }

    if (type === 'later') {
      if (!laterDate) {
        formErrors.laterDate = 'Date is required';
      }

      if (!laterStartTime) {
        formErrors.laterStartTime = 'Start time is required';
      }
    }

    // disabling validations
    /*
    if (!formValues.activity) formErrors.activity = 'Activity is required';
    if (!formValues.details) formErrors.details = 'Details are required';
    if (!location) formErrors.location = 'Location is required';
    */
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    },
    [],
  );

  const handleInputChangeTextArea = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { name, value } = event.target;
      setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    },
    [],
  );

  const removeContact = (phoneNumber: string) => {
    setSelectedContacts((prevContacts) =>
      prevContacts.filter((contact) => contact.phoneNumber !== phoneNumber),
    );
  };

  const handleTickOutCaleido = () => {
    setPublicEvent(!publicEvent);
  };

  const handleNowEndTimeChange = (time: ITimeSlot) => {
    setNowEndTime(new Date(time.dateTime));
  };

  const handleLaterEndTimeChange = (time: ITimeSlot) => {
    setLaterEndTime(new Date(time.dateTime));
  };

  const handleLaterStartTimeChange = (time: ITimeSlot) => {
    setLaterStartTime(new Date(time.dateTime));
    setEndEnable(true);
  };

  const onNowSwitchChange = () => {
    setIsMeetNowChecked(!isMeetNowChecked);
  };

  const handleCheckboxChangeAvailability = (startTime: string, endTime: string) => {
    const dateTime = formatUtcDateTime(startTime, endTime)
    setCheckedTimes((prev) =>
      prev.includes(dateTime) ? prev.filter((t) => t !== dateTime) : [...prev, dateTime],
    );
  };

  const handleCheckboxChange = (time: string) => {
    setCheckedTimes((prev) =>
      prev.includes(time) ? prev.filter((t) => t !== time) : [...prev, time],
    );
  };

  const handleDeleteManualAvailability = (date: string, timeIndex: number) => {
    const filteredManualAvailability = manualAvailabilitySlots.map((availability) => {
      if (availability.date === date) {
        return {
          ...availability,
          times: availability.times.filter((_, index) => index !== timeIndex)
        };
      }
      return availability;
    });

    setManualAvailabilitySlots(filteredManualAvailability)
  }

  const handleDeleteAvailability = (index: number) => {
    setAvailabiltySlots((prevAvailabilities) => {
      let slotIndex = 0;
      const updatedAvailabilities = prevAvailabilities.reduce<
        AvailabilityResponse[]
      >((acc, day) => {
        const filteredTimes = day.times.filter((_, timeIndex) => {
          if (slotIndex === index) {
            slotIndex++;
            return false;
          }
          slotIndex++;
          return true;
        });

        if (filteredTimes.length > 0) {
          acc.push({
            ...day,
            times: filteredTimes,
          });
        }

        return acc;
      }, []);

      return updatedAvailabilities;
    });
  };

  const handleOpenModal = () => {
    setSelectedDate(undefined)
    setRecurringTimes([new AvailabilityPeriodModel([])])
    setVisibleOption(true);
  };

  const isTimeConflict = (
    existingTimes: AvailabilitySlot[],
    newTime: AvailabilitySlot,
  ) => {
    return existingTimes.some(
      ({ start, end }) =>
        (newTime.start >= start && newTime.start < end) ||
        (newTime.end > start && newTime.end <= end) ||
        (newTime.start <= start && newTime.end >= end),
    );
  };

  const mergeAvailabilities = (
    availabilities1: AvailabilityResponse[],
    availabilities2: AvailabilityResponse[],
  ): AvailabilityResponse[] => {
    const updatedAvailabilities = availabilities1.map((availability) => {
      const matchingSlot = availabilities2.find(
        (slot) => slot.date === availability.date,
      );

      if (matchingSlot) {
        const nonConflictingTimes = matchingSlot.times.filter(
          (newTime) => !isTimeConflict(availability.times, newTime),
        );

        return {
          ...availability,
          times: [...availability.times, ...nonConflictingTimes],
        };
      }

      return availability;
    });

    // Add new dates that are not already in availabilities1
    availabilities2?.forEach((slot) => {
      if (
        !updatedAvailabilities.some(
          (availability) => availability.date === slot.date,
        )
      ) {
        const copyOfSlot = {...slot}
        copyOfSlot.manual = true
        updatedAvailabilities.push(copyOfSlot);
      }
    });

    return updatedAvailabilities;
  };

  const handleSave = () => {
    if (!selectedDate) {
      alert('Please select a date');
      return;
    }

    if (recurringTimes.length === 0) {
      alert('Please select a time slot');
      return;
    }

    const dateObj = new Date(selectedDate);

    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    // add the recurring times to availabilities
    const newSlots: AvailabilitySlot[] = recurringTimes.map(
      ({ start, end }) => {
        try {
          const formattedStart = new Date(start.dateTime).toLocaleTimeString(
            'en-US',
            {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            },
          );

          const formattedEnd = end
            ? new Date(end.dateTime).toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
              })
            : '';

            return { start: formattedStart, end: formattedEnd };
        } catch (error) {
          console.error('Error formatting time:', error);
          return { start: '', end: '' };
        }
      },
    );

    const newManualTimes: AvailabilityResponse[] = [
      {
        date: formattedDate,
        times: newSlots
      }
    ]

    const manualMerged = mergeAvailabilities(manualAvailabilitySlots, newManualTimes);
    console.log('manualMerged', manualMerged);
    setManualAvailabilitySlots((prev) => manualMerged);
    setVisibleOption(false);
  };

  const formatDate = (dateString: string) => {
    try {
      const date = parseISO(dateString);
      return format(date, 'EEE, MMM do');
    } catch (error) {
      console.error('Error parsing date:', error);
      return 'Invalid Date';
    }
  };

  const formatUtcDateTime = (startTime: string, endTime: string) => {
    try{ 
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);

    const date = startDate.toLocaleDateString('en-CA');

    const startFormatted = startDate.toISOString().split('T')[1].slice(0, 5); 
    const endFormatted = endDate.toISOString().split('T')[1].slice(0, 5);

    // Combine date and time into the desired format
    return `${date}-${startFormatted}Z-${endFormatted}Z`;
    } catch (e) {
      return 'Invalid Time'
    }
  }

  const formatDateUtc = (dateString: any) => {
    try { 
    const date = new Date(dateString)
    return format(date, 'EEE, MMM do');
    } catch (e) {
      return 'Invalid Time'
    }
  };

  const formatTimeUtc = (timeString: string) => {
    try { 
    const date = new Date(timeString)
    return format(date, 'hh:mm a');
    } catch (e) {
      return 'Invalid Time'
    }
  };
  
  const formatTime = (dateString: string, timeString: string) => {
    try { 
    const date = new Date(`${dateString}T${timeString}`)
    return format(date, 'hh:mm a');
    } catch (e) {
      return 'Invalid Time'
    }
  };

  const onChangeType = (typeOption: string) => {
    setType(typeOption);
  };

  const [searchValue, setSearchValue] = useState('');
  const handleSearchChange = (e: string) => {
    setSearchValue(e);
  };

  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
  const handleContactClick = (contact: Contact) => {
    setSearchValue('');
    setSelectedContacts((prevContacts) => [...prevContacts, contact]);
  };

  const handleConfirmationModal = useCallback((value: boolean) => {
    setVisible(value);
  }, []);

  const showableAvailabilities = mergeAvailabilities(
    availabiltySlots,
    manualAvailabilitySlots,
  );

  // convert the date-time string into a Date object
  const getDateFromString = (dateTimeString: string): Date | any => {
    const parts = dateTimeString.split('-');
    const datePart = parts.slice(0, 3).join('-');
    const startTime = parts[3];
    const formattedString = `${datePart}T${startTime}`;

    return new Date(formattedString);
  };

  const sortDateTimeArray = (dateTimeArray?: string[]): string[] => {
    if (!dateTimeArray || dateTimeArray?.length < 1)
      return []

    const copyOfArray = JSON.parse(JSON.stringify(dateTimeArray))
    return copyOfArray.sort((a: string, b: string) => {
      // Extract the date and start time part from each string
      const dateA = getDateFromString(a);
      const dateB = getDateFromString(b);

      // Sort based on the timestamp (milliseconds since the Unix epoch)
      return dateA - dateB;
    });
  };

  useEffect(() => {
    return () => {
      setVisible(false);
    };
  }, [icon]);

  useEffect(() => {
    console.log(location);
  }, [location]);

  useEffect(() => {
    if (user && user.contacts) {
      console.log(user.contacts);
      setUserContacts(user.contacts);
    }
  }, [user]);

  useEffect(() => {
    if (selectedNowEndValue) {
      const endTime = new Date();
      endTime.setMinutes(endTime.getMinutes() + parseInt(selectedNowEndValue));
      console.log(endTime);
      setNowEndSelectTime(endTime);
    }
  }, [selectedNowEndValue]);

  const laterEventEndInNextDay = useMemo(() => {
    if (!laterEndTime) return false;
    if (!laterStartTime) return false;
    if (!laterDate) return false;

    const date = dayjs(laterDate);

    const startTime = dayjs(laterStartTime);
    startTime
      .set('year', date.year())
      .set('month', date.month())
      .set('date', date.date());

    const endTime = dayjs(laterEndTime);
    endTime
      .set('year', date.year())
      .set('month', date.month())
      .set('date', date.date());

    return endTime.isBefore(startTime);
  }, [laterEndTime]);

  const handleContactVisibility = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if ((event.target as HTMLElement).id === 'withWho') {
      setIsContactVisible(true);
    } else {
      setIsContactVisible(false);
    }
  };

  const pollData = {
    checkedTimes: checkedTimes.map((time) => ({
      time: time,
      voters: [],
    })),
  };

  const handleSubmit = () => {
    if (!validateForm()) return;
    // if (!icon) {
    //   alert('Icon is required');
    //   return;
    // }
    
    // TODO: fix selected time not properly reflected when quickly click on create event button
    // click blur event for all time picker elements
    const timeSelectors = document.querySelectorAll('.native-time-selector');
    timeSelectors.forEach((selector) => {
      (selector as HTMLInputElement)?.blur();
    });

    if (type === 'poll' && checkedTimes.length < 2) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        checkedTimes: 'At least 2 times must be selected',
      }));
      return;
    }

    const { activity, details } = formValues;
    const laterFixedStartTime = new Date(laterStartTime);
    const laterFixedEndTime = new Date(laterEndTime);

    if (laterDate) {
      const laterDateObj = new Date(laterDate);
      const year = laterDateObj.getFullYear();
      const month = laterDateObj.getMonth();
      const date = laterDateObj.getDate();
      laterFixedStartTime.setFullYear(year);
      laterFixedStartTime.setMonth(month);
      laterFixedStartTime.setDate(date);
      laterFixedEndTime.setFullYear(year);
      laterFixedEndTime.setMonth(month);
      laterFixedEndTime.setDate(date);
      if (laterFixedEndTime < laterFixedStartTime) {
        laterFixedEndTime.setDate(laterFixedEndTime.getDate() + 1);
      }
    }
    const sortedCheckedTimes = sortDateTimeArray(checkedTimes)

    let calculatedStartTime = new Date();
    if (type === 'later') {
      calculatedStartTime = laterFixedStartTime
    } else if (type === 'poll') {
      calculatedStartTime = getDateFromString(sortedCheckedTimes[0])
    }

    // "now" and "later" events If the user do not add an end time, the event should be a 2 hour event on default.
    let calculatedEndTime = new Date();
    if (type === 'now') {
      if(nowEndTime){
        calculatedEndTime = nowEndTime;
      } else {
        const endTime = new Date();
        endTime.setHours(endTime.getHours() + 2);
        calculatedEndTime = endTime;
      }
    } else if (type === 'later') {
      if (!laterEndTime) {
        const endTime = new Date(laterFixedStartTime);
        endTime.setHours(endTime.getHours() + 2);
        calculatedEndTime = endTime;
      } else {
        calculatedEndTime = laterFixedEndTime;
      }
    } else if (type === 'poll') {
      calculatedEndTime = getDateFromString(sortedCheckedTimes[sortedCheckedTimes?.length - 1])
    }

    // add the organizer contact to the selected contacts when poll turn into event
    const eventContacts = [...selectedContacts,organizerContact] ;

    const data = {
      id,
      activity,
      emoji: icon,
      location: location,
      contacts: eventContacts,
      details,
      publicEvent,
      type: type,
      start: calculatedStartTime,
      end:calculatedEndTime,
      pollData: type === 'poll' ? pollData : null,
      gCalendarEventId,
    };

    const notificationData = {
      type: NotificationType.EVENT_CHANGE,
      eventId: data?.id
    }

    updateEvent(data)
      .then((response) => {
        const responseId = response.data.message._id;

        // Function to extract time in 'HH:mm:ss' format
        const getTimeFromDate = (date: Date) => {
          return new Date(date).toLocaleTimeString('en-GB', { hour12: false });
        };

        // Check for time change
        const isNewTime = getTimeFromDate(event?.start) !== getTimeFromDate(data?.start) || getTimeFromDate(event?.end) !== getTimeFromDate(data?.end);
        // Check for location (venue) change
        const isNewLocation = event?.location !== data?.location;
        // Check for date change
        const isNewDate = new Date(event?.start).toDateString() !== new Date(data?.start).toDateString();

        navigate(`/lets-meet/review/${id}`, {
          state: { isEditEvent: true, isNewDate, isNewLocation, isNewTime },
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  if (!id) {
    return null;
  }
 

  const handleIconSelect = (icon: React.SetStateAction<string>) => {
    setIcon(icon);

    // Scroll to the beginning of the scrollable section
    if (containerRef.current) {
      containerRef?.current?.scrollTo({
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  
  return (
    <LetsMeetLayout
      deleteBtn={false}
      widget={'option'}
      step={endPollEvent ? "create" : "edit"}
      footerText={''}
      actionOnHeaderRight={''}
    >
      {loading && <OverlayLoader />}
      <main className="flex-grow mb-4" onClick={handleContactVisibility}>
        <div className="w-full my-8 mb-6">
          <div className="flex flex-col items-center w-full gap-2">
            <form
              onSubmit={handleSubmit}
              className="w-full grid grid-cols-1 gap-4"
            >
              <div>
                <TextAreaField
                  label="What do you want to do?"
                  placeholder="Name activity"
                  required={true}
                  name="activity"
                  value={formValues.activity}
                  onChange={handleInputChangeTextArea}
                  error={errors.activity}
                />
              </div>

              <div>
                <Text
                  size="small"
                  className="w-full text-base font-sofia-normal pb-2"
                >
                  Select an icon for your activity
                </Text>
                <div className="overflow-auto">
                  <div className=" flex gap-2.5 overflow-x-auto overflow-y-visible py-0.5" ref={containerRef}>
                    {icon ? (
                      <>
                        <div
                          className="flex items-center gap-2.5 mr-1 shrink-0"
                          onClick={() => handleConfirmationModal(true)}
                        >
                          <EventIcon icon={icon} />
                          <div className="text-sm font-sofia-light opacity-50 shrink-0">
                            Change Icon
                          </div>
                        </div>
                        {['🍽️', '🍻', '🏠', '❤️'].map((i) => (
                          <div key={i} onClick={() => handleIconSelect(i)}>
                            <EventIcon icon={i} />
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        <div
                          className=" rounded-full bg-[#32395E] flex justify-center py-7 w-20 shrink-0"
                          onClick={() => handleConfirmationModal(true)}
                        >
                          <PlusIcon />
                        </div>
                        {['🍽️', '🍻', '🏠', '❤️'].map((i) => (
                          <div key={i} onClick={() => handleIconSelect(i)}>
                            <EventIcon icon={i} />
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <CitySuggestion
                  location={location}
                  error={errors.location}
                  locationMenuVisible={locationMenuVisible}
                  setSelectedLocation={setLocation}
                />
              </div>

              <div>
                {selectedContacts && selectedContacts.length > 0 && (
                  <>
                    <div className="flex justify-between items-center">
                      <div>
                        <Text
                          size="small"
                          className="w-full text-base font-sofia-normal"
                        >
                          With who?
                        </Text>
                      </div>
                      <div className="text-right">
                        {!publicEvent && (
                          <div className="flex items-center gap-1 px-2.5 py-1.5 flex-row text-violet-700 bg-white rounded-xl">
                            <UserAvatar height={12} width={12} />
                            <Text
                              size="small"
                              className="text-violet-700 text-xs leading-none"
                            >
                              {selectedContacts.length}
                            </Text>
                          </div>
                        )}
                      </div>
                    </div>
                    {!publicEvent && (
                      <div className="grid grid-cols-2">
                        {selectedContacts.map((contact, index) => (
                          <div
                            key={index}
                            className="grid grid-cols-[auto_24px] items-center justify-between rounded-full bg-[#1F2339] text-white text-sm px-3 py-1.5  m-1"
                          >
                            <div className="text-sm whitespace-nowrap overflow-hidden text-ellipsis">
                              {contact.name}
                            </div>
                            <div
                              onClick={() => removeContact(contact.phoneNumber)}
                              className={'ml-2.5 flex-shrink-0'}
                            >
                              <X size={14} />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}
                <TextField
                  disabled={publicEvent}
                  id={'withWho'}
                  label={
                    selectedContacts && selectedContacts.length == 0
                      ? 'With who?'
                      : ''
                  }
                  placeholder="Select contacts"
                  value={searchValue}
                  onChange={(elem) => {
                    handleSearchChange(elem.target.value);
                  }}
                />
                <ContactsList
                  show={isContactVisible}
                  userContacts={userContacts}
                  currUserId={user?.id ?? '-'}
                  searchValue={searchValue}
                  onContactSelect={handleContactClick}
                  onSearchChange={handleSearchChange}
                  selectedContacts={selectedContacts}
                />

                <div className="inline-flex items-center w-full ml-0.5">
                  <Checkbox
                    checked={publicEvent}
                    className="bg-purple-300 mr-2"
                    onCheckedChange={handleTickOutCaleido}
                  />
                  <Text
                    size="extra-small"
                    className="text-xs font-sofia-extralight"
                  >
                    My friends aren&apos;t on Caleido
                  </Text>
                </div>
              </div>

              <div>
                <TextAreaField
                  label="Any other details?"
                  placeholder="Type here"
                  required={false}
                  name={'details'}
                  value={formValues.details}
                  onChange={handleInputChangeTextArea}
                  error={errors.details}
                />
              </div>

              <div>
                <Text
                  size="small"
                  className="w-full text-base font-sofia-normal flex justify-start space-x-2 pb-2"
                >
                  <span>When?</span>
                  <span>*</span>
                </Text>
                <div className="grid grid-cols-3 w-full gap-2 mb-4">
                  <div
                    onClick={() => onChangeType('now')}
                    className={`rounded-full py-2 flex flex-col justify-center items-center ${type === 'now' ? `bg-purple-300` : `border border-indigo-600`}`}
                  >
                    {type === 'now' ? <NowActiveIcon /> : <NowIcon />}
                    <span
                      className={`font-bold text-xs mt-1 ${type === 'now' && `text-indigo-900`}`}
                    >
                      Now
                    </span>
                  </div>
                  <div
                    onClick={() => onChangeType('later')}
                    className={`rounded-full py-2 flex flex-col justify-center items-center ${type === 'later' ? `bg-purple-300` : `border border-indigo-600`}`}
                  >
                    {type === 'later' ? <LaterActiveIcon /> : <LaterIcon />}
                    <span
                      className={`font-bold text-xs mt-1 ${type === 'later' && `text-indigo-900`}`}
                    >
                      Later
                    </span>
                  </div>
                  <div
                    onClick={() => onChangeType('poll')}
                    className={`rounded-full py-2 flex flex-col justify-center items-center ${type === 'poll' ? `bg-purple-300` : `border border-indigo-600`}`}
                  >
                    {type === 'poll' ? <PollActiveIcon /> : <PollIcon />}
                    <span
                      className={`font-bold text-xs mt-1 ${type === 'poll' && `text-indigo-900`}`}
                    >
                      Poll
                    </span>
                  </div>
                </div>
                {type && <hr className={'opacity-20 my-8'} />}
                {type === 'now' ? (
                  <div>
                    <h1 className={'text-xl font-sofia-medium mb-4'}>
                      Meet Now
                    </h1>
                    <div>
                      <Text
                        size="small"
                        className="w-full text-base font-sofia-normal flex justify-between mb-2"
                      >
                        <span>
                          {isMeetNowChecked
                            ? `How long do you plan to hang out?`
                            : `Select the end time`}
                        </span>
                        <div>
                          <ToggleSwitch
                            isChecked={isMeetNowChecked}
                            onChange={onNowSwitchChange}
                          />
                        </div>
                      </Text>
                      {!isMeetNowChecked ? (
                        <AvailabilityTimeSelector
                          onTimeSelect={handleNowEndTimeChange}
                          selectedTime={
                            nowEndTime
                              ? dayjs(nowEndTime).format('hh:mm A')
                              : undefined
                          }
                          className="m-0 max-w-full [&>div]:justify-start [&_.lucide]:hidden [&_button]:w-full"
                          popupDirection="up"
                          useDefaultTime
                        />
                      ) : (
                        <CustomSelect
                          selectedValue={selectedNowEndValue ?? '120'}
                          options={generateTimeOptions()}
                          onValueChange={(value) =>
                            setSelectedNowEndValue(value)
                          }
                          direction="up"
                        />
                      )}
                    </div>
                  </div>
                ) : type === 'later' ? (
                  <div>
                    <h1 className={'text-xl font-sofia-medium mb-4'}>
                      Meet later
                    </h1>

                    <div>
                      <Text
                        size="small"
                        className="w-full text-base font-sofia-normal flex justify-between mb-2"
                      >
                        <span>Select the start date</span>
                      </Text>
                      <Popover>
                        <PopoverTrigger asChild>
                          <button
                            className={
                              'bg-[#32395E]  text-left py-3 px-5 text-sm w-full rounded-full'
                            }
                            style={{
                              border: '1px solid rgba(71, 85, 105, 1)',
                            }}
                          >
                            {laterDate ? (
                              format(laterDate, 'PPP')
                            ) : (
                              <span className={'text-slate-400'}>
                                DD/MM/YYYY
                              </span>
                            )}
                          </button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0 border-none">
                          <Calendar
                            fromDate={new Date()}
                            className={'bg-slate-800 text-white'}
                            mode="single"
                            selected={laterDate}
                            onSelect={setLaterDate}
                            initialFocus
                          />
                        </PopoverContent>
                      </Popover>
                      <Text
                        size="small"
                        className="w-full text-base font-sofia-normal flex justify-between mb-2 mt-4"
                      >
                        <span>Select the start time</span>
                      </Text>

                      <AvailabilityTimeSelector
                        onTimeSelect={handleLaterStartTimeChange}
                        selectedTime={
                          laterStartTime
                            ? dayjs(laterStartTime).format('hh:mm A')
                            : undefined
                        }
                        className="m-0 max-w-full [&>div]:justify-start [&_.lucide]:hidden [&_button]:w-full"
                        popupDirection="down"
                        useDefaultTime
                      />

                      <Text
                        size="small"
                        className="w-full text-base font-sofia-normal flex justify-between mb-2 mt-4"
                      >
                        <span>Select the end time</span>
                      </Text>

                      <AvailabilityTimeSelector
                        onTimeSelect={handleLaterEndTimeChange}
                        selectedTime={
                          laterEndTime
                            ? dayjs(laterEndTime).format('hh:mm A')
                            : undefined
                        }
                        className="m-0 max-w-full [&>div]:justify-start [&_.lucide]:hidden [&_button]:w-full"
                        popupDirection="up"
                        // disabled={!endEnable}
                        useDefaultTime
                      />

                      {laterEventEndInNextDay && (
                        <Text
                          size="small"
                          className="font-sans text-xs font-sofia-light mt-1.5 opacity-75"
                        >
                          Event will end on the next day
                        </Text>
                      )}
                    </div>
                  </div>
                ) : type === 'poll' ? (
                      <div>
                        <h1 className={'text-xl font-sofia-medium mb-4'}>
                          Edit a poll
                        </h1>
                        <div>
                          <Text
                            size="small"
                            className="w-full text-base font-sofia-normal flex justify-between mb-2"
                          >
                            <span>Edit Checked Times</span>
                          </Text>
                          <ThemeProvider theme={darkTheme}>
                            <DatePickerWithRange
                              className="w-full rounded-full border-slate-600 outline-none "
                              date={dateRange}
                              setDate={setDateRange}
                            />
                          </ThemeProvider>

                          <Text
                            size="small"
                            className="w-full text-base font-sofia-normal flex justify-between mb-2 mt-4"
                          >
                            <span>How long do you plan to hang out</span>
                          </Text>
                          <CustomSelect
                            selectedValue={selectedDuration?.toString() ?? '0'}
                            options={generateTimeOptions()}
                            onValueChange={(value) => {
                              setSelectedDuration(Number(value));
                            }}
                            placeHolder="Select duration"
                          />
                          <Text
                            size="small"
                            className="w-full text-base font-sofia-normal flex justify-between mb-2 mt-4"
                          >
                            <span>
                              Choose from your available times (At least 2)
                            </span>
                          </Text>
                          <ThemeProvider theme={darkTheme}>
                            <div className="max-h-96 overflow-y-auto flex flex-col gap-2.5 relative">
                              {loading && <OverlayLoader />}
                              {(!dateRange || !selectedDuration) &&
                                manualAvailabilitySlots.length === 0 ? (
                                <Text
                                  size="small"
                                  className="text-gray-500 text-center"
                                >
                                  No availabilities to show. Please select a date
                                  and duration.
                                </Text>
                              ) : showableAvailabilities.length === 0 ||
                                showableAvailabilities.every(
                                  (avail) => avail.times.length === 0,
                                ) ? (
                                <Text
                                  size="small"
                                  className="text-gray-500 text-center"
                                >
                                  No availabilities
                                </Text>
                              ) : (
                                showableAvailabilities?.map(
                                  (day: AvailabilityResponse) =>
                                    day.times.map(
                                      (time: AvailabilitySlot, index: number) => (
                                        <div
                                          key={`${day.date}-${time.start}-${index}`}
                                          className="text-sm flex justify-between w-full max-w-sm mx-auto font-sans text-gray-300 bg-[#32395E] focus:outline-none px-4 py-2.5 rounded items-center"
                                          onClick={() => {
                                            if (day?.manual) {
                                              handleCheckboxChange(
                                                `${day.date}-${time.start}-${time.end}`,
                                              )
                                            } else {
                                              handleCheckboxChangeAvailability(time.start, time.end)
                                            }
                                          }}
                                        >
                                          {day?.manual ?
                                            <div className="flex items-center">
                                              <div
                                                className='h-4 w-4 shrink-0 mr-2 rounded-sm border border-primary ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground'
                                              >
                                                <div className='flex items-center justify-center text-current bg-purple-300 w-full h-full rounded-sm '>
                                                  {checkedTimes.includes(
                                                    `${day.date}-${time.start}-${time.end}`,
                                                  ) ?
                                                    <div className="absolute bg-theme h-2 w-2" /> : null
                                                  }
                                                </div>
                                              </div>
                                              <span className="pr-4 pl-1 font-sofia-light">
                                                {formatDate(day.date)} •{' '}
                                                {formatTime(day.date, time.start)} -{' '}
                                                {formatTime(day.date, time.end)}
                                              </span>
                                            </div> :
                                            <div className="flex items-center">
                                              <div
                                                className='h-4 w-4 shrink-0 mr-2 rounded-sm border border-primary ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground'
                                              >
                                                <div className='flex items-center justify-center text-current bg-purple-300 w-full h-full rounded-sm '>
                                                  {checkedTimes.includes(formatUtcDateTime(time.start, time.end)) ?
                                                    <div className="absolute bg-theme h-2 w-2" /> : null
                                                  }
                                                </div>
                                              </div>
                                              <span className="pr-4 pl-1 font-sofia-light">
                                                {formatDateUtc(time.start)} •{' '}
                                                {formatTimeUtc(time.start)} -{' '}
                                                {formatTimeUtc(time.end)}
                                              </span>
                                            </div>
                                          }

                                          <button
                                            onClick={(e) => {
                                              e.stopPropagation()
                                              e.preventDefault();
                                              if (day?.manual) {
                                                handleDeleteManualAvailability(day.date, index)
                                                handleCheckboxChange(
                                                  `${day.date}-${time.start}-${time.end}`,
                                                )
                                              } else {
                                                handleDeleteAvailability(index);
                                                handleCheckboxChangeAvailability(time.start, time.end)
                                              }
                                            }}
                                            className="text-red-500"
                                          >
                                            <DeleteIcon />
                                          </button>
                                        </div>
                                      ),
                                    ),
                                )
                              )}
                            </div>
                          </ThemeProvider>

                          {loading && <OverlayLoader />}

                          <Text
                            size="small"
                            className={cn(
                              'mt-4 underline cursor-pointer decoration-solid',
                            )}
                            onClick={handleOpenModal}
                          >
                            + Add a different time
                          </Text>
                          <Drawer
                            setVisible={setVisibleOption}
                            visible={visibleOption}
                            className="flex flex-col w-full p-4 overflow-y-auto after:content-none"
                          >
                            <Text
                              size="medium"
                              className="px-4 my-3 font-sofia-medium text-center"
                            >
                              Add the time(s) you are available
                            </Text>
                            <Text
                              size="small"
                              className="w-full text-base font-sofia-normal flex justify-between mb-2"
                            >
                              <span>Select the date</span>
                            </Text>
                            <Popover>
                              <PopoverTrigger asChild>
                                <button
                                  className={
                                    '!bg-[#32395E] text-white text-sm font-sofia-light  text-left py-3 px-5  w-full rounded-full'
                                  }
                                >
                                  {selectedDate ? (
                                    format(selectedDate, 'PPP')
                                  ) : (
                                    <span className={'text-slate-400'}>
                                      DD/MM/YYYY
                                    </span>
                                  )}
                                </button>
                              </PopoverTrigger>
                              <PopoverContent className="w-auto p-0 border-none">
                                <Calendar
                                  fromDate={new Date()}
                                  className={'bg-slate-800 text-white'}
                                  mode="single"
                                  selected={selectedDate}
                                  onSelect={setSelectedDate}
                                  initialFocus
                                />
                              </PopoverContent>
                            </Popover>
                            <Text
                              size="small"
                              className="w-full text-base font-sofia-normal flex justify-between mb-2 mt-4"
                            >
                              <span>Select the time</span>
                            </Text>
                            <div className="mt-4">
                              <RecurringAvailabilitySlot
                                slots={recurringTimes}
                                onChange={setRecurringTimes}
                                allEventData={allEventData}
                                selectedDate={selectedDate}
                                showSelectedInitially={false}
                              />
                            </div>
                            <div className="mt-5">
                              <MainButton type="primary" onClick={handleSave}>
                                <Text
                                  size="small"
                                  className="text-base font-sofia-normal"
                                >
                                  Save
                                </Text>
                              </MainButton>
                            </div>
                          </Drawer>
                        </div>
                      </div>
                ) : (
                  <></>
                )}
              </div>

              <button type="submit" className="hidden">
                Submit
              </button>

              {/* Icon drawer */}
              <Drawer
                setVisible={setVisible}
                visible={visible}
                className="flex flex-col w-full py-4"
              >
                <IconsList activeIcon={icon} fetchIcon={setIcon} />
              </Drawer>
            </form>
          </div>
        </div>
      </main>
      <footer className="mb-4 pb-8">
        <MainButton
          type="primary"
          onClick={handleSubmit}
          icon={isLoading?<><InLineLoader /></>:<ChevronRight color="#fff" />}
          disabled={isLoading}
        >
          <Text size="small" className="text-base font-sofia-normal">
            Go to Review
          </Text>
        </MainButton>
      </footer>
    </LetsMeetLayout>
  );
});

EditEvent.displayName = 'EditEvent';

export default EditEvent;

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { EventType } from 'modules/letsMeet/types/types';
import { useAppSelector } from 'hooks/useAppSelector';
import { RootState } from 'app/store';
import { IContact, IEvent } from 'types/event';
import { format } from 'date-fns';
import { ChevronRight } from 'lucide-react';
import axios from 'axios';
import { toast } from 'components/ui/toast/use-toast';
import { getFormattedSharingText } from 'utils/helpers';
import { NotificationType } from 'modules/letsMeet/utils/enum';

import ModernActivityHeader from 'modules/activity/components/modernHeader';
import EventOrganizerHeader from 'modules/letsMeet/components/receive/eventOrganizerHeader';
import EventCard from 'modules/letsMeet/components/receive/eventCard';
import HorizontalInviteList from 'modules/letsMeet/components/inviteList/HorizontalInviteList';
import CommentList from 'modules/letsMeet/components/comments/commentList';
import PollResults from 'modules/letsMeet/components/meetResponse/pollResults';
import OverlayLoader from 'components/loader/OverlayLoader';
import { Text } from 'components/text/primary/Text';
import MainButton from 'modules/auth/components/MainButton';
import Drawer from 'modules/settings/components/contactActions/Drawer/Drawer';
import ActionButton from 'components/buttons/action/ActionButton';

import {
  useAddEventCommentMutation,
  useUpdateEventMutation,
  useShareEventMutation,
  useDeleteEventMutation
} from 'services/event.service';

import { useSendNotificationMutation, useStopNotificationMutation } from 'services/notification.service';
import { useGetAllEventActivitiesQuery, useUpdateActivityMutation } from 'services/activity.service';
import ReceiveLayout from 'modules/letsMeet/layouts/receiveLayout';
import { getUserPhotosForEvent } from 'modules/letsMeet/utils/user';

const ViewEvent = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAppSelector((state: RootState) => state.auth.user);

  const [isLoading, setIsLoading] = useState(true);
  const [event, setEvent] = useState<IEvent | null>(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<number | null>(null);
  const [visible, setVisible] = useState(false);
  const [myEvent, setMyEvent] = useState<boolean>(false);
  const [pastEvent, setPastEvent] = useState<boolean>(false);
  const [convertingPoll, setConvertingPoll] = useState(false);

  const [shareEvent] = useShareEventMutation();
  const [updateEvent] = useUpdateEventMutation();
  const [deleteEvent] = useDeleteEventMutation();
  
  const [updateActivity] = useUpdateActivityMutation();
  const [sendNotification] = useSendNotificationMutation();
  const [stopNotification] = useStopNotificationMutation();

  const { data: eventActivities } = useGetAllEventActivitiesQuery(
    event?._id ?? '', 
    { skip: !event?._id }
  );

  const [userPhotos, setUserPhotos] = React.useState<{[key: string]: { photoURL: string, name: string }}>({});
  const [organizerPhoto, setOrganizerPhoto] = React.useState<string>('');
  useEffect(() => {
  if (event?._id) {
    getUserPhotosForEvent(event?._id).then(photos => {
      setUserPhotos(photos);
      const organizerNumber = event?.contacts?.find((contact : IContact) => contact.organizer === 1)?.phoneNumber;
      if (!organizerNumber) return;
      setOrganizerPhoto(photos[organizerNumber]?.photoURL ?? '');
    });
  }
  }, [event?._id]);


  // Check if logged in user is viewing the event
  const pathArray = location.pathname.split('/');
  const isLoggedInUserView = pathArray.length > 2 && pathArray[2] === 'view';
  const isHost = event?.userId === user?.id;

  useEffect(() => {
    const endDate = new Date(event?.end ?? 0);
    const currentDate = new Date();

    console.log(endDate);
    console.log(currentDate);
    if (endDate < currentDate && event?.type !== 'poll') {
      setPastEvent(true);
    } else {
      setPastEvent(false);
    }

    if (event?.userId === user?.id) {
      setMyEvent(true);
    } else {
      setMyEvent(false);
    }
  }, [event, user]);

  const fetchEvent = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/event/share/${id}`);
      setEvent(response.data);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (id) {
      fetchEvent();
    } else {
      navigate('/lets-meet');
    }
  }, [id]);

  const handleShare = async (link: string) => {
    if (!navigator.share) {
      await navigator?.clipboard?.writeText(link);
      toast({
        title: 'Link copied to clipboard',
      });
    } else {
      await navigator.share({
        title: 'Share this event',
        text: getFormattedSharingText(user!, event!, link),
        url: link,
      });
    }
  };

  const convertPollToEvent = async () => {
    if (!event?.pollData?.checkedTimes || selectedTimeSlot === null) return;
    
    setConvertingPoll(true);
    try {
      const timeString = event.pollData.checkedTimes[selectedTimeSlot].time;
      // Format: 2024-12-24-21:00Z-00:00Z
      const match = timeString.match(/^(\d{4}-\d{2}-\d{2})-(\d{2}:\d{2})Z-(\d{2}:\d{2})Z$/);
      
      if (!match) {
        throw new Error('Invalid time format');
      }

      const [_, datePart, startTime, endTime] = match;
      
      const startDateTime = new Date(`${datePart}T${startTime}Z`);
      // If end time is less than start time, it means it's the next day
      let endDateTime = new Date(`${datePart}T${endTime}Z`);
      if (endDateTime < startDateTime) {
        endDateTime = new Date(endDateTime.setDate(endDateTime.getDate() + 1));
      }

      // Get voters for the selected time slot
      const selectedTimeVoters = event.pollData.checkedTimes[selectedTimeSlot].voters;
      
      // Update contacts based on poll votes
      const updatedContacts = event.contacts?.map(contact => {
        // Check if this contact voted for the selected time
        const didVoteForSelectedTime = selectedTimeVoters.some(voter => 
          (voter.userId && contact.userId === voter.userId) || 
          (voter.name && contact.name === voter.name && !contact.phoneNumber)
        );

        // If they voted for this time, they're going (status 1)
        // If they didn't vote for this time but voted for other times, they declined (status 2)
        // Otherwise, keep their current status
        if (didVoteForSelectedTime) {
          return { ...contact, status: 1 };
        } else {
          const votedForOtherTime = event.pollData?.checkedTimes.some(timeSlot =>
            timeSlot.voters.some(voter => 
              (voter.userId && contact.userId === voter.userId) ||
              (voter.name && contact.name === voter.name && !contact.phoneNumber)
            )
          );
          return votedForOtherTime ? { ...contact, status: 2 } : contact;
        }
      }) || [];

      // Create updated event data
      const updatedEventData = {
        ...event,
        type: 'later',
        start: startDateTime,
        end: endDateTime,
        contacts: updatedContacts,
        pollData: undefined, // Remove poll data
      };

      // Update the event
      await updateEvent({
        id: event._id,
        ...updatedEventData
      }).unwrap();

      toast({
        title: 'Poll successfully converted to event',
      });

      // Refresh the event data
      await fetchEvent();
    } catch (error) {
      console.error('Error converting poll to event:', error);
      toast({
        title: 'Failed to convert poll to event',
        variant: 'destructive'
      });
    } finally {
      setConvertingPoll(false);
    }
  };

  const handleSubmit = async () => {
    if (event?.type === EventType.POLL) {
      if (selectedTimeSlot == null) return;
      await convertPollToEvent();
      return;
    }

    // Share event
    await handleShare(`${process.env.REACT_APP_CLIENT_URL}/share/${event?._id}`);

    if (event?.publicEvent === false) {
      await shareEvent({
        fromName: user?.name.split(' ')[0] ?? '',
        eventName: event?.activity,
        endTime: event?.type === 'now' ? 
          (event?.end ? format(new Date(event.end), 'hh:mm a') : '') : 
          null,
        eventLink: `${process.env.REACT_APP_CLIENT_URL}/share/${event?._id}`,
        to: event?.contacts?.map((contact) => contact.phoneNumber) ?? [],
        messageType: event?.type === 'now' ? 
          'MEET_NOW_NOTIFICATION' : 
          event?.type === 'later' ? 
            'MEET_LATER_NOTIFICATION' : 
            'MEET_POLL_NOTIFICATION'
      });
    }
  };

     const shareLink = async () => {
      const link = `${process.env.REACT_APP_CLIENT_URL}/share/${event?._id}`;
      if (!navigator.share) {
        await navigator?.clipboard?.writeText(link);
        toast({
          title: 'Link copied to clipboard',
        });
      } else {
        await navigator.share({
          title: 'Share this event',
          text: getFormattedSharingText(user!, event!, link),
          url: link,
        });
      }
    };

  const handleEditEvent = () => {
    if (event?._id) {
      navigate(`/lets-meet/edit/${event._id}`);
    }
  };

  const handleDeleteEvent = async () => {
    if (event?._id) {
      await deleteEvent(event._id);
      navigate('/home');
    }
    setVisible(false);
  };

  if (isLoading || (isLoggedInUserView && !user)) {
    return <OverlayLoader />;
  }

  const attendingInvitees = event?.contacts ?? [];

  return (
    <ReceiveLayout>
       {convertingPoll && <OverlayLoader />}
      <div className="relative min-h-screen pb-[200px]">
        <div className="w-full sticky top-0 z-10">
          <ModernActivityHeader 
            isHost={isHost}
            event={event}
            originPage="lets-meet"
            onEditEvent={handleEditEvent}
            onDeleteEvent={() => setVisible(true)}
          />
        </div>

        <div className="px-8">
          <EventOrganizerHeader 
            organizerPhoto={organizerPhoto}
            organizerName={event?.organizer ?? ''}
            sentTime={format(new Date(event?.start ?? new Date()), 'EEE, MMM d')}
            isHost = {isHost}
            isPast={pastEvent}
          />

          <div className="my-6">
            <EventCard
              title={event?.activity ?? ''}
              emoji={event?.emoji ?? ''}
              guests={event?.contacts?.filter((contact) => contact.status === 1).length ?? 0}
              location={event?.location ?? ''}
              startTime={format(new Date(event?.start ?? new Date()), 'h:mma')}
              dateOfEvent={event?.start?.toString() ?? ''}
              eventType={event?.type}
            />
          </div>

          {event?.type === EventType.POLL && (
            <div className="mt-3 mb-4" >
              <PollResults
                pollData={event?.pollData}
                totalUsers={Math.max(attendingInvitees.length, 1)}
                showCreatorView
                onSelectTimeSlot={setSelectedTimeSlot}
                eventId= {event?._id}
              />
            </div>
          )}

          <HorizontalInviteList 
            title="Attendee List"
            // list={attendingInvitees.map(contact => contact.name)}
            eventId={event?._id}
            contacts={event?.contacts ?? []}
            isOrganizer={isHost}
            userPhotos={userPhotos}
            onAddClick={() => navigate(`/lets-meet/edit/${event?._id}`)}
            disabled={pastEvent}
            shareEvent={shareLink}
          />

          <CommentList
            event={{ event }}
            disabled={pastEvent}
            activities={eventActivities}
            userPhotos={userPhotos}
          />
        </div>

        <div className="fixed bottom-0 left-0 w-full z-40">
          <div className="flex justify-center p-4">
            {event?.type === EventType.POLL ? (
              <button
              onClick={handleSubmit}
              disabled={pastEvent || selectedTimeSlot === null}
              className="flex h-[60px] w-[384px] items-center justify-center gap-[10px] rounded-[100px] bg-[#6000E9] shadow-[0px_4px_10px_0px_rgba(0,0,0,0.06)] disabled:opacity-50 disabled:cursor-not-allowed"
            >
                <span className="text-white font-manrope text-base font-semibold">Confirm time</span>
              </button>
            ) : (
              <button
                onClick={handleSubmit}
                disabled={pastEvent}
                className="flex h-[60px] w-[190px] items-center justify-center gap-[10px] rounded-[100px] bg-[#6000E9] shadow-[0px_4px_10px_0px_rgba(0,0,0,0.06)] disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <span className="text-white font-manrope text-base font-semibold">Share hangout</span>
              </button>
            )}
          </div>
        </div>
      </div>

      <Drawer
        visible={visible}
        setVisible={setVisible}
        className="flex flex-col w-full p-4"
      >
        <Text size="small" className="px-4 mt-2 mb-2 font-semibold text-center text-md">
          Are you sure you want to delete?
        </Text>
        <Text size="medium" className="mb-6 font-light text-center text-sm">
          You cannot undo this action after you confirm.
        </Text>
        <div className="flex items-center w-full gap-4 px-2 mt-4 mb-6">
          <ActionButton
            variant="secondary"
            label="Cancel"
            className="text-sm"
            onClick={() => setVisible(false)}
          />
          <ActionButton
            label="Yes, Delete"
            className="text-sm"
            onClick={handleDeleteEvent}
          />
        </div>
      </Drawer>
    </ReceiveLayout>
  );
};

export default ViewEvent;
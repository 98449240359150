import { useState } from 'react';
import { ReactComponent as MenuIcon } from 'assets/images/common/menu-v2.svg';
import SideBar from './sidebar';

const SideBarButton: React.FC = () => {
  const [menuVisible, setMenuVisible] = useState(false);

  return (
    <div
      className="flex justify-end"
      onClick={() => {
        setMenuVisible(true);
      }}
    >
      <MenuIcon />
      <SideBar onClose={() => setMenuVisible(false)} open={menuVisible} />
    </div>
  );
};

export default SideBarButton;

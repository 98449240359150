import { get, isArray, isEmpty, isObject } from 'lodash';
import { IUser } from 'modules/auth/types/types';
import { Contact } from 'modules/profile/types/types';
import {
  CountryCode,
  parsePhoneNumber,
  parsePhoneNumberFromString,
} from 'libphonenumber-js';

type FieldIndexTuple = [string, number];

const getNextFormIndex = (
  user: IUser,
  fieldIndexTuples: FieldIndexTuple[],
): number => {
  for (const [] of fieldIndexTuples) {
    if (!isEmpty(user?.contacts)) {
      return 4;
    }
    if (!isEmpty(user?.availability)) {
      return 2;
    }
    if (!isEmpty(user?.name)) {
      return 1;
    }
  }
  return 0;
};

/**
 * Transforms a VCF (vCard) object into a Contact object.
 *
 * @param {Object} input - The input VCF object.
 * @return {Contact} The transformed Contact object.
 *
 * @example
 * const input = {
 *   fn: { _data: 'John Doe' },
 *   tel: [{ _data: '123-456-7890' }],
 *   email: { _data: 'john.doe@example.com' },
 *   photo: { _data: 'http://example.com/photo.jpg' },
 * };
 *
 * const contact = transformVCFToContact(input);
 * console.log(contact);
 * // Output: { name: 'John Doe', phoneNumber: '123-456-7890', email: 'john.doe@example.com', photoURL: 'http://example.com/photo.jpg' }
 */
const transformVCFToContact = (input: any): Contact | null => {
  const getFieldValue = (field: any, isPhoto: boolean = false) => {
    let value = '';
    if (isArray(field)) {
      value = get(field, '[0]._data', '');
    } else if (isObject(field)) {
      value = get(field, '_data', '');
    }
    if(get(field, 'encoding') === "QUOTED-PRINTABLE" || get(field, '[0].encoding') === "QUOTED-PRINTABLE"){
      value = value.replace(/=([A-Fa-f0-9]{2})/g, (match, hex) => {
        return String.fromCharCode(parseInt(hex, 16));
      });
    }

    return isPhoto && value ? `base64,${value}` : value;
  };
  let name = getFieldValue(get(input, 'fn')) || getFieldValue(get(input, 'n'));

  const phoneNumber = getFieldValue(get(input, 'tel'));

  // Return immediately if required fields are empty
  if (isEmpty(name) || isEmpty(phoneNumber)) {
    console.count('Name or phone number is empty');
    return null;
  }

  const nameField = name.replace(/\\;/g, '__ESCAPED_SEMICOLON__');

  const nameParts = nameField.split(';');

  const [lastName, firstName, middleName, prefix, suffix] = nameParts.map(
    (part) => part.replace(/__ESCAPED_SEMICOLON__/g, ';'),
  );

  name =
    `${prefix || ''} ${firstName || ''} ${middleName || ''} ${lastName || ''} ${suffix || ''}`.trim();

  const validated = validatePhoneNumber(phoneNumber);

  if (!validated) {
    console.log(`Invalid phone number ${phoneNumber}`);
    return null;
  }
  if(!name){
    console.log(`Invalid name`);
    return null;
  }

  // validate phone number

  const contact: Contact = {
    name,
    phoneNumber: validated,
  };
  // if (!isEmpty(email)) {
  //   contact.email = email;
  // }
  // if (!isEmpty(photoURL)) {
  //   contact.photoURL = photoURL;
  // }
  return contact;
};

const validatePhoneNumber = (phoneNumber: string): string | false => {
  let cleanedNumber = phoneNumber.replace(/[^\d+]/g, '');

  // Add country code if missing for US and Sri Lankan formats
  if (!cleanedNumber.startsWith('+')) {
    if (cleanedNumber.length === 10 && cleanedNumber[0] === '0') {
      cleanedNumber = `+94${cleanedNumber.slice(1)}`; // Sri Lankan (10 digits)
    } else if (cleanedNumber.length === 9 && /^[7-9]\d{8}$/.test(cleanedNumber)) {
      cleanedNumber = `+94${cleanedNumber}`; // Sri Lankan (9 digits)
    } else if (cleanedNumber.length === 10 && /^[2-9]\d{9}$/.test(cleanedNumber)) {
      cleanedNumber = `+1${cleanedNumber}`; // US (10 digits)
    } else if (cleanedNumber.length === 11 && cleanedNumber.startsWith('1')) {
      cleanedNumber = `+${cleanedNumber}`; // US (11 digits, starts with 1)
    } else {
      return false; // Invalid if none of these patterns match
    }
  }

  const parsedNumber = parsePhoneNumberFromString(cleanedNumber);
  return parsedNumber && parsedNumber.isValid() ? parsedNumber.number : false;
};

function getUniqueNewContacts(
  oldContacts: Contact[],
  newContacts: Contact[],
  validate = true,
): Contact[] {
  const oldPhonesSet = new Set(
    oldContacts.map((contact) => contact.phoneNumber),
  );

  const uniqueNewContactsMap = new Map<string, Contact>();
  newContacts.forEach((contact) => {
    if (
      !uniqueNewContactsMap.has(contact.phoneNumber) &&
      !oldPhonesSet.has(contact.phoneNumber)
    ) {
      uniqueNewContactsMap.set(contact.phoneNumber, contact);
    }
  });

  return Array.from(uniqueNewContactsMap.values());
}

const mergeContacts = (
  oldContacts: Contact[],
  newContacts: Contact[],
): Contact[] => {
  const oldPhonesSet = new Set(
    oldContacts.map((contact) => contact.phoneNumber),
  );

  const uniqueNewContactsMap = new Map<string, Contact>();

  oldContacts.forEach((contact) => {
    uniqueNewContactsMap.set(contact.phoneNumber, contact);
  });

  newContacts.forEach((contact) => {
    uniqueNewContactsMap.set(contact.phoneNumber, contact);
  });

  return Array.from(uniqueNewContactsMap.values());
};

export {
  getNextFormIndex,
  transformVCFToContact,
  validatePhoneNumber,
  getUniqueNewContacts,
  mergeContacts,
};

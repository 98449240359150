import React from 'react';
import { ReactComponent as MapPin } from 'assets/images/common/map-pin.svg';
import { ReactComponent as Users } from 'assets/images/common/users.svg';
import { ReactComponent as TimeIcon} from 'assets/images/common/time.svg';
import { format, isToday } from 'date-fns';

interface EventCardProps {
  title: string;
  emoji: string;
  guests: number;
  location: string;
  startTime: string;
  dateOfEvent: string;
  hasResponded?: boolean;
  eventType?: string;   
}

// North American timezone mappings
const NA_TIMEZONES = {
  'America/New_York': 'EST',
  'America/Chicago': 'CST',
  'America/Denver': 'MT',
  'America/Phoenix': 'MST',
  'America/Los_Angeles': 'PST',
  'America/Anchorage': 'AKST',
  'America/Adak': 'HST',
};

export const EventCard: React.FC<EventCardProps> = ({
  title,
  emoji,
  guests,
  location,
  startTime,
  dateOfEvent,
  hasResponded,
  eventType,
}) => {
  // Function to get timezone abbreviation
  const getTimezoneAbbr = (): string => {
    // eslint-disable-next-line
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return NA_TIMEZONES[timeZone as keyof typeof NA_TIMEZONES] || '';
  };

  // Format time with timezone
  const formatTimeWithZone = (time: string): string => {
    if (!time) return '';
    
    // Handle different time formats (assuming 24h or 12h format input)
    const timeRegex = /^(\d{1,2}):(\d{2})\s*(AM|PM)?$/i;
    const match = time.match(timeRegex);
    
    if (!match) return time;

    const [_, hours, minutes, meridiem] = match;
    const tzAbbr = getTimezoneAbbr();
    
    // If meridiem (AM/PM) exists, keep the same format
    if (meridiem) {
      return `${hours}:${minutes} ${meridiem} ${tzAbbr}`;
    }
    
    // Convert 24h format to 12h format with AM/PM
    const hour = parseInt(hours, 10);
    const isPM = hour >= 12;
    const displayHour = hour % 12 || 12;
    return `${displayHour}:${minutes} ${isPM ? 'PM' : 'AM'} ${tzAbbr}`;
  };

  const formatDate = () => {
    try {
      const date = new Date(dateOfEvent);
      if (isToday(date)) {
        return 'today';
      }
      return "on " + format(date, 'MM/dd');
    } catch {
      return "on " + dateOfEvent;
    }
  };

  const renderTitleText = () => {
    if (eventType === 'now') {
      return (<>
        <div>Meet now for {title}</div>
      </>);
    } else if (eventType === 'poll') {
      return (<>
        <div>When are you free for {title}?</div>
      </>);
    } else {
      return (
        <>
          <div>{title}</div>
          <div>{formatDate()} at {formatTimeWithZone(startTime)}{!hasResponded ? '?' : ''}</div>
        </>
      );
    }
  };

  return (
    <div className="relative bg-[#6000E9] rounded-[20px] p-6 text-white flex flex-col min-h-[200px] h-auto overflow-hidden">
      {/* Content wrapper with higher z-index */}
      <div className="flex flex-col h-full relative z-10">
        {/* Title section with controlled width */}
        <div className="flex-grow">
          <div className="text-[30px] font-bold font-manrope leading-[1.15] tracking-[-0.01em] max-w-[90%]">
            {renderTitleText()}
          </div>
        </div>

        {/* Bottom info with consistent spacing */}
        <div className="flex flex-col gap-[8px] mt-[15px]">
          {eventType === 'poll' && (
            <div className="flex items-center gap-2 text-[14px] font-manrope font-normal text-[rgba(255,255,255,0.8)]">
              <TimeIcon className="w-5 h-5 text-white flex-shrink-0" />
              <span>Pending time</span>
            </div>
          )}
          
          {/* Location */}
          <div className="flex items-center gap-2 text-[14px] font-manrope font-normal text-[rgba(255,255,255,0.8)]">
            <MapPin className="w-5 h-5 text-white flex-shrink-0" />
            {location.length < 1 ? "TBD" : (
              <a
                href={`https://www.google.com/maps?q=${encodeURIComponent(location)}`}
                target="_blank"
                rel="noopener noreferrer"
                className="truncate hover:text-white transition-colors max-w-[200px] underline underline-offset-2"
              >
                {location}
              </a>
            )}
          </div>

          {/* Guests */}
          <div className="flex items-center gap-2 text-[14px] font-manrope font-normal text-[rgba(255,255,255,0.8)]">
            <Users className="w-5 h-5 text-white flex-shrink-0" />
            <span>{guests} {eventType === "poll" ? "Voted" : "Going"} </span>
          </div>
        </div>
      </div>

      {/* Emoji with lower z-index */}
      <div className="absolute bottom-[-20px] right-[-20px] w-[clamp(143px,45%,200px)] aspect-square z-0">
        <div className="bg-white/20 w-full h-full rounded-full flex items-center justify-center overflow-hidden">
          <span className="text-[clamp(64px,20vh,100px)]">{emoji}</span>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
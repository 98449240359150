import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ChevronRight, X } from 'lucide-react';
import { ReactComponent as DeleteIcon } from 'assets/images/profile/delete-icon.svg';

import LetsMeetLayout from 'modules/letsMeet/layouts/LetsMeetLayout';
import MainButton from 'modules/auth/components/MainButton';
import { Text } from 'components/text/primary/Text';
import OverlayLoader from 'components/loader/OverlayLoader';
import TextField from 'components/textfield/TextField';
import { format, parseISO } from 'date-fns';
import Drawer from 'modules/settings/components/contactActions/Drawer/Drawer';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import {
  useCreateEventMutation,
  useGetEventsQuery,
} from 'services/event.service';

import { ReactComponent as NowIcon } from 'assets/images/event/now.svg';
import { ReactComponent as LaterIcon } from 'assets/images/event/later.svg';
import { ReactComponent as PollIcon } from 'assets/images/event/poll.svg';

import { ReactComponent as NowActiveIcon } from 'assets/images/event/nowPurple.svg';
import { ReactComponent as LaterActiveIcon } from 'assets/images/event/laterPurple.svg';
import { ReactComponent as PollActiveIcon } from 'assets/images/event/pollPurple.svg';

import { ReactComponent as PlusIcon } from 'assets/images/event/plus.svg';
import IconsList from 'components/iconsList/IconsList';
import EventIcon from 'components/eventIcon/EventIcon';
import CitySuggestion from 'components/citySuggestion/CitySuggestion';
import { useAppSelector } from 'hooks/useAppSelector';
import { RootState } from 'app/store';
import {
  Contact,
  IAvailabilityPeriod,
  IAvailability,
} from 'modules/profile/types/types';
import ToggleSwitch from 'components/toggle/ToggleSwitch';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';

import { Calendar } from 'components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { useLocation, useNavigate } from 'react-router-dom';
import { Checkbox } from 'components/ui/checkbox';
import { DateRange } from 'react-day-picker';
import { DatePickerWithRange } from 'components/ui/datePickerWithRange';
import caleidoBaseApi from 'services/apiClient';
import { cn } from 'utils/helpers';
import RecurringAvailabilitySlot from 'modules/profile/components/availability/AvailabilitySlot/RecurringAvailabilitySlot';
import ContactsList from 'modules/letsMeet/components/contactsList/contactsList';
import { useGetAvailabilitiesQuery } from 'services/calendar.service';
import { IPollData } from 'types/event';
import dayjs from 'dayjs';
import AvailabilityTimeSelector from 'modules/profile/components/availability/TimeDropDown/availabilityTimeSelector';
import { ITimeSlot } from 'modules/profile/components/availability/TimeSlot/TimeSlot';

import { toZonedTime } from 'date-fns-tz';
import CustomSelect from 'modules/letsMeet/components/select/select';
import { generateTimeOptions, toNextDay } from 'modules/letsMeet/utils/lib';
import { ReactComponent as UserAvatar } from 'assets/images/common/avatar.svg';
import { toast } from 'components/ui/toast/use-toast';
import TextAreaField from 'components/textArea/TextAreaField';
import { AvailabilityPeriodModel } from 'modules/profile/model/Period';

const dayOfTheWeek: (keyof IAvailability)[] = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
];

type FormValues = {
  activity: string;
  details: string;
};

interface AvailabilitySlot {
  start: string;
  end: string;
}

interface AvailabilityResponse {
  manual?: boolean;
  date: string;
  times: AvailabilitySlot[];
}

// Create a custom theme
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: 'rgb(99,91,232)',
    },
    text: {
      disabled: 'rgba(255, 255, 255, 0.2)', // Color for disabled text
    },
  },
  typography: {
    body1: {},
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: 'rgba(0, 1, 41, 1)',
          color: '#fff',
        },
      },
    },
  },
});

const inputStyles = {
  '& *': {
    borderColor: 'transparent',
  },

  '& .MuiInputBase-root': {
    backgroundColor: '#32395E',
    borderRadius: '30px',
    border: 'none',
    padding: '10px',
    paddingLeft: '18px',
    fontSize: '14px',
    outline: 'none',
  },
  '& .MuiInputBase-input': {
    padding: '0',
    textAlign: 'left',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },

  '& .MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },

  '& .MuiIconButton-root': {
    opacity: 0.7,
  },
};

const CreateEvent = forwardRef<() => void, {}>((props, ref) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleOption, setVisibleOption] = useState(false);
  const [location, setLocation] = useState<string>('');
  const [icon, setIcon] = useState<string>('');
  const [locationMenuVisible, setLocationMenuVisible] = useState(false);
  const user = useAppSelector((state: RootState) => state.auth.user);
  const [userContacts, setUserContacts] = useState<Contact[]>([]);
  const [isContactVisible, setIsContactVisible] = useState(false);
  const [isMeetNowChecked, setIsMeetNowChecked] = useState(true);
  const [type, setType] = useState<string>('');
  const [laterDate, setLaterDate] = useState<Date>();
  const [nowEndTime, setNowEndTime] = useState<Date>();
  const [nowEndSelectTime, setNowEndSelectTime] = useState<any | null>(null);
  const [laterStartTime, setLaterStartTime] = useState<Date | null>(null);
  const [laterEndTime, setLaterEndTime] = useState<Date | null>(null);
  const [selectedNowEndValue, setSelectedNowEndValue] = useState<string | null>(
    '120',
  );
  const [selectedDuration, setSelectedDuration] = useState<number | null>(null);
  const [publicEvent, setPublicEvent] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [date, setDate] = useState<DateRange | undefined>();
  const [dateRange, setDateRange] = useState<Date[] | undefined>();
  const [userId, setUserId] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [availableTimes, setAvailableTimes] = useState<any[]>([]);
  const [checkedTimes, setCheckedTimes] = useState<string[]>([]);
  const [endEnable, setEndEnable] = useState<boolean>(false);
  const [prevPollId, setPrevPollId] = useState<string | null>(null); // used for deleting a poll after creating an event from it
  const [createEventIsLoading, setCreateEventIsLoading] =
    useState<boolean>(false);
  const [availabiltySlots, setAvailabiltySlots] = useState<
    AvailabilityResponse[]
  >([]);

  const [manualAvailabilitySlots, setManualAvailabilitySlots] = useState<
    AvailabilityResponse[]
  >([]);

  const [showOverlappingTimeError, setShowOverlappingTimeError] =
    useState<boolean>(false);
  const [showAvailabilityError, setShowAvailabilityError] =
    useState<boolean>(false);

  const [createEvent, { data, error, isLoading }] = useCreateEventMutation();
  const { data: allEventData, refetch: refetchAllEvents } = useGetEventsQuery();

  const [recurringTimes, setRecurringTimes] = useState<IAvailabilityPeriod[]>(
    [],
  );
  const [userName, setUserName] = useState<string>('');

  const formattedDateList = dateRange?.map(date => {
    const dateObj = date.toLocaleDateString('en-CA');
    return dateObj;
  });

  const {
    data: availabilities,
    error: availabilitiesError,
    isLoading: availabilitiesLoading,
    refetch: refetchAvailabilities,
  } = useGetAvailabilitiesQuery(
    {
      dates: formattedDateList || [],
      interval: selectedDuration || 0,
    },
    {
      skip: !dateRange || !dateRange?.[0] || !selectedDuration, // Skip the query if date or duration is undefined
    },
  );

  const routerLocation = useLocation();

  const nowEventEndInNextDay = useMemo(() => {
    const date = nowEndTime;
    if (!date) return false;

    // const givenTime = dayjs(date).format('HH:mm');

    const givenTimeToday = dayjs()
      .hour(dayjs(date).hour())
      .minute(dayjs(date).minute())
      .second(0);

    const currentTime = dayjs();

    return givenTimeToday.isBefore(currentTime);
  }, [nowEndTime]);

  const laterEventEndInNextDay = useMemo(() => {
    if (!laterEndTime) return false;
    if (!laterStartTime) return false;
    if (!laterDate) return false;

    const date = dayjs(laterDate);

    const startTime = dayjs(laterStartTime);
    startTime
      .set('year', date.year())
      .set('month', date.month())
      .set('date', date.date());

    const endTime = dayjs(laterEndTime);
    endTime
      .set('year', date.year())
      .set('month', date.month())
      .set('date', date.date());

    return endTime.isBefore(startTime);
  }, [laterEndTime]);

  useEffect(() => {
    const startDate =
      type === 'now'
        ? new Date()
        : type === 'later'
          ? combineDateTime(laterDate, laterStartTime)
          : null;

    const endDate =
      type === 'now' && isMeetNowChecked
        ? nowEndSelectTime
        : type === 'now'
          ? toNextDay(nowEndTime, nowEventEndInNextDay)
          : type === 'later'
            ? combineDateTime(laterDate, laterEndTime)
              ? toNextDay(
                combineDateTime(laterDate, laterEndTime) ?? new Date(),
                laterEventEndInNextDay,
              )
              : null
            : null;

    const isOverlappingData = (
      start1: Date,
      end1: Date,
      start2: Date,
      end2: Date,
    ) => {
      return start1 < end2 && start2 < end1;
    };

    if (startDate && endDate) {
      const selectedStartDate = new Date(startDate);
      const selectedEndDate = new Date(endDate);

      const selectedDate =
        type === 'now' ? new Date() : new Date(laterDate ?? new Date());

      const dayOfWeek = dayOfTheWeek[selectedDate.getUTCDay()];

      const availableSlotsForTheDay = user?.availability as any;

      if (availableSlotsForTheDay?.hasOwnProperty(dayOfWeek)) {
        const availableTimes = availableSlotsForTheDay[dayOfWeek];

        const isAvailable = availableTimes?.[0]?.allDay
          ? true
          : availableTimes?.some((availableSlot: any) => {
            return isOverlappingData(
              formatDateTime(availableSlot.start.dateTime, selectedStartDate) as Date,
              formatDateTime(availableSlot.end.dateTime, selectedEndDate) as Date,
              selectedStartDate,
              selectedEndDate,
            );
          });
        if (isAvailable) {
          setShowAvailabilityError(false);

          const isOverlapping = allEventData.some((event: any) => {
            return isOverlappingData(
              new Date(event.start),
              new Date(event.end),
              selectedStartDate,
              selectedEndDate,
            );
          });

          setShowOverlappingTimeError(isOverlapping);
        } else {
          setShowAvailabilityError(true);
        }
      } else {
        setShowAvailabilityError(true);
      }
    } else if (showOverlappingTimeError || showAvailabilityError) {
      setShowOverlappingTimeError(false);
      setShowAvailabilityError(false);
    }
  });

  // TODO: We dont need this anymore since we want to just straight up create events from the poll, not navigate
  // to the create event page
  const eventData = routerLocation.state?.eventData;
  const laterDateInfo = routerLocation.state?.dateInfo;
  const selectedTimeSlot = routerLocation.state?.selectedTimeSlot;

  // Prepopulate form values with eventData if available
  useEffect(() => {
    // Prepopulate form values from poll for the poll to create event feature
    if (eventData && eventData.type === 'poll') {
      setPrevPollId(eventData._id);
      setFormValues({
        activity: eventData.activity,
        details: eventData.details,
      });
      setIcon(eventData.emoji);
      setLocation(eventData.location);
      // filter out organizers again since they are added to contacts later
      const myInvitees = eventData.contacts.filter(
        (contact: Contact) => contact.organizer === 0,
      );

      setSelectedContacts(myInvitees);
      setPublicEvent(eventData.publicEvent);
      setType('later');
      setLaterDate(laterDateInfo.date);
      setLaterStartTime(laterDateInfo.startTime);
      setLaterEndTime(laterDateInfo.endTime);
      console.log('laterStartTime', laterDateInfo.startTime);
      console.log('laterEndTime', laterDateInfo.endTime);
    }
  }, [eventData, selectedTimeSlot, laterDateInfo]);

  // Effect to log the available times when they are loaded
  useEffect(() => {
    if (availabilities) {
      console.log('Availabilities:', availabilities);
      setAvailabiltySlots(availabilities); // Replace previous state with new availabilities
    }
  }, [availabilities, availabilitiesLoading, selectedDuration]);

  // TODO: fix to include manually added availabilities
  const handleDeleteAvailability = (index: number) => {
    setAvailabiltySlots((prevAvailabilities) => {
      let slotIndex = 0;
      const updatedAvailabilities = prevAvailabilities.reduce<
        AvailabilityResponse[]
      >((acc, day) => {
        const filteredTimes = day.times.filter((_, timeIndex) => {
          if (slotIndex === index) {
            slotIndex++;
            return false;
          }
          slotIndex++;
          return true;
        });

        if (filteredTimes.length > 0) {
          acc.push({
            ...day,
            times: filteredTimes,
          });
        }

        return acc;
      }, []);

      return updatedAvailabilities;
    });
  };

  const handleDeleteManualAvailability = (date: string, timeIndex: number) => {
    const filteredManualAvailability = manualAvailabilitySlots.map((availability) => {
      if (availability.date === date) {
        return {
          ...availability,
          times: availability.times.filter((_, index) => index !== timeIndex)
        };
      }
      return availability;
    });

    setManualAvailabilitySlots(filteredManualAvailability)
  }

  const validateForm = () => {
    const formErrors: { [key: string]: string } = {};

    if (!formValues.activity) {
      formErrors.activity = 'Activity is required';
    }

    if (type === '') {
      formErrors.when = 'When is required';
    }

    if (type === 'later') {
      if (!laterDate) {
        formErrors.laterDate = 'Date is required';
      }

      if (!laterStartTime) {
        formErrors.laterStartTime = 'Start time is required';
      }

      // remove validation for end time
      /*
      if (!laterEndTime) {
        formErrors.laterEndTime = 'End time is required';
      }
      */
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const navigate = useNavigate();

  const [formValues, setFormValues] = useState<FormValues>({
    activity: '',
    details: '',
  });

  const formatDateToLocalTimezone = (dateString: Date, timezone: string) => {
    // Convert the UTC date string to a Date object
    const date = new Date(dateString);
    // Convert the date to the desired timezone
    const zonedDate = toZonedTime(date, timezone);
    return zonedDate;
  };

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    },
    [],
  );

  const handleInputChangeTextArea = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleEventNameChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    handleInputChangeTextArea(event);
    setErrors((prev) => ({ ...prev, activity: '' }));
  };

  const handleOtherDetailsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    handleInputChangeTextArea(event);
    setErrors((prev) => ({ ...prev, details: '' }));
  };

  const removeContact = (phoneNumber: string) => {
    setSelectedContacts((prevContacts) =>
      prevContacts.filter((contact) => contact.phoneNumber !== phoneNumber),
    );
  };

  const handleTickOutCaleido = () => {
    setPublicEvent(!publicEvent);
  };

  const handleNowEndTimeChange = (time: ITimeSlot) => {
    setNowEndTime(new Date(time.dateTime));
  };

  const handleLaterEndTimeChange = (time: ITimeSlot) => {
    setLaterEndTime(new Date(time.dateTime));
    setErrors((prev) => ({ ...prev, laterEndTime: '' }));
  };

  const handleLaterStartTimeChange = (time: ITimeSlot) => {
    setLaterStartTime(new Date(time.dateTime));
    setEndEnable(true);
    setErrors((prev) => ({ ...prev, laterStartTime: '' }));
  };

  const onNowSwitchChange = () => {
    setIsMeetNowChecked(!isMeetNowChecked);
  };

  const onChangeType = (typeOption: string) => {
    setType((prev) => typeOption);
    setErrors((prev) => ({ ...prev, when: '' }));
    setShowAvailabilityError(false);
    setShowOverlappingTimeError(false);
  };

  // contacts
  const [searchValue, setSearchValue] = useState('');
  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };

  // multiple contacts
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);

  const handleContactClick = (contact: Contact) => {
    setSearchValue('');
    setSelectedContacts((prevContacts) => [...prevContacts, contact]);
  };

  const handleConfirmationModal = useCallback((value: boolean) => {
    setVisible(value);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    refetchAllEvents();
  }, []);

  useEffect(() => {
    // Close drawer on unmount
    return () => {
      setVisible(false);
    };
  }, [icon]);

  useEffect(() => {
    // show user details
    if (user && user.contacts) {
      console.log(user.contacts);
      setUserContacts(user.contacts);
    }
  }, [user]);

  useEffect(() => {
    if (selectedNowEndValue) {
      const endTime = new Date();
      endTime.setMinutes(endTime.getMinutes() + parseInt(selectedNowEndValue));
      console.log(endTime);
      setNowEndSelectTime(endTime);
    }
  }, [selectedNowEndValue]);

  const handleContactVisibility = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if ((event.target as HTMLElement).id === 'withWho') {
      setIsContactVisible(true);
    } else {
      setIsContactVisible(false);
    }
  };

  // convert the date-time string into a Date object
  const getDateFromString = (dateTimeString: string): Date | any => {
     const parts = dateTimeString.split('-');
     const datePart = parts.slice(0, 3).join('-'); 
     const startTime = parts[3]; 
     const formattedString = `${datePart}T${startTime}`;
 
     return new Date(formattedString);
  };

  const sortDateTimeArray = (dateTimeArray?: string[]): string[] => {
    if(!dateTimeArray || dateTimeArray?.length < 1)
      return []

    const copyOfArray = JSON.parse(JSON.stringify(dateTimeArray))
    return copyOfArray.sort((a: string, b: string) => {
      // Extract the date and start time part from each string
      const dateA = getDateFromString(a);
      const dateB = getDateFromString(b);

      // Sort based on the timestamp (milliseconds since the Unix epoch)
      return dateA - dateB;
  });
  };

  const handleSubmit = () => {
    
    // TODO: fix selected time not properly reflected when quickly click on create event button
    // click blur event for all time picker elements
    const timeSelectors = document.querySelectorAll('.native-time-selector');
    timeSelectors.forEach((selector) => {
      (selector as HTMLInputElement)?.blur();
    });

    setCreateEventIsLoading(true);

    if (!validateForm()) {
      setCreateEventIsLoading(false);
      return;
    }

    if (!(nowEndTime || nowEndSelectTime || laterDate) && type !== 'poll') {
      setCreateEventIsLoading(false);
      alert('Please select a time');
      return;
    }

    if (type === 'poll' && checkedTimes.length < 2) {
      setCreateEventIsLoading(false);
      alert('Please select at least 2 available times.');
      return;
    }

    const { activity, details } = formValues;

    const laterFixedStartTime = new Date(laterStartTime || new Date());
    const laterFixedEndTime = new Date(laterEndTime || new Date());

    if (laterDate) {
      const year = laterDate.getFullYear();
      const month = laterDate.getMonth();
      const date = laterDate.getDate();

      laterFixedStartTime.setFullYear(year);
      laterFixedStartTime.setMonth(month);
      laterFixedStartTime.setDate(date);

      laterFixedEndTime.setFullYear(year);
      laterFixedEndTime.setMonth(month);
      laterFixedEndTime.setDate(date);

      if (laterFixedEndTime < laterFixedStartTime) {
        laterFixedEndTime.setDate(laterFixedEndTime.getDate() + 1);
      }
    }

    const pollData: IPollData = {
      checkedTimes: checkedTimes.map((time) => ({
        time: time,
        voters: [],
      })),
    };

    const sortedCheckedTimes = sortDateTimeArray(checkedTimes)

    let calculatedStartTime = new Date();
    if (type === 'later') {
      calculatedStartTime = laterFixedStartTime
    } else if (type === 'poll') {
      calculatedStartTime = getDateFromString(sortedCheckedTimes[0])
    }

    // "now" and "later" events If the user do not add an end time, the event should be a 2 hour event on default.
    let calculatedEndTime = new Date();
    if (type === 'now') {
      if(nowEndTime){
        calculatedEndTime = nowEndTime;
      }else{
        const endTime = new Date();
        endTime.setHours(endTime.getHours() + 2);
        calculatedEndTime = endTime;
      }
    }else if (type === 'later') {      
      if(!laterEndTime){
        const endTime = new Date(laterFixedStartTime);
        endTime.setHours(endTime.getHours() + 2);
        calculatedEndTime = endTime;
      }else{
        calculatedEndTime = laterFixedEndTime;
      }
    } else if(type === 'poll'){
      calculatedEndTime = getDateFromString(sortedCheckedTimes[sortedCheckedTimes?.length - 1])
    }

    // TODO: remove logs
    console.log("---------------",calculatedStartTime, calculatedEndTime)
    
    const data = {
      activity,
      emoji: icon,
      location: location,
      contacts: selectedContacts,
      details,
      publicEvent,
      type: type,
      start: calculatedStartTime,
        // type === 'now'
        //   ? new Date()
        //   : type === 'later'
        //     ? laterFixedStartTime
        //     : new Date(),
      end:calculatedEndTime,
      /*
        type === 'now' && isMeetNowChecked
          ? nowEndSelectTime
          : type === 'now'
            ? toNextDay(nowEndTime, nowEventEndInNextDay)
            : type === 'later'
              ? toNextDay(laterFixedEndTime, laterEventEndInNextDay)
              : null,
      */
      pollData: type === 'poll' ? pollData : null,
      prevPollId: prevPollId ?? undefined,
    };

    createEvent(data)
      .then((response) => {
        setCreateEventIsLoading(false);
        const responseId = response.data.message._id;
        navigate(`./review/${responseId}`);
      })
      .catch((error) => {
        setCreateEventIsLoading(false);
        console.error(error);
      });
  };

  const handleCheckboxChange = (time: string) => {
    setCheckedTimes((prev) =>
      prev.includes(time) ? prev.filter((t) => t !== time) : [...prev, time],
    );
  };
  
  const handleCheckboxChangeAvailability = (startTime: string, endTime: string) => {
    const dateTime = formatUtcDateTime(startTime, endTime)
    setCheckedTimes((prev) =>
      prev.includes(dateTime) ? prev.filter((t) => t !== dateTime) : [...prev, dateTime],
    );
  };

  const formatDate = (dateString: any, timeString: string) => {
    try {
      const date = new Date(`${dateString}T${timeString}`)
      return format(date, 'EEE, MMM do');
    } catch (e) {
      return "invalid Date"
    }
  };

  const formatTime = (dateString: string, timeString: string) => {
    try {
      const date = new Date(`${dateString}T${timeString}`)
      return format(date, 'hh:mm a');
    } catch (e) {
      return 'Invalid Time'
    }
  };

  const formatUtcDateTime = (startTime: string, endTime: string) => {
    try {
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);

      const date = startDate.toLocaleDateString('en-CA');

      const startFormatted = startDate?.toISOString()?.split('T')[1]?.slice(0, 5);
      const endFormatted = endDate?.toISOString()?.split('T')[1]?.slice(0, 5);

      // Combine date and time into the desired format
      return `${date}-${startFormatted}Z-${endFormatted}Z`;
    } catch (e) {
      return "Invalid Date"
    }
  }

  const formatDateUtc = (dateString: any) => {
    try {
      const date = new Date(dateString)
      return format(date, 'EEE, MMM do');
    } catch (e) {
      return 'Invalid Date'
    }
  };

  const formatTimeUtc = (timeString: string) => {
    try {
      const date = new Date(timeString)
      return format(date, 'hh:mm a');
    } catch (e) {
      return 'Invalid Time'
    }
  };

  const formatDateTime = (timeString: string, originalDate: Date) => {
    const timeParts = timeString.split(':');
    if (timeParts.length !== 2) {
      return originalDate;
    }

    const [hours, minutes] = timeParts;

    const newDate = new Date(originalDate.getTime());

    const parsedHours = Number(hours);
    const parsedMinutes = Number(minutes);

    if (isNaN(parsedHours) || isNaN(parsedMinutes)) {
      return originalDate;
    }

    newDate.setHours(parsedHours);
    newDate.setMinutes(parsedMinutes);

    return newDate;
  };

  function combineDateTime(dateStr?: Date, timeStr?: Date | null) {
    if (!dateStr || !timeStr)
      return null

    const dateObj = new Date(dateStr);
    const timeObj = new Date(timeStr);

    const year = dateObj.getFullYear();
    const month = dateObj.getMonth();
    const day = dateObj.getDate();

    const hours = timeObj.getHours();
    const minutes = timeObj.getMinutes();
    const seconds = timeObj.getSeconds();

    const combinedDateTime = new Date(
      year,
      month,
      day,
      hours,
      minutes,
      seconds,
    );

    return combinedDateTime;
  }

  const fetchUserDetails = async () => {
    try {
      const response = await caleidoBaseApi.get('/calendar/checkCurrentToken');
      const data = response.data;

      setUserName(data.userName);
      setUserId(data.userId);
      setPhoneNumber(data.phoneNumber);
      console.log(data);
    } catch (error) {
      console.error('Error fetching user details', error);
    }
  };

  const handleOpenModal = () => {
    setSelectedDate(undefined)
    setRecurringTimes([new AvailabilityPeriodModel([])])
    setVisibleOption(true);
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const fetchEvents = async () => {
    if (dateRange?.[0] && dateRange?.[dateRange?.length - 1] && selectedDuration && userId && phoneNumber) {
      setLoading(true); // Start loading
      try {
        const queryParams = new URLSearchParams({
          userId,
          phoneNumber,
          startDate: dateRange?.[0].toISOString(),
          endDate: dateRange?.[dateRange?.length - 1].toISOString(),
          duration: selectedDuration.toString(),
        }).toString();

        const response = await caleidoBaseApi.get(
          `/calendar/rangeEvents?${queryParams}`,
        );
        console.log(response.data);
        const data = response.data;
        setAvailableTimes(data.events);
      } catch (error) {
        console.error('Error fetching events', error);
      } finally {
        setLoading(false); // Stop loading
      }
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [dateRange, selectedDuration, userId, phoneNumber]);

  useEffect(() => {
    // update the available times when the availabilities change
  }, [availabilities, manualAvailabilitySlots]);

  const isTimeConflict = (
    existingTimes: AvailabilitySlot[],
    newTime: AvailabilitySlot,
  ) => {
    return existingTimes.some(
      ({ start, end }) =>{
        const startTime = new Date(start)
        const endTime = new Date(end)
        const newStart = new Date(newTime.start)
        const newEnd = new Date(newTime.start)

        return (
          (newStart >= startTime && newStart < endTime) ||
          (newEnd > startTime && newEnd <= endTime) ||
          (newStart <= startTime && newEnd >= endTime)
        )
      }
        
    );
  };
  const mergeAvailabilities = (
    availabilities1: AvailabilityResponse[],
    availabilities2: AvailabilityResponse[],
  ): AvailabilityResponse[] => {
    const updatedAvailabilities = availabilities1.map((availability) => {
      const matchingSlot = availabilities2.find(
        (slot) => slot.date === availability.date,
      );

      if (matchingSlot) {
        const matchingTimeSLots = matchingSlot.times.map(slot => {
          if(slot.start.includes('T') || availability?.manual){
            return slot
          } else {
            return {
              start: `${matchingSlot.date}T${slot.start}`,
              end: `${matchingSlot.date}T${slot.end}`
            }
          }
        })

        const nonConflictingTimes = matchingTimeSLots.filter(
          (newTime) => !isTimeConflict(availability.times, newTime),
        );

        return {
          ...availability,
          times: [...availability.times, ...nonConflictingTimes],
        };
      }

      return availability;
    });

    // Add new dates that are not already in availabilities1
    availabilities2.forEach((slot) => {
      if (
        !updatedAvailabilities.some(
          (availability) => availability.date === slot.date,
        )
      ) {
        const copyOfSlot = {...slot}
        copyOfSlot.manual = true
        updatedAvailabilities.push(copyOfSlot);
      }
    });

    return updatedAvailabilities;
  };

  const handleSave = () => {
    if (!selectedDate) {
      alert('Please select a date');
      return;
    }

    if (recurringTimes.length === 0) {
      alert('Please select a time slot');
      return;
    }

    if (recurringTimes.some(timeSlot => !timeSlot.start.dateTime || !timeSlot.end?.dateTime)) {
      alert('Please select start and end times');
      return;
    }

    const dateObj = new Date(selectedDate);

    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    // add the recurring times to availabilities
    // first turn the recurring times into availabilities
    const newSlots: AvailabilitySlot[] = recurringTimes.map(
      ({ start, end }) => {
        try {
          const formattedStart = new Date(start.dateTime).toLocaleTimeString(
            'en-US',
            {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            },
          );

          const formattedEnd = end?.dateTime
            ? new Date(end.dateTime).toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
              })
            : '';

          return { start: formattedStart, end: formattedEnd };
        } catch (error) {
          console.error('Error formatting time:', error);
          return { start: '', end: '' };
        }
      },
    );

    // append the new slots to the manual availability slots, if the same date exist, merge the times, if same time then dont add
    const newManualTimes: AvailabilityResponse[] = [
      {
        date: formattedDate,
        times: newSlots
      }
    ]

    const manualMerged = mergeAvailabilities(manualAvailabilitySlots, newManualTimes);
    console.log('manualMerged', manualMerged);
    setManualAvailabilitySlots(manualMerged);
    setVisibleOption(false);
  };

  useEffect(() => {
    console.log(laterStartTime);
  }, [laterStartTime]);

  const containerRef = useRef<HTMLDivElement | null>(null); 

  const handleIconSelect = (icon: React.SetStateAction<string>) => {
    setIcon(icon);

    // Scroll to the beginning of the scrollable section
    if (containerRef.current) {
      containerRef?.current?.scrollTo({
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const showableAvailabilities = mergeAvailabilities(
    availabiltySlots,
    manualAvailabilitySlots,
  );
  console.log('showableAvailabilities', showableAvailabilities);
  console.log('availabiltySlots', availabiltySlots);
  console.log('manualAvailabilitySlots', manualAvailabilitySlots);
  console.log('length', manualAvailabilitySlots.length);
  return (
    <LetsMeetLayout
      step="create"
      footerText={''}
      actionOnHeaderRight={''}
      widget="menu"
    >
      {loading && <OverlayLoader />}
      <main className="flex-grow mb-4" onClick={handleContactVisibility}>
        <div className="w-full my-8 mb-6">
          <div className="flex flex-col items-center w-full gap-2">
            <form
              onSubmit={handleSubmit}
              className="w-full grid grid-cols-1 gap-4"
            >
              <div>
                <TextAreaField
                  label="What do you want to do?"
                  placeholder="Name activity"
                  required
                  name="activity"
                  value={formValues.activity || ''}
                  onChange={handleEventNameChange}
                  error={errors.activity}
                />
              </div>

              <div>
                <Text
                  size="small"
                  className="w-full text-base font-sofia-normal pb-2"
                >
                  Select an icon for your activity
                </Text>
                <div className="overflow-x-auto overflow-y-visible">
                  <div className=" flex gap-2.5 overflow-x-auto overflow-y-visible py-0.5" ref={containerRef}>
                    {icon ? (
                      <>
                        <div
                          className="flex items-center gap-2.5 mr-1 shrink-0"
                          onClick={() => handleConfirmationModal(true)}
                        >
                          <EventIcon icon={icon} />
                          <div className="text-sm font-sofia-light opacity-50 shrink-0">
                            Change Icon
                          </div>
                        </div>
                        {['🍽️', '🍻', '🏠', '❤️'].map((i) => (
                          <div key={i} onClick={() => handleIconSelect(i)}>
                            <EventIcon icon={i} />
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        <div
                          className=" rounded-full bg-[#32395E] flex justify-center py-7 w-20 shrink-0"
                          onClick={() => handleConfirmationModal(true)}
                        >
                          <PlusIcon />
                        </div>
                        {['🍽️', '🍻', '🏠', '❤️'].map((i) => (
                          <div key={i} onClick={() => handleIconSelect(i)}>
                            <EventIcon icon={i} />
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <CitySuggestion
                  location={location}
                  error={errors.location}
                  locationMenuVisible={locationMenuVisible}
                  setSelectedLocation={setLocation}
                />
              </div>

              <div>
                {selectedContacts && selectedContacts.length > 0 && (
                  <>
                    <div className="flex justify-between items-center">
                      <div>
                        <Text
                          size="small"
                          className="w-full text-base font-sofia-normal"
                        >
                          With who?
                        </Text>
                      </div>
                      <div className="text-right">
                        {!publicEvent && (
                          <div className="flex items-center gap-1 px-2.5 py-1.5 flex-row text-violet-700 bg-white rounded-xl">
                            <UserAvatar height={12} width={12} />

                            <Text
                              size="small"
                              className="text-violet-700 text-xs leading-none"
                            >
                              {selectedContacts.length}
                            </Text>
                          </div>
                        )}
                      </div>
                    </div>
                    {!publicEvent && (
                      <div className="grid grid-cols-2">
                        {selectedContacts.map((contact, index) => (
                          <div
                            key={index}
                            className="grid grid-cols-[auto_24px] items-center justify-between rounded-full bg-[#1F2339] text-white text-sm px-3 py-1.5  m-1"
                          >
                            <div className="text-sm whitespace-nowrap overflow-hidden text-ellipsis">
                              {contact.name}
                            </div>
                            <div
                              onClick={() => removeContact(contact.phoneNumber)}
                              className={'ml-2.5 flex-shrink-0'}
                            >
                              <X size={14} />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}
                <TextField
                  disabled={publicEvent}
                  id={'withWho'}
                  label={
                    selectedContacts && selectedContacts.length == 0
                      ? 'With who?'
                      : ''
                  }
                  placeholder="Select contacts"
                  value={searchValue}
                  onChange={(elem) => {
                    handleSearchChange(elem.target.value);
                  }}
                />
                <ContactsList
                  show={isContactVisible}
                  userContacts={userContacts}
                  currUserId={user?.id ?? '-'}
                  searchValue={searchValue}
                  onContactSelect={handleContactClick}
                  onSearchChange={handleSearchChange}
                  selectedContacts={selectedContacts}
                />

                <div className="inline-flex items-center w-full ml-0.5">
                  <Checkbox
                    checked={publicEvent}
                    className="bg-purple-300 mr-2"
                    onCheckedChange={handleTickOutCaleido}
                  />
                  <Text
                    size="extra-small"
                    className="text-xs font-sofia-extralight"
                  >
                    My friends aren&apos;t on Caleido
                  </Text>
                </div>
              </div>

              <div>
                <TextAreaField
                  label="Any other details?"
                  placeholder="Type here"
                  name={'details'}
                  value={formValues.details}
                  onChange={handleOtherDetailsChange}
                  error={errors.details}
                />
              </div>

              <div>
                <Text
                  size="small"
                  className="w-full text-base font-sofia-normal flex justify-start space-x-2 pb-2"
                >
                  <span>When?</span>
                  <span>*</span>
                </Text>
                <div className="grid grid-cols-3 w-full gap-2 mb-4">
                  <div
                    onClick={() => onChangeType('now')}
                    className={`rounded-full py-2 flex flex-col justify-center items-center ${type === 'now' ? `bg-purple-300` : `border border-indigo-600`}`}
                  >
                    {type === 'now' ? <NowActiveIcon /> : <NowIcon />}
                    <span
                      className={`font-bold text-xs mt-1 ${type === 'now' && `text-indigo-900`}`}
                    >
                      Now
                    </span>
                  </div>
                  <div
                    onClick={() => onChangeType('later')}
                    className={`rounded-full py-2 flex flex-col justify-center items-center ${type === 'later' ? `bg-purple-300` : `border border-indigo-600`}`}
                  >
                    {type === 'later' ? <LaterActiveIcon /> : <LaterIcon />}
                    <span
                      className={`font-bold text-xs mt-1 ${type === 'later' && `text-indigo-900`}`}
                    >
                      Later
                    </span>
                  </div>
                  <div
                    onClick={() => onChangeType('poll')}
                    className={`rounded-full py-2 flex flex-col justify-center items-center ${type === 'poll' ? `bg-purple-300` : `border border-indigo-600`}`}
                  >
                    {type === 'poll' ? <PollActiveIcon /> : <PollIcon />}
                    <span
                      className={`font-bold text-xs mt-1 ${type === 'poll' && `text-indigo-900`}`}
                    >
                      Poll
                    </span>
                  </div>
                </div>

                {errors.when && (
                  <Text
                    size="small"
                    className="font-sans text-xs text-red-500 mt-1.5"
                  >
                    {errors.when}
                  </Text>
                )}

                {type && <hr className={'opacity-20 my-8'} />}
                {type === 'now' ? (
                  <div>
                    <div className='flex flex-row mb-4 justify-between'>
                      <h1 className='text-xl font-sofia-medium'>
                        Meet Now
                      </h1>
                      <div className='flex flex-row items-center'>
                        <Text
                          size="small"
                          className="mr-2"
                        >
                          {isMeetNowChecked
                            ? `Set duration`
                            : `Set end time`}
                        </Text>
                        <ToggleSwitch
                          isChecked={isMeetNowChecked}
                          onChange={onNowSwitchChange}
                        />
                      </div>
                    </div>
                    <div>
                      <Text
                        size="small"
                        className="w-full text-base font-sofia-normal flex justify-between mb-2"
                      >
                        <span>
                          {isMeetNowChecked
                            ? `How long do you plan to hang out?`
                            : `Select the end time`}
                        </span>
                      </Text>
                      {!isMeetNowChecked ? (
                        <>
                          <AvailabilityTimeSelector
                            onTimeSelect={handleNowEndTimeChange}
                            selectedTime={
                              nowEndTime
                                ? dayjs(nowEndTime).format('hh:mm A')
                                : undefined
                            }
                            className="m-0 max-w-full [&>div]:justify-start [&_.lucide]:hidden [&_button]:w-full"
                            popupDirection="up"
                            useDefaultTime
                          />
                          {nowEventEndInNextDay && (
                            <Text
                              size="small"
                              className="font-sans text-xs font-sofia-light mt-1.5 opacity-75"
                            >
                              Event will end on the next day
                            </Text>
                          )}
                        </>
                      ) : (
                        <div>
                          <CustomSelect
                            selectedValue={selectedNowEndValue ?? '120'}
                            options={generateTimeOptions()}
                            onValueChange={(value) =>
                              setSelectedNowEndValue(value)
                            }
                            direction="up"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ) : type == 'later' ? (
                  <div>
                    <h1 className={'text-xl font-sofia-medium mb-4'}>
                      Meet later
                    </h1>

                    <div>
                      <Text
                        size="small"
                        className="w-full text-base font-sofia-normal flex justify-between mb-2"
                      >
                        <span>Select the start date</span>
                      </Text>
                      <Popover>
                        <PopoverTrigger asChild>
                          <button
                            className={
                              'bg-[#32395E]  text-left py-3 px-5 text-sm w-full rounded-full'
                            }
                          >
                            {laterDate ? (
                              format(laterDate, 'PPP')
                            ) : (
                              <span className={'text-slate-400'}>
                                DD/MM/YYYY
                              </span>
                            )}
                          </button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0 border-none">
                          <Calendar
                            fromDate={new Date()}
                            className={'bg-slate-800 text-white'}
                            mode="single"
                            selected={laterDate}
                            onSelect={(date?: Date) => {
                              setLaterDate(date);
                              date &&
                                setErrors((prev) => ({
                                  ...prev,
                                  laterDate: '',
                                }));
                            }}
                            initialFocus
                          />
                        </PopoverContent>
                      </Popover>

                      {errors.laterDate && (
                        <Text
                          size="small"
                          className="font-sans text-xs text-red-500 mt-1.5"
                        >
                          {errors.laterDate}
                        </Text>
                      )}

                      <Text
                        size="small"
                        className="w-full text-base font-sofia-normal flex justify-between mb-2 mt-4"
                      >
                        <span>Select the start time</span>
                      </Text>

                      <AvailabilityTimeSelector
                        onTimeSelect={handleLaterStartTimeChange}
                        selectedTime={
                          laterStartTime
                            ? dayjs(laterStartTime).format('hh:mm A')
                            : undefined
                        }
                        className="m-0 max-w-full [&>div]:justify-start [&_.lucide]:hidden [&_button]:w-full"
                        popupDirection="down"
                        useDefaultTime
                      />
                      {errors.laterStartTime && (
                        <Text
                          size="small"
                          className="font-sans text-xs text-red-500 mt-1.5"
                        >
                          {errors.laterStartTime}
                        </Text>
                      )}

                      <Text
                        size="small"
                        className="w-full text-base font-sofia-normal flex justify-between mb-2 mt-4"
                      >
                        <span>Select the end time</span>
                      </Text>
                      <AvailabilityTimeSelector
                        onTimeSelect={handleLaterEndTimeChange}
                        selectedTime={
                          endEnable && laterEndTime
                            ? dayjs(laterEndTime).format('hh:mm A')
                            : undefined
                        }
                        className="m-0 max-w-full [&>div]:justify-start [&_.lucide]:hidden [&_button]:w-full"
                        popupDirection="up"
                        disabled={!endEnable}
                        useDefaultTime
                      />

                      {errors.laterEndTime && (
                        <Text
                          size="small"
                          className="font-sans text-xs text-red-500 mt-1.5"
                        >
                          {errors.laterEndTime}
                        </Text>
                      )}

                      {laterEventEndInNextDay && (
                        <Text
                          size="small"
                          className="font-sans text-xs font-sofia-light mt-1.5 opacity-75"
                        >
                          Event will end on the next day
                        </Text>
                      )}
                    </div>
                  </div>
                ) : type === 'poll' ? (
                  <div>
                    <h1 className={'text-xl font-sofia-medium mb-4'}>
                      Create a poll
                    </h1>

                    <div>
                      <Text
                        size="small"
                        className="w-full text-base font-sofia-normal flex justify-between mb-2"
                      >
                        <span>Select the dates you are available</span>
                      </Text>
                      <ThemeProvider theme={darkTheme}>
                        <DatePickerWithRange
                          className="w-full rounded-full border-slate-600 outline-none "
                          date={dateRange}
                          setDate={setDateRange}
                        />
                      </ThemeProvider>

                      <Text
                        size="small"
                        className="w-full text-base font-sofia-normal flex justify-between mb-2 mt-4"
                      >
                        <span>How long do you plan to hang out</span>
                      </Text>
                      <CustomSelect
                        selectedValue={selectedDuration?.toString() ?? '0'}
                        options={generateTimeOptions()}
                        onValueChange={(value) => {
                          setSelectedDuration(Number(value));
                        }}
                        placeHolder="Select duration"
                      />
                      <Text
                        size="small"
                        className="w-full text-base font-sofia-normal flex justify-between mb-2 mt-4"
                      >
                        <span>
                          Choose from your available times (At least 2)
                        </span>
                      </Text>
                      <ThemeProvider theme={darkTheme}>
                        <div className="max-h-96 overflow-y-auto flex flex-col gap-2.5 relative">
                          {loading && <OverlayLoader />}
                          {(!dateRange || !selectedDuration) &&
                          manualAvailabilitySlots.length === 0 ? (
                            <Text
                              size="small"
                              className="text-gray-500 text-center"
                            >
                              No availabilities to show. Please select a date
                              and duration.
                            </Text>
                          ) : showableAvailabilities.length === 0 ||
                            showableAvailabilities.every(
                              (avail) => avail.times.length === 0,
                            ) ? (
                            <Text
                              size="small"
                              className="text-gray-500 text-center"
                            >
                              No availabilities
                            </Text>
                          ) : (
                            showableAvailabilities?.map(
                              (day: AvailabilityResponse) =>
                                day.times.map(
                                  (time: AvailabilitySlot, index: number) => (
                                    <div
                                      key={`${day.date}-${time.start}-${index}`}
                                      className="text-sm flex justify-between w-full max-w-sm mx-auto font-sans text-gray-300 bg-[#32395E] focus:outline-none px-4 py-2.5 rounded items-center"
                                      onClick={() => {
                                        if (day?.manual) {
                                          handleCheckboxChange(
                                            `${day.date}-${time.start}-${time.end}`,
                                          )
                                        } else {
                                          handleCheckboxChangeAvailability(time.start, time.end)
                                        }
                                      }}
                                    >
                                      {day?.manual ?
                                        <div className="flex items-center">
                                          <div
                                            className='h-4 w-4 shrink-0 mr-2 rounded-sm border border-primary ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground'
                                          >
                                            <div className='flex items-center justify-center text-current bg-purple-300 w-full h-full rounded-sm '>
                                              {checkedTimes.includes(
                                                `${day.date}-${time.start}-${time.end}`,
                                              ) ?
                                                <div className="absolute bg-theme h-2 w-2" /> : null
                                              }
                                            </div>
                                          </div>

                                          <span className="pr-4 pl-1 font-sofia-light">
                                            {formatDate(day.date, time.start)} •{' '}
                                            {formatTime(day.date, time.start)} -{' '}
                                            {formatTime(day.date, time.end)}
                                          </span>
                                        </div> :
                                        <div className="flex items-center">
                                          <div
                                            className='h-4 w-4 shrink-0 mr-2 rounded-sm border border-primary ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground'
                                          >
                                            <div className='flex items-center justify-center text-current bg-purple-300 w-full h-full rounded-sm '>
                                              {checkedTimes.includes(formatUtcDateTime(time.start, time.end)) ?
                                                <div className="absolute bg-theme h-2 w-2" /> : null
                                              }
                                            </div>
                                          </div>
                                            <span className="pr-4 pl-1 font-sofia-light">
                                              {formatDateUtc(time.start)} •{' '}
                                              {formatTimeUtc(time.start)} -{' '}
                                              {formatTimeUtc(time.end)}
                                            </span>
                                        </div>
                                      }

                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          if(day?.manual) {
                                            if (checkedTimes.includes(
                                              `${day.date}-${time.start}-${time.end}`,
                                            )) {
                                              handleCheckboxChange(
                                                `${day.date}-${time.start}-${time.end}`,
                                              )
                                            }
                                            handleDeleteManualAvailability(day.date ,index)
                                          } else {
                                            if (checkedTimes.includes(formatUtcDateTime(time.start, time.end))) {
                                              handleCheckboxChangeAvailability(time.start, time.end)
                                            }
                                            handleDeleteAvailability(index);
                                          }
                                        }}
                                        className="text-red-500"
                                      >
                                        <DeleteIcon />
                                      </button>
                                    </div>
                                  ),
                                ),
                            )
                          )}
                        </div>
                      </ThemeProvider>

                      {loading && <OverlayLoader />}

                      <Text
                        size="small"
                        className={cn(
                          'mt-4 underline cursor-pointer decoration-solid',
                        )}
                        onClick={handleOpenModal}
                      >
                        + Add a different time
                      </Text>
                      <Drawer
                        setVisible={setVisibleOption}
                        visible={visibleOption}
                        className="flex flex-col w-full p-4 overflow-y-auto after:content-none"
                      >
                        <Text
                          size="medium"
                          className="px-4 my-3 font-sofia-medium text-center"
                        >
                          Add the time(s) you are available
                        </Text>
                        <Text
                          size="small"
                          className="w-full text-base font-sofia-normal flex justify-between mb-2"
                        >
                          <span>Select the date</span>
                        </Text>
                        <Popover>
                          <PopoverTrigger asChild>
                            <button
                              className={
                                '!bg-[#32395E] text-white text-sm font-sofia-light  text-left py-3 px-5  w-full rounded-full'
                              }
                            >
                              {selectedDate ? (
                                format(selectedDate, 'PPP')
                              ) : (
                                <span className={'text-slate-400'}>
                                  DD/MM/YYYY
                                </span>
                              )}
                            </button>
                          </PopoverTrigger>
                          <PopoverContent className="w-auto p-0 border-none">
                            <Calendar
                              fromDate={new Date()}
                              className={'bg-slate-800 text-white'}
                              mode="single"
                              selected={selectedDate}
                              onSelect={setSelectedDate}
                              initialFocus
                            />
                          </PopoverContent>
                        </Popover>
                        <Text
                          size="small"
                          className="w-full text-base font-sofia-normal flex justify-between mb-2 mt-4"
                        >
                          <span>Select the time</span>
                        </Text>
                        <div className="mt-4">
                          <RecurringAvailabilitySlot
                            slots={recurringTimes}
                            onChange={setRecurringTimes}
                            allEventData={allEventData}
                            selectedDate={selectedDate}
                            showSelectedInitially={false}
                          />
                        </div>
                        <div className="mt-5">
                          <MainButton type="primary" onClick={handleSave}>
                            <Text
                              size="small"
                              className="text-base font-sofia-normal"
                            >
                              Save
                            </Text>
                          </MainButton>
                        </div>
                      </Drawer>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              {/* Icon drawer */}
              <Drawer
                setVisible={setVisible}
                visible={visible}
                className="flex flex-col w-full py-4"
              >
                <IconsList activeIcon={icon} fetchIcon={setIcon} />
              </Drawer>
            </form>
          </div>
          {(showAvailabilityError || showOverlappingTimeError) && (
            <Text size="small" className="text-red-500 text-left">
              {`Heads up! This time overlaps with ${showAvailabilityError ? 'your busy time' : 'an existing block'} `}
            </Text>
          )}
        </div>
      </main>
      <footer className="mb-4 pb-8">
        <MainButton
          type="primary"
          onClick={handleSubmit}
          icon={<ChevronRight color="#fff" />}
          loading={createEventIsLoading}
        >
          <Text size="small" className="text-base font-sofia-normal">
            Go to Review
          </Text>
        </MainButton>
      </footer>
    </LetsMeetLayout>
  );
});

CreateEvent.displayName = 'CreateEvent';

export default CreateEvent;

import * as Dialog from '@radix-ui/react-dialog';
import React from 'react';
import {ReactComponent as QRCode} from 'assets/images/landing/prod-qr-code.svg';
interface QRPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const QRPopup: React.FC<QRPopupProps> = ({ isOpen, onClose }) => {
  
  return (
    <Dialog.Root open={isOpen} onOpenChange={(open) => !open && onClose()}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50" />
        <Dialog.Content className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 shadow-xl max-w-md w-full">
            <Dialog.Title className="text-gray-600 text-xl font-semibold text-center mb-4">
              Caleido is currently only accessible via mobile
            </Dialog.Title>
            <Dialog.Description className="text-sm text-gray-600 text-center mb-6">
              Scan the QR code below on your phone to access and save as a local app!
            </Dialog.Description>
            <div className="flex justify-center mb-4">
              <QRCode  style={
                {
                  width: "150px",
                  height: "150px"
                }
              }/>
            </div>
            <Dialog.Close asChild>
              <button className="w-full bg-primary hover:bg-primary-dark text-white py-2 px-4 rounded-lg">
                Close
              </button>
            </Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default QRPopup;
